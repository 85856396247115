import React, { useState, useEffect, lazy, Suspense } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { UIDropdown } from './../../components/forms/form-fields'
import Store from './CRM.store'

// import './LocationSelector.component.scss'

const LocationSelector = () => {
  const { Locations, Location, setLocation, fetchContactsByLocation } = Store,
    isDisabled = !Locations || !Locations.length,
    options = toJS(Locations || []).sort((a, b) =>
      a.get('name').localeCompare(b.get('name'))
    )

  return (
    <table>
      <tbody>
        <tr>
          <td>
            <UIDropdown
              label="My 10X Location"
              name="location_id"
              disabled={isDisabled}
              className="location-selector"
              options={options.map((L) => ({
                text: (
                  <>
                    {L.get('name') + ' '}
                    <small>{L.id()}</small>
                  </>
                ),
                value: `${L.id()}`,
                checked: Location?.id() && `${Location?.id()}` === `${L.id()}`,
              }))}
              selected={Location && Location?.id()}
              onChange={(evt) =>
                setLocation(
                  Locations.find((L) => L.id() === evt.target.value)
                ) && fetchContactsByLocation(evt.target.value)
              }
              required={true}
              value={Location && Location?.id()}
              rules={{ required: true }}
            />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default observer(LocationSelector)
