import React, { Component } from 'react'
import AdminApi from '../api/admin-api/admin-api'
import { toast } from 'react-toastify'
import '../api/FirebaseAuth'
import Navbar from '../components/newhome/Navbar'
import Footer from '../components/newhome/Footer'
import env from './../environments/environment'
import ReCAPTCHA from 'react-google-recaptcha'
import { phone as phonePattern } from './../shared/patterns/telephone.pattern'
import {
  phoneToStorage,
  phoneToView,
} from './../shared/formatters/telephone.formatter'

class ContactUs extends Component {
  recaptchaRef = React.createRef()

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.createContactRequest = this.createContactRequest.bind(this)

    this.state = {
      loading: false,
      contactData: '',
      firstname: '',
      email: '',
      phone: '',
      message: '',
      formError: false,
      errorMessage: '',
      captcha: false,
    }
  }

  componentDidMount() {
    AdminApi.getContactDetails()
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState({
            contactData: result.data.data,
          })
        }
      })
      .catch((error) => {
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  validate() {
    const { firstname, email, phone, message, captcha } = this.state
    //const regPhone = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

    if (!(firstname && firstname.trim())) {
      this.setState({ formError: true, errorMessage: 'First name required' })
      return
    }
    if (!(email && email.trim())) {
      this.setState({
        formError: true,
        errorMessage: 'E-mail address required',
      })
      return
    }

    if (!regEmail.test(email)) {
      this.setState({ formError: true, errorMessage: 'Invalid E-mail format' })
      return
    }

    if (phone && !phonePattern.test(phone)) {
      this.setState({ formError: true, errorMessage: 'Invalid Phone Number' })
      return
    }

    if (!(message && message.trim())) {
      this.setState({ formError: true, errorMessage: 'Message required' })
      return
    }
    if (!captcha) {
      this.setState({ formError: true, errorMessage: 'Captcha required' })
      return
    }

    this.setState({ formError: false, errorMessage: '' })
  }

  //onChange
  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  onCaptchaChange() {
    this.setState({ captcha: !this.state.captcha })
  }

  //CREATE API FRO CONTACT
  createContactRequest = async (event) => {
    event.preventDefault()
    await this.validate()
    const {
      firstname,
      email,
      phone,
      message,
      formError,
      errorMessage,
      loading,
    } = this.state
    if (loading) {
      return
    }

    if (formError) {
      event.preventDefault()
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }

    const data = {
      firstname: firstname,
      email: email,
      home_phone: phone,
      message: message,
      req_url: window.location.href,
      ref_url: document.referrer,
    }

    let form_data = new FormData()

    for (let key in data) form_data.append(key, data[key])

    try {
      await AdminApi.createContactRequest(form_data).then((response) => {
        this.setState({ loading: false })
        if (response.status === 200) {
          this.setState({
            firstname: '',
            email: '',
            phone: '',
            message: '',
            formError: false,
            errorMessage: '',
            captcha: false,
          })
          window.grecaptcha.reset()
          toast.success('Contact successfully created', {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      })
    } catch (error) {
      this.setState({ loading: false })
      toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
    }
  }

  render() {
    toast.configure()
    const { contactData, firstname, email, phone, message, loading } =
      this.state
    return (
      <>
        <div className="page contact-page-custom-wrapper">
          <Navbar />

          <section
            style={{
              backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fsubsites%2Fimg.premiere-default.jpg?alt=media)`,
            }}
            className="section-30 section-sm-40 section-md-66 section-lg-bottom-90 bg-gray-dark page-title-wrap"
          >
            <div className="shell">
              <div className="page-title">
                <h2 className={'text-white'}>Contact Us</h2>
              </div>
            </div>
          </section>

          <div
            data-zoom="15"
            data-x="-73.9874068"
            data-y="40.643180"
            data-styles='[{"featureType":"all","stylers":[{"saturation":0},{"hue":"#e7ecf0"}]},{"featureType":"road","stylers":[{"saturation":-70}]},{"featureType":"transit","stylers":[{"visibility":"off"}]},{"featureType":"poi","stylers":[{"visibility":"off"}]},{"featureType":"water","stylers":[{"visibility":"simplified"},{"saturation":-60}]}]'
            className="rd-google-map rd-google-map__model"
          >
            <iframe
              title="address map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.915419819319!2d-82.58819598492128!3d28.48208498248667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c29f4fa388e64d%3A0x5571db1e8c4b5079!2s5141%20Mariner%20Blvd%20Unit%203%2C%20Spring%20Hill%2C%20FL%2034609%2C%20USA!5e0!3m2!1sen!2sin!4v1601373612345!5m2!1sen!2sin"
              width="100%"
              height="450"
              frameBorder="0"
              className="iframe-border"
              allowFullScreen
            ></iframe>
          </div>

          <section className="section-60 section-sm-top-90 section-sm-bottom-100 main-form-section">
            <div className="shell">
              <div className="range range-md-justify">
                <div className="cell-md-7 cell-lg-6 offset-top-50 offset-md-top-0">
                  <h3 className="text-spacing--25">Get in Touch</h3>
                  <form
                    data-form-output="form-output-global"
                    data-form-type="contact"
                    onSubmit={this.createContactRequest}
                    noValidate
                    className="rd-mailform form-modern offset-top-30"
                  >
                    <div className="range">
                      <div className="cell-sm-12">
                        <div className="form-group">
                          <input
                            id="contact-name"
                            type="text"
                            name="firstname"
                            value={firstname}
                            autoComplete="off"
                            onChange={this.onChange}
                            data-constraints="@Required"
                            className="form-control"
                            placeholder="Name"
                          />
                        </div>
                      </div>
                      <div className="cell-sm-6 offset-top-30 offset-sm-top-0">
                        <div className="form-group">
                          <input
                            id="contact-email"
                            type="email"
                            name="email"
                            value={email}
                            autoComplete="off"
                            onChange={this.onChange}
                            data-constraints="@Email @Required"
                            className="form-control"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div className="cell-sm-6 offset-top-30 offset-sm-top-0">
                        <div className="form-group">
                          <input
                            id="contact-phone"
                            type="phone"
                            name="phone"
                            value={phone}
                            autoComplete="off"
                            onChange={this.onChange}
                            data-constraints="@Phone"
                            className="form-control"
                            placeholder="Phone"
                          />
                        </div>
                      </div>
                      <div className="cell-xs-12 offset-top-30">
                        <div className="form-group">
                          <div className="textarea-lined-wrap">
                            <textarea
                              id="contact-message"
                              data-constraints="@Required"
                              name="message"
                              value={message}
                              autoComplete="off"
                              onChange={this.onChange}
                              className="form-control"
                              placeholder="Message"
                            ></textarea>
                            {/*<label htmlFor="contact-message"
                                                               className="form-label">Message</label>*/}
                          </div>
                        </div>
                      </div>

                      <div className="cell-xs-12 offset-top-30">
                        <div className="form-group">
                          <div className="textarea-lined-wrap">
                            <ReCAPTCHA
                              ref={this.recaptchaRef}
                              sitekey="6Lc_aeIZAAAAACSfGY2OqRXdIzJ1jPOIW__6gq11"
                              onChange={(event) => {
                                this.onCaptchaChange()
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="cell-xs-8 offset-top-30 offset-xs-top-30 offset-sm-top-50">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          {loading ? 'Submitting...' : 'Send'}
                        </button>
                      </div>

                      <div className="cell-xs-12 mt-3 text-spacing-0 font-default">
                        By submitting my contact information, I verify that the
                        contact information entered is correct and is my
                        personal information and that I am over 18 years of age.
                      </div>
                    </div>
                  </form>
                </div>
                <div className="cell-md-5 cell-lg-4 offset-top-60 offset-md-top-0">
                  <div className="inset-md-right-15 inset-lg-right-0">
                    <div className="range">
                      <div className="cell-sm-10 cell-md-12">
                        <h3 className="text-spacing--25">How to Find Us</h3>
                        <p className="offset-md-top-40 text-gray-base">
                          If you have any questions, just fill in the contact
                          form, and we will answer you shortly.
                        </p>
                      </div>
                      <div className="cell-sm-6 cell-md-12 offset-top-30 offset-sm-top-45">
                        <h5 className="text-spacing--25">Headquarters</h5>
                        <address className="contact-info">
                          <p className="text-uppercase text-gray-base">
                            {contactData.a_address}, {contactData.a_city},{' '}
                            {contactData.a_state} {contactData.a_zip}
                          </p>
                          <dl className="list-terms-inline">
                            <dt>Telephone</dt>
                            <dd>
                              <a
                                href={`callto:${phoneToStorage(
                                  contactData.a_phone
                                )}`}
                                className="link-gray-base"
                              >
                                &nbsp;{phoneToView(contactData.a_phone)}
                              </a>
                            </dd>
                          </dl>
                          <dl className="list-terms-inline">
                            <dt>E-mail</dt>
                            <dd>
                              <a
                                href={`mailto:${contactData.a_email}`}
                                className="link-primary"
                              >
                                &nbsp;{contactData.a_email}
                              </a>
                            </dd>
                          </dl>
                        </address>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      </>
    )
  }
}

export default ContactUs
