import React from 'react'
import { observer } from 'mobx-react-lite'
import IntakeFormIdentity from './../IntakeFormIdentity/IntakeFormIdentity.component'
import IntakeFormProfile from './../IntakeFormProfile/IntakeFormProfile.component'
import IntakeFormAcceptance from './../IntakeFormAcceptance/IntakeFormAcceptance.component'
import IntakeFormPackageSelection from './../IntakeFormPackageSelection/IntakeFormPackageSelection.component'
import IntakeFormPayment from './../IntakeFormPayment/IntakeFormPayment.component'
import Store from './../../shared/Enrollment.store'
import { toast } from 'react-toastify'
import LoadingSpinner from './../../../../components/shared/LoadingSpinner.component'
import { MDBContainer, MDBRow, MDBCol, MDBCollapse } from 'mdbreact'
import completeEnrollmentAudio from './../../assets/complete-enrollment.mp3'

import './EnrollmentStageManager.scss'

const EnrollmentStageManager = ({ useDemo, upline }) => {
  const processEnrollment = async () => {
    const verify = async () => {
      let newUser
      try {
        newUser = await Store.enroll(true)
        return newUser
      } catch (ex) {
        toast.error(`${ex}`, { position: toast.POSITION.TOP_RIGHT })
        return false
      }
    }

    const authenticate = async (newUser) => {
      try {
        const result = await Store.authenticate(newUser);
        if (!result) toast.error("Can not authenticate new agent, something went wrong!", { position: toast.POSITION.TOP_RIGHT })
        return result
      } catch (ex) {
        toast.error(`${ex}`, { position: toast.POSITION.TOP_RIGHT })
        return false
      }
    }

    if (Store.isAuthenticated()) {
      if (Store.isAssumed()) {
        // If the user is logged in & an account is assumed,
        // that my or may not mean we're already logged
        // in to a downline account.

        // If we're logged into a recruiter's account,
        // continue with enrolling.

        if (!Store.isDownlineRecruiter()) {
          // If we're logged into the downline agent
          // account, no need to authenticate or
          // enroll - these steps were previously
          // completed. Stop here. ---
          return true
        }

        // If we're logged into downline recruiter,
        // we still need to complete enrolling the
        // downline agent.
      } else if (Store.isRecruiter()) {
        // If the user is logged in & is a recruiter,
        // that means we are logged in while enrolling
        // a new downline agent.
        // Permit to proceed.
      } else if (Store.isEnrolled()) {
        // If the user is logged in, not a recruiter,
        // AND is already enrolled - that means we are
        // here in error (& shouldn't be).  Possibly a
        // strangely time enrollment page refresh, or
        // a different enrollment issue.  Redirect
        // this user back to the backoffice.
        // a new downline agent.
        // Permit to proceed.
        return false
      } else {
        // If the user is logged in & is NOT enrolled,
        // that means we are logged in as a new enrollee
        // that has not completed their enrollment process.
        // Do not proceed to enroll or authenticate,
        // But do permit agent to complete enrollment proess.
        return true
      }
    }

    Store.isCreating = true

    const newUser = await verify()
    if (!newUser) {
      Store.isCreating = false
      return false
    }

    const is_authenticate = await authenticate(newUser);
    if (is_authenticate) {
      Store.isCreating = false
      return true
    }
    
    Store.isCreating = false
    return false
  }

  const getStage = () => {
    if (Store.intakeStage) return `${Store.intakeStage}`
    return false
  }

  const onStageClosed = () => {
    Store.intakeStage = Store.nextIntakeStage
  }

  const goToStage = (goToIntake) => {
    if (
      Store.intakeStage === false ||
      (Store.isAuthenticated() && !Store.isRecruiter() && Store.isEnrolled())
    ) {
      window.location = '/welcome'
      return true
    }

    if (goToIntake === true) {
      if (!Store.isEnrolled() || Store.isRecruiter())
        return Store.completeEnrollment(Store.usertypeId).then(
          async (res) => {
            try {
              const enrollmentAudio = new Audio(completeEnrollmentAudio)
              enrollmentAudio.play()
              enrollmentAudio.onended = () => {
                window.location = '/welcome'
              }
            } catch (err) {
              window.location = '/welcome'
            }
          },
          (err) => toast.error(`${err}`, { position: toast.POSITION.TOP_RIGHT })
        )
    }

    Store.intakeStage = false

    if (goToIntake === 'IntakeFormPackageSelection') {
      Store.nextIntakeStage = false

      return processEnrollment().then(
        (res) =>
          (Store.intakeStage = Store.nextIntakeStage =
            !!res ? goToIntake : 'IntakeFormProfile'),
        (err) => (Store.intakeStage = 'IntakeFormProfile')
      )
    }

    Store.nextIntakeStage = goToIntake
  }

  return (
    <div id="EnrollmentStageManagerComponent">
      <MDBContainer>
        <MDBRow>
          <MDBCol>
            <div
              className={`loading-wrapper ${
                Store.isCreating ? 'is-loading' : ''
              }`}
            >
              <LoadingSpinner size="md" isActive={true} />
            </div>
            <div className="stage-wrapper">
              <MDBCollapse
                isOpen={
                  !Store.isCreating &&
                  !Store.isLoading &&
                  getStage() === 'IntakeFormIdentity' &&
                  !!upline?.id > 0
                }
                onClosed={onStageClosed}
              >
                {!Store.isCreating && !Store.isLoading && upline?.id > 0 ? (
                  <IntakeFormIdentity
                    upline={upline}
                    onComplete={(nextStage) => goToStage(nextStage)}
                  />
                ) : (
                  <></>
                )}
              </MDBCollapse>
              <MDBCollapse
                isOpen={
                  !Store.isCreating &&
                  !Store.isLoading &&
                  getStage() === 'IntakeFormProfile'
                }
                onClosed={onStageClosed}
              >
                {!Store.isCreating && !Store.isLoading ? (
                  <IntakeFormProfile
                    onComplete={(nextStage) => goToStage(nextStage)}
                  />
                ) : (
                  <></>
                )}
              </MDBCollapse>
              <MDBCollapse
                isOpen={
                  !Store.isCreating &&
                  !Store.isLoading &&
                  getStage() === 'IntakeFormPackageSelection'
                }
                onClosed={onStageClosed}
              >
                {!Store.isCreating &&
                !Store.isLoading &&
                Store.intakeStage === 'IntakeFormPackageSelection' ? (
                  <IntakeFormPackageSelection
                    onComplete={(nextStage) => goToStage(nextStage)}
                  />
                ) : (
                  <></>
                )}
              </MDBCollapse>
              <MDBCollapse
                isOpen={
                  !Store.isCreating &&
                  !Store.isLoading &&
                  getStage() === 'IntakeFormAcceptance'
                }
                onClosed={onStageClosed}
              >
                {!Store.isCreating && !Store.isLoading ? (
                  <IntakeFormAcceptance
                    onComplete={(nextStage) => goToStage(nextStage)}
                  />
                ) : (
                  <></>
                )}
              </MDBCollapse>
              <MDBCollapse
                isOpen={
                  !Store.isCreating &&
                  !Store.isLoading &&
                  getStage() === 'IntakeFormPayment'
                }
                onClosed={onStageClosed}
              >
                {!Store.isCreating &&
                !Store.isLoading &&
                [Store.intakeStage, Store.nextIntakeStage].includes(
                  'IntakeFormPayment'
                ) ? (
                  <IntakeFormPayment
                    onComplete={(nextStage) => goToStage(nextStage)}
                  />
                ) : (
                  <></>
                )}
              </MDBCollapse>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  )
}

export default observer(EnrollmentStageManager)
