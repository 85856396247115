import React, { useState, useEffect } from 'react'
import { toJS } from 'mobx'
import ContentHtml from './../../content/ContentHtml/ContentHtml.component'
import { observer } from 'mobx-react-lite'
import LedgersStore from './../store'
import { UIInput, UISelect, UIDropdown } from './../../forms/form-fields'
import UserProfileService from './../../../shared/services/UserProfile.service'
import UserDocumentService from '../../../shared/services/UserDocument.service'
import { MDBAlert, MDBIcon, MDBBtn, MDBCol, MDBCollapse } from 'mdbreact'
import AgentWalletDocumentsButton from './../../../components/agents/AgentWalletDocumentsButton/AgentWalletDocumentsButton.component'
import ReceiptUploaderModal from './../../modals/ReceiptUploaderModal'

import './TransfersBar.component.scss'
import { LedgerFactory } from '../../../shared/factories'
import UsertypeService from '../../../shared/services/Usertype.service'

const getUserOptions = (selectedId) => {
  return LedgersStore.TransferStore.Users.filter(
    (user) => ![111, 133, 223, 224].includes(user.usertype_id)
  ).map((user) => ({
    text: (
      <>
        {user.name + ' '}
        <br></br>
        <small>
          {user.email} | {user.usertype}
        </small>
      </>
    ),
    value: `${user.id}`,
    checked: !!(selectedId && `${selectedId}` === `${user.id}`),
  }))
}

const getLeadVendorOptions = (leadVendorId) => {
  return LedgersStore.TransferStore.LeadVendors.filter(
    (leadVendor) =>
      !leadVendor.deleted_at ||
      (leadVendor.id &&
        leadVendorId &&
        `${leadVendor.id}` === `${leadVendorId}`)
  ).map((leadVendor) => ({
    text: <>{leadVendor.name + ' '}</>,
    value: `${leadVendor.id}`,
    checked: !!(leadVendorId && `${leadVendorId}` === `${leadVendor.id}`),
  }))
}

const getCarrierOptions = (carrierId) => {
  return LedgersStore.TransferStore.Carriers.map((carrier) => ({
    text: (
      <>
        {carrier.name + ' '}
        <small>{carrier.description}</small>
      </>
    ),
    value: `${carrier.id}`,
    checked: !!(carrierId && `${carrierId}` === `${carrier.id}`),
  }))
}

const getFmoOptions = (fmoId) => {
  return LedgersStore.TransferStore.Fmos.map((fmo) => ({
    text: <>{fmo.name + ' '}</>,
    value: `${fmo.id}`,
    checked: !!(fmoId && `${fmoId}` === `${fmo.id}`),
  }))
}

const getCategoryOptions = (category) => {
  return LedgersStore.TransferStore.categories(
    LedgersStore.TransferStore.form
  ).map((cat) => ({
    value: cat,
    text: LedgersStore.TransferStore.category(cat),
    checked: category === cat,
  }))
}

const deleteReceipt = async (name) => {
  LedgersStore.TransferStore.isDeletingTravelRequestReceipt = true
  const receipt = (
    await UserDocumentService.search({
      search: {
        filepath: name,
      },
      pagination: false,
    })
  ).models
  if (receipt.length > 0 && receipt[0]?.id) {
    await UserDocumentService.delete(receipt[0]?.id)
    LedgersStore.TransferStore.travelRequestReceipts =
      LedgersStore.TransferStore.travelRequestReceipts.filter(
        (item) => item !== name
      )
    LedgersStore.TransferStore.isDeletingTravelRequestReceipt = false
  }
}

const TransferSourceLeadVendor = observer(({ required }) => {
  const onLeadVendorSelect = async (leadVendorId) => {
    let leadVendor = LedgersStore.TransferStore.LeadVendors.filter(
        (leadVendor) => leadVendor && leadVendor?.id === leadVendorId
      )
        .map((leadVendor) => leadVendor && toJS(leadVendor))
        .shift(),
      minimumRequired = leadVendor?.options?.minimum_required
    // console.log("Set the threshold: ",leadVendorId,leadVendor?.options?.minimum_required);
    // leadVendor?.options?.minimum_required

    LedgersStore.TransferStore.minimumRequired =
      !isNaN(minimumRequired) && minimumRequired !== null
        ? minimumRequired
        : null
    LedgersStore.TransferStore.transfer = {
      ...LedgersStore.TransferStore.transfer,
      lead_vendor_id: leadVendorId,
      lead_vendor_name: getLeadVendorOptions(leadVendorId)
        .filter((opt) => opt.checked)
        .shift()?.name,
    }
  }

  return (
    <UISelect
      label="Lead Vendor *"
      name="lead_vendor_id"
      disabled={LedgersStore.TransferStore.isDisabled}
      options={getLeadVendorOptions(
        LedgersStore.TransferStore.transfer.lead_vendor_id
      )}
      value={LedgersStore.TransferStore.transfer.lead_vendor_id || ''}
      selected={LedgersStore.TransferStore.transfer.lead_vendor_name || ''}
      onChange={(evt) => onLeadVendorSelect(evt.target.value)}
      required={required}
      rules={{ required }}
      showValidity={LedgersStore.TransferStore.showErrors}
    />
  )
})

const TransferSourceCarrier = observer(({ required }) => {
  const onCarrierSelect = async (carrierId) => {
    LedgersStore.TransferStore.transfer = {
      ...LedgersStore.TransferStore.transfer,
      carrier_id: carrierId,
      carrier_name: getCarrierOptions(carrierId)
        .filter((opt) => opt.checked)
        .shift()?.name,
    }
  }

  return (
    <UISelect
      label="Carrier"
      name="carrier_id"
      disabled={LedgersStore.TransferStore.isDisabled}
      options={getCarrierOptions(
        LedgersStore.TransferStore.transfer.carrier_id
      )}
      value={LedgersStore.TransferStore.transfer.carrier_id || ''}
      selected={LedgersStore.TransferStore.transfer.carrier_name || ''}
      onChange={(evt) => onCarrierSelect(evt.target.value)}
      required={required}
      rules={{ required }}
      showValidity={LedgersStore.TransferStore.showErrors}
    />
  )
})

const TransferSourceFmo = observer(({ required }) => {
  const onFmoSelect = async (fmoId) => {
    LedgersStore.TransferStore.transfer = {
      ...LedgersStore.TransferStore.transfer,
      fmo_id: fmoId,
      fmo_name: getFmoOptions(fmoId)
        .filter((opt) => opt.checked)
        .shift()?.name,
    }
  }

  return (
    <UISelect
      label="FMO"
      name="fmo_id"
      disabled={LedgersStore.TransferStore.isDisabled}
      options={getFmoOptions(LedgersStore.TransferStore.transfer.fmo_id)}
      value={LedgersStore.TransferStore.transfer.fmo_id || ''}
      selected={LedgersStore.TransferStore.transfer.fmo_name || ''}
      onChange={(evt) => onFmoSelect(evt.target.value)}
      required={required}
      rules={{ required }}
      showValidity={LedgersStore.TransferStore.showErrors}
    />
  )
})

const getUploadedReceipts = (urls) => {
  LedgersStore.TransferStore.travelRequestReceipts =
    LedgersStore.TransferStore.travelRequestReceipts.concat(
      urls.map((item) => item.file_path)
    )
}

const DocAttachment = observer(() => {
  const openModal = (uploadType) => {
    ReceiptUploaderModal.open({
      uploadType,
      modalContentTop: (
        <div className="text--center fw--500">
          <h6 className="fw--500">Upload receipts</h6>
        </div>
      ),
      validationRules: {
        contentType: ['application/pdf'],
      },
      onSuccess: (urls) => getUploadedReceipts(urls),
    })
      .then
      // LedgersStore.TransferStore.resolveTransferPrerequisites(LedgersStore.TransferStore.form)
      ()
      .catch((err) => {
        if (err) console.log('Failed to complete uploading receipts.', err)
      })
  }

  return (
    <MDBBtn
      block
      color="info"
      onClick={() => {
        openModal('travel_request_receipts')
      }}
    >
      <MDBIcon icon="file-medical" />
      &nbsp;
      <span>Upload Travel Request Receipts</span>
    </MDBBtn>
  )
})

const TransferAgentRecipient = observer(({ required }) => {
  const onAgentSelect = async (agentId) => {
    let Agent
    LedgersStore.TransferStore.setAgent(
      (Agent = await LedgersStore.findAgentById(agentId)),
      await LedgersStore.findOrCreateLedgerByAgent(Agent)
    )
  }

  return (
    <UIDropdown
      label="Agent"
      name="recipient_id"
      disabled={LedgersStore.TransferStore.isDisabled}
      options={getUserOptions(LedgersStore.TransferStore.transfer.recipient_id)}
      value={LedgersStore.TransferStore.transfer.recipient_id || ''}
      selected={LedgersStore.TransferStore.transfer.recipient_name || ''}
      onChange={(evt) => onAgentSelect(evt.target.value)}
      required={required}
      rules={{ required }}
      showValidity={LedgersStore.TransferStore.showErrors}
      search
    />
  )
})

const TransferAmount = observer(({ required }) => {
  let rules = { required }
  if (LedgersStore.TransferStore.minimumRequired > 0)
    rules.minValue = parseInt(LedgersStore.TransferStore.minimumRequired)

  return (
    <>
      <UIInput
        label="Amount"
        name="amount"
        search
        className="trans-agent-amount"
        value={LedgersStore.TransferStore.transfer.amount || ''}
        onChange={(evt) =>
          (LedgersStore.TransferStore.transfer.amount = evt.target.value)
        }
        required={required}
        rules={rules}
        type="integer"
        showValidity={LedgersStore.TransferStore.showErrors}
      />
      {LedgersStore.TransferStore.minimumRequired ? (
        <span class="min-order">
          Minimum Order: $
          {parseFloat(LedgersStore.TransferStore.minimumRequired).toFixed(2)}.
        </span>
      ) : (
        <></>
      )}
    </>
  )
})

const TransferCategory = observer(({ required }) => (
  <UISelect
    label="Category"
    name="category"
    options={getCategoryOptions(LedgersStore.TransferStore.transfer.category)}
    selected={LedgersStore.TransferStore.category(
      LedgersStore.TransferStore.transfer.category
    )}
    onChange={(evt) =>
      (LedgersStore.TransferStore.transfer.category = evt.target.value)
    }
    required={required}
    value={LedgersStore.TransferStore.transfer.category || ''}
    rules={{ required }}
    showValidity={LedgersStore.TransferStore.showErrors}
  />
))

const TransferPurpose = observer(({ required }) => (
  <UIInput
    label="Reason, purpose or detail"
    name="purpose"
    value={LedgersStore.TransferStore.transfer.purpose || ''}
    onChange={(evt) =>
      (LedgersStore.TransferStore.transfer.purpose = evt.target.value)
    }
    required={required}
    rules={{ required }}
    type="text"
    showValidity={LedgersStore.TransferStore.showErrors}
  />
))

const SubmitOutcome = observer(() => {
  return (
    <MDBCollapse
      isOpen={
        !!(
          LedgersStore.TransferStore.outcomeMsg &&
          (LedgersStore.TransferStore.showErrorMsg ||
            LedgersStore.TransferStore.showSuccessMsg)
        )
      }
    >
      <MDBAlert
        color={LedgersStore.TransferStore.showSuccessMsg ? 'success' : 'danger'}
      >
        <MDBIcon
          icon={
            LedgersStore.TransferStore.showSuccessMsg
              ? 'dollar-sign'
              : 'exclamation-triangle'
          }
        />
        &nbsp;{LedgersStore.TransferStore.outcomeMsg}
      </MDBAlert>
    </MDBCollapse>
  )
})

const ToggleAgentCashWithdrawal = observer(({ required }) => {
  // const onAgentSelect = async (agentId) => {
  //   let Agent
  //   LedgersStore.TransferStore.setAgent(
  //     (Agent = await LedgersStore.findAgentById(agentId)),
  //     await LedgersStore.findOrCreateLedgerByAgent(Agent)
  //   )
  // }

  return (
    <div id="ToggleAgentCashWithdrawalComponent" className="container-fluid">
      {LedgersStore.WalletMeta ? (
        <div className="content-wrapper row">
          <MDBCol size="12" sm="2" md="3">
            <MDBAlert
              color={
                LedgersStore.canAccessCashWithdrawal ? 'success' : 'danger'
              }
            >
              {LedgersStore.canAccessCashWithdrawal ? 'ACTIVE !' : 'INACTIVE'}
            </MDBAlert>
          </MDBCol>
          <MDBCol size="12" sm="8" md="6">
            Do you want to enable the Cash Withdrawal option for this agent?
            <br />
            <small>Cash Withdrawal requests will be created as 'PENDING'</small>
          </MDBCol>
          <MDBCol size="12" sm="2" md="3">
            <UIDropdown
              name="_can_cash_withdrawal"
              options={[
                {
                  text: 'Yes, agent can request Cash Withdrawal',
                  value: '1',
                  checked: LedgersStore.canAccessCashWithdrawal,
                },
                {
                  text: 'No, agent can not request Cash Withdrawal',
                  value: '0',
                  checked: !LedgersStore.canAccessCashWithdrawal,
                },
              ]}
              selected={
                LedgersStore.canAccessCashWithdrawal
                  ? 'Yes, agent can request Cash Withdrawal'
                  : 'No, agent can not request Cash Withdrawal'
              }
              onChange={(evt) =>
                LedgersStore.toggleCanUserAccessCashWithdrawal()
              }
              required={true}
              value={LedgersStore.canAccessCashWithdrawal ? '1' : '0'}
            />
          </MDBCol>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
  // <UISelect
  //   label="Agent"
  //   name="recipient_id"
  //   disabled={LedgersStore.TransferStore.isDisabled}
  //   options={getUserOptions(LedgersStore.TransferStore.transfer.recipient_id)}
  //   value={LedgersStore.TransferStore.transfer.recipient_id || ''}
  //   selected={LedgersStore.TransferStore.transfer.recipient_name || ''}
  //   onChange={(evt) => onAgentSelect(evt.target.value)}
  //   required={required}
  //   rules={{ required }}
  //   showValidity={LedgersStore.TransferStore.showErrors}
  // />
  // )
})

const getRequiredDocs = (transferReqs) => {
  let metas =
    (transferReqs?.documents && Object.values(transferReqs?.documents)) || []
  return !metas.filter((UserMeta) => !!UserMeta?.get('meta_value') !== true)
}

const doesTransferRequireDocuments = (transferReqs) => {
  let metas =
    (transferReqs &&
      transferReqs?.documents &&
      Object.values(transferReqs?.documents)) ||
    []
  return !metas.every((UserMeta) => !!UserMeta?.get('meta_value') === true)
}

const TransferFormSubmit = observer(({ isTravelRequest = false }) => {
  const isLoading =
      Object.keys(LedgersStore.TransferStore.transferRequirements || {})
        .length === 0,
    walletW9 =
      LedgersStore.TransferStore.isTransferReqMet('wallet---wallet-w9'),
    walletDD = LedgersStore.TransferStore.isTransferReqMet('wallet---wallet-dd')

  if (
    LedgersStore.TransferStore.transferRequirements === null ||
    (walletW9 === true && walletDD === true)
  ) {
    return (
      <MDBBtn
        disabled={
          LedgersStore.TransferStore.isValidating ||
          LedgersStore.TransferStore.isSaving ||
          (isTravelRequest &&
            !UserProfileService.isA(
              ['system-admin', 'internal-admin', 'agency-owner'],
              true
            ) &&
            LedgersStore.TransferStore.travelRequestReceipts.length === 0)
        }
        className="transfer-form-submit"
        type="submit"
      >
        {LedgersStore.TransferStore.isSaving ? (
          <>
            <i className="fa fa-spin fa-spinner"></i>&nbsp;Transferring BUCK$
          </>
        ) : (
          <>
            <MDBIcon icon="save" />
            &nbsp;Transfer BUCK$
          </>
        )}
      </MDBBtn>
    )
  }

  if (isLoading) return <MDBAlert color="warning"> Loading ... </MDBAlert>

  return (
    <AgentWalletDocumentsButton
      UserMetas={LedgersStore.TransferStore.transferRequirements.documents}
      onUpload={() => {
        LedgersStore.TransferStore.resolveTransferPrerequisites(
          LedgersStore.TransferStore.form
        )
      }}
    />
  )
})

const TransferForm = observer(() => {
  const [cashwithdrawalEnabledByUserType, setCashwithdrawalEnabledByUserType] =
    useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const user_type = await UsertypeService.get(
        UserProfileService.getCurrentUserTypeId()
      )
      setCashwithdrawalEnabledByUserType(
        user_type.cash_withdrawal_enabled === 1 ? true : false
      )
    }

    fetchData()
  }, [])

  const getRequired = () => {
    switch (LedgersStore.TransferStore.form) {
      case 'AgentAchievement':
        return {}
      case 'AgentTransfer':
        return {}
      case 'CashDeposit':
        return {}
      case 'StripeDeposit':
        return {}
      case 'Purchase':
        return {}
      case 'RymaxTransfer':
        return {}
      case 'SwagStoreTransfer':
        return {}
      case 'CarrierBonus':
        return {}
      case 'FmoBonus':
        return {}
      case 'TravelRequest':
        return {}
      case 'LeadVendorTransfer':
        return { purpose: false }
      case 'CashWithdrawal':
        return {}
    }
  }

  const getForm = () => {
    const getDefault = ({ required, isTravelRequest = false }) => {
      const cats = getCategoryOptions(),
        walletW9 =
          LedgersStore.TransferStore.isTransferReqMet('wallet---wallet-w9'),
        walletDD =
          LedgersStore.TransferStore.isTransferReqMet('wallet---wallet-dd')

      return (
        <>
          <TransferAmount
            required={required?.amount === false ? false : true}
          />
          {cats && cats.length === 1 ? (
            <></>
          ) : (
            <TransferCategory
              required={required?.category === false ? false : true}
            />
          )}
          <TransferPurpose
            required={required?.purpose === false ? false : true}
          />
          <SubmitOutcome />
          {isTravelRequest &&
            (LedgersStore.TransferStore.transferRequirements === null ||
              (walletW9 === true && walletDD === true)) && (
              <div className="row receiptUploadBtnField">
                <div className="col-md-6">
                  <DocAttachment />
                </div>
                <div className="col-md-6">
                  {LedgersStore.TransferStore.isDeletingTravelRequestReceipt ? (
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                      <i className="fa fa-spin fa-spinner"></i>&nbsp;Deleting...
                    </div>
                  ) : (
                    LedgersStore.TransferStore.travelRequestReceipts.map(
                      (item, index) => {
                        return (
                          <div
                            key={index}
                            className="d-flex align-items-center mb-1 text-break"
                          >
                            <p className="mb-0 mr-3">
                              {item?.split('/').pop()}
                            </p>
                            <i
                              className={'fa fa-trash red-text p-1'}
                              onClick={() => deleteReceipt(item)}
                            ></i>
                          </div>
                        )
                      }
                    )
                  )}
                </div>
              </div>
            )}
          <TransferFormSubmit isTravelRequest={isTravelRequest} />
        </>
      )
    }

    switch (LedgersStore.TransferStore.form) {
      case 'AgentAchievement':
        if (
          UserProfileService.isA(
            ['system-admin', 'internal-admin', 'agency-owner'],
            true
          )
        )
          return getDefault({ required: getRequired() })
        break
      case 'AgentTransfer':
        return (
          <>
            <TransferAgentRecipient />
            {getDefault({ required: getRequired() })}
          </>
        )
      case 'CashDeposit':
        if (
          UserProfileService.isA(
            ['system-admin', 'internal-admin', 'agency-owner'],
            true
          )
        )
          return getDefault({ required: getRequired() })
        return (
          <>
            <MDBAlert color="warning">
              You do not have access to this feature.
            </MDBAlert>
          </>
        )
      case 'StripeDeposit':
      case 'Purchase':
        return <>{getDefault({ required: getRequired() })}</>
      case 'RymaxTransfer':
        if (
          UserProfileService.isA(
            ['system-admin', 'internal-admin', 'agency-owner'],
            true
          )
        )
          return getDefault({ required: getRequired() })

        if (
          LedgersStore.TransferStore.pipLevel >= 8 ||
          LedgersStore.TransferStore.prevPipLevel >= 8
        )
          return getDefault({ required: getRequired() })

        return (
          <>
            <MDBAlert color="warning">
              You must reach diamond level before you can access this feature.
            </MDBAlert>
          </>
        )
      case 'SwagStoreTransfer':
        return getDefault({ required: getRequired() })
      case 'CarrierBonus':
        return (
          <>
            <TransferSourceCarrier />
            {getDefault({ required: getRequired() })}
          </>
        )
      case 'FmoBonus':
        return (
          <>
            <TransferSourceFmo />
            {getDefault({ required: getRequired() })}
          </>
        )
      case 'TravelRequest':
        return getDefault({ required: getRequired(), isTravelRequest: true })
      case 'LeadVendorTransfer':
        return (
          <>
            <TransferSourceLeadVendor />
            {getDefault({ required: getRequired() })}
          </>
        )
      case 'CashWithdrawal':
        if (
          UserProfileService.isA(
            [
              'system-admin',
              'internal-admin',
              'internal-staff',
              'agency-owner',
            ],
            true
          )
        )
          return (
            <>
              <ToggleAgentCashWithdrawal />
              {getDefault({ required: getRequired() })}
            </>
          )

        if (
          LedgersStore.canAccessCashWithdrawal ||
          cashwithdrawalEnabledByUserType
        )
          return <>{getDefault({ required: getRequired() })}</>

        return (
          <>
            <MDBAlert color="warning">
              You do not currently have access to this feature.
            </MDBAlert>
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <form
      id="TransferFormComponent"
      noValidate
      onSubmit={async (evt) => {
        evt.preventDefault()
        ;(await LedgersStore.TransferStore.validate({
          required: getRequired(),
        })) && (await LedgersStore.TransferStore.store())
        await LedgersStore.searchAgentLedgerTransactions({
          pagination: toJS(LedgersStore.ledgerTransactionPagination),
        })
        LedgersStore.Ledger = await LedgersStore.findLedgerById(
          LedgersStore.Ledger.id()
        )
        LedgersStore.TransferStore.travelRequestReceipts = []
        return false
      }}
    >
      {getForm()}
    </form>
  )
})

function onFormChange(f) {
  let form = LedgersStore.TransferStore.form || ''
  if (form && f && form !== f) {
    // form is open, but is changing.
    LedgersStore.TransferStore.showErrors = false
    LedgersStore.TransferStore.showErrorMsg = false
    LedgersStore.TransferStore.showSuccessMsg = false
    LedgersStore.TransferStore.outcomeMsg = ''
  } else if (!!form !== !!f) {
    // toggling open or closed.
    LedgersStore.TransferStore.create(LedgersStore.Ledger)
    LedgersStore.TransferStore.showErrors = false
    LedgersStore.TransferStore.showErrorMsg = false
    LedgersStore.TransferStore.showSuccessMsg = false
    LedgersStore.TransferStore.outcomeMsg = ''
  }

  LedgersStore.TransferStore.form = f
  LedgersStore.TransferStore.transfer.doner_id = LedgersStore.Ledger?.id()
}

const TransfersBar = observer(() => {
  const [cashwithdrawalEnabledByUserType, setCashwithdrawalEnabledByUserType] =
    useState(false)

  // we need to know here if this is a locked wallet.

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ledgers = await LedgerFactory.search({
          search: { user_id: UserProfileService.getUserId() },
          pagination: false,
        })

        const relationId = (ledgers || []).shift()?.get('id')

        if (relationId) {
          const travelRequestReceipts = await UserDocumentService.search({
            search: {
              relation_id: relationId,
              relation_model: 'StagedTravelReceipt',
            },
            pagination: false,
          })

          LedgersStore.TransferStore.travelRequestReceipts =
            travelRequestReceipts.models.map((item) => item.filepath)
        }

        const user_type = await UsertypeService.get(
          UserProfileService.getCurrentUserTypeId()
        )
        setCashwithdrawalEnabledByUserType(
          user_type.cash_withdrawal_enabled === 1 ? true : false
        )
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  const getPermittedCategories = () => {
    const isSigAgentOrAbove = UserProfileService.isA([
        'signature-higher-group',
      ]),
      isLeader =
        isSigAgentOrAbove && UserProfileService.isA(['recruiter-group']),
      isLeaderAdmin = UserProfileService.isA([
        'division-admin',
        'region-admin',
        'district-admin',
      ]),
      isInternalStaff = UserProfileService.isA(['internal'], true),
      canSwagStore = isSigAgentOrAbove,
      canTravelRequest = isSigAgentOrAbove || isInternalStaff,
      canAgentTransfer = isInternalStaff || isLeader || isLeaderAdmin,
      canAgentAchievement = isInternalStaff,
      canCarrierBonus = isInternalStaff,
      canFmoBonus = isInternalStaff,
      canRymaxTransfer =
        isInternalStaff ||
        LedgersStore.TransferStore.meetsCommissionLevelRequirements,
      canCashDeposit = isInternalStaff,
      canCashWithdrawal =
        isInternalStaff ||
        (
          (LedgersStore.canAccessCashWithdrawal ||
          cashwithdrawalEnabledByUserType) && LedgersStore.TransferStore.meetsCommissionLevelRequirements
        ),
      canLeadVendorTransfer = true

    return [
      canSwagStore ? 'SwagStoreTransfer' : null,
      canTravelRequest ? 'TravelRequest' : null,
      canAgentTransfer ? 'AgentTransfer' : null,
      canAgentAchievement ? 'AgentAchievement' : null,
      canCarrierBonus ? 'CarrierBonus' : null,
      canFmoBonus ? 'FmoBonus' : null,
      canRymaxTransfer ? 'RymaxTransfer' : null,
      canCashDeposit ? 'CashDeposit' : null,
      canLeadVendorTransfer ? 'LeadVendorTransfer' : null,
      canCashWithdrawal ? 'CashWithdrawal' : null,
    ].filter((n) => !!n)
  }

  const permitted = getPermittedCategories(),
    isDisabled = !!LedgersStore.TransferStore.Transaction,
    isOpen = !!LedgersStore.TransferStore.form && !isDisabled

  return (
    <div id="TransfersBarComponent" className="container-fluid">
      <div className="row btns-row">
        {permitted.includes('AgentTransfer') ? (
          <div className="col">
            <MDBBtn
              disabled={isDisabled}
              className={`expense-btn ${
                isOpen && LedgersStore.TransferStore.form === 'AgentTransfer'
                  ? 'active'
                  : ''
              }`}
              onClick={() => !isDisabled && onFormChange('AgentTransfer')}
            >
              <MDBIcon icon="minus-circle" />
              &nbsp;Agent Transfer
            </MDBBtn>
          </div>
        ) : (
          <></>
        )}
        {permitted.includes('SwagStoreTransfer') ? (
          <div className="col">
            <MDBBtn
              disabled={isDisabled}
              className={`expense-btn ${
                isOpen &&
                LedgersStore.TransferStore.form === 'SwagStoreTransfer'
                  ? 'active'
                  : ''
              }`}
              onClick={() => !isDisabled && onFormChange('SwagStoreTransfer')}
            >
              <MDBIcon icon="minus-circle" />
              &nbsp;Swag Store Transfer
            </MDBBtn>
          </div>
        ) : (
          <></>
        )}
        {permitted.includes('TravelRequest') ? (
          <div className="col">
            <MDBBtn
              disabled={isDisabled}
              className={`expense-btn ${
                isOpen && LedgersStore.TransferStore.form === 'TravelRequest'
                  ? 'active'
                  : ''
              }`}
              onClick={() => !isDisabled && onFormChange('TravelRequest')}
            >
              <MDBIcon icon="minus-circle" />
              &nbsp;Travel Request
            </MDBBtn>
          </div>
        ) : (
          <></>
        )}
        {permitted.includes('RymaxTransfer') ? (
          <div className="col">
            <MDBBtn
              disabled={isDisabled}
              className={`expense-btn ${
                isOpen && LedgersStore.TransferStore.form === 'RymaxTransfer'
                  ? 'active'
                  : ''
              }`}
              onClick={() => !isDisabled && onFormChange('RymaxTransfer')}
            >
              <MDBIcon icon="minus-circle" />
              &nbsp;Rymax Transfer
            </MDBBtn>
          </div>
        ) : (
          <></>
        )}
        {permitted.includes('LeadVendorTransfer') ? (
          <div className="col">
            <MDBBtn
              disabled={isDisabled}
              className={`expense-btn ${
                isOpen &&
                LedgersStore.TransferStore.form === 'LeadVendorTransfer'
                  ? 'active'
                  : ''
              }`}
              onClick={() => !isDisabled && onFormChange('LeadVendorTransfer')}
            >
              <MDBIcon icon="minus-circle" />
              &nbsp;Lead Vendor Transfer
            </MDBBtn>
          </div>
        ) : (
          <></>
        )}
        {permitted.includes('CashWithdrawal') ? (
          <div className="col">
            <MDBBtn
              disabled={isDisabled}
              className={`expense-btn ${
                isOpen && LedgersStore.TransferStore.form === 'CashWithdrawal'
                  ? 'active'
                  : ''
              }`}
              onClick={() => !isDisabled && onFormChange('CashWithdrawal')}
            >
              <MDBIcon icon="minus-circle" />
              &nbsp;Cash Withdrawal
            </MDBBtn>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="row btns-row">
        {permitted.includes('AgentAchievement') ? (
          <div className="col">
            <MDBBtn
              disabled={isDisabled}
              className={`debit-btn ${
                isOpen && LedgersStore.TransferStore.form === 'AgentAchievement'
                  ? 'active'
                  : ''
              }`}
              onClick={() => !isDisabled && onFormChange('AgentAchievement')}
            >
              <MDBIcon icon="plus-circle" />
              &nbsp;Agent Achievements
            </MDBBtn>
          </div>
        ) : (
          <></>
        )}
        {permitted.includes('CarrierBonus') ? (
          <div className="col">
            <MDBBtn
              disabled={isDisabled}
              className={`debit-btn ${
                isOpen && LedgersStore.TransferStore.form === 'CarrierBonus'
                  ? 'active'
                  : ''
              }`}
              onClick={() => !isDisabled && onFormChange('CarrierBonus')}
            >
              <MDBIcon icon="plus-circle" />
              &nbsp;Carrier Bonus
            </MDBBtn>
          </div>
        ) : (
          <></>
        )}
        {permitted.includes('FmoBonus') ? (
          <div className="col">
            <MDBBtn
              disabled={isDisabled}
              className={`debit-btn ${
                isOpen && LedgersStore.TransferStore.form === 'FmoBonus'
                  ? 'active'
                  : ''
              }`}
              onClick={() => !isDisabled && onFormChange('FmoBonus')}
            >
              <MDBIcon icon="plus-circle" />
              &nbsp;FMO Bonus
            </MDBBtn>
          </div>
        ) : (
          <></>
        )}
        {permitted.includes('CashDeposit') ? (
          <div className="col">
            <MDBBtn
              disabled={isDisabled}
              className={`debit-btn ${
                isOpen && LedgersStore.TransferStore.form === 'CashDeposit'
                  ? 'active'
                  : ''
              }`}
              onClick={() => !isDisabled && onFormChange('CashDeposit')}
            >
              <MDBIcon icon="plus-circle" />
              &nbsp;Cash Deposit
            </MDBBtn>
          </div>
        ) : (
          <></>
        )}
        {permitted.includes('Purchase ') ? (
          <div className="col">
            <MDBBtn
              disabled={isDisabled}
              className={`debit-btn ${
                isOpen && LedgersStore.TransferStore.form === 'Purchase '
                  ? 'active'
                  : ''
              }`}
              onClick={() => !isDisabled && onFormChange('Purchase ')}
            >
              <MDBIcon icon="plus-circle" />
              &nbsp;Stripe Deposit
            </MDBBtn>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="row">
        <div className="col-12">
          <MDBCollapse
            isOpen={isOpen}
            className="container-fluid transfer-form-wrapper"
          >
            <div className="row">
              <div className="col">
                {isOpen ? (
                  <ContentHtml
                    contentSlug={`lead-credit-${LedgersStore.TransferStore.form
                      .replace(/([a-z0–9])([A-Z])/g, '$1-$2')
                      .toLowerCase()}-howto`}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="close-btn">
                <MDBBtn onClick={() => onFormChange(null)}>
                  Close&nbsp;
                  <MDBIcon icon="times" />
                </MDBBtn>
              </div>
            </div>
            {isOpen && <TransferForm />}
          </MDBCollapse>
        </div>
      </div>
    </div>
  )
})

export default TransfersBar
