import React from 'react'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import { MDBContainer, MDBBtn, MDBCol, MDBRow } from 'mdbreact'
import ContentHtmlCard from './../../components/content/ContentHtmlCard/ContentHtmlCard.component'
import CarrierContactsModal from './../../components/modals/CarrierContactsModal/CarrierContactsModal.component'
import UplineContactsModal from './../../components/modals/UplineContactsModal/UplineContactsModal.component'

import './Support.page.scss'

class SupportPage extends React.Component {
  #renderSubmitTicketBtn = (url) =>
    url ? (
      <MDBBtn
        className="support-ticket-btn btn-block"
        color="primary"
        href={url}
        target="_blank"
      >
        Submit Ticket
      </MDBBtn>
    ) : (
      <></>
    )

  render() {
    return (
      <DashboardLayout>
        <main id="SupportPage" className="adminStyle mainSection">
          <MDBContainer fluid className="mt-5">
            <div style={{ backgroundColor: '#2d38a8' }} className="mb-2">
              <img
                src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREABSE_STORAGE_BUCKET}/o/static%2Fimg.header.help-support.jpg?alt=media`}
                className="img-fluid"
                alt="USABG Support & Help Resources"
              />
            </div>
            <MDBRow>
              <MDBCol className="mt-2 mb-2">
                <UplineContactsModal />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="6" sm="12">
                <ContentHtmlCard
                  fullHeight={true}
                  contentId={28}
                  appendContent={this.#renderSubmitTicketBtn(
                    'https://usabg.atlassian.net/servicedesk/customer/portal/2'
                  )}
                />
              </MDBCol>
              <MDBCol md="6" sm="12">
                <ContentHtmlCard
                  fullHeight={true}
                  contentId={29}
                  appendContent={
                    <>
                      {this.#renderSubmitTicketBtn(
                        'https://usabg.atlassian.net/servicedesk/customer/portal/3'
                      )}
                      <CarrierContactsModal />
                    </>
                  }
                />
              </MDBCol>
              {/*
              <MDBCol md="6" sm="12">
                <ContentHtmlCard
                  fullHeight={true}
                  contentId={30}
                  appendContent={this.#renderSubmitTicketBtn(
                    'https://usabg.atlassian.net/servicedesk/customer/portal/1'
                  )}
                />
              </MDBCol>
              */}
              {/* <MDBCol md="6" sm="12">
                <ContentHtmlCard
                  fullHeight={true}
                  contentId={34}
                  appendContent={
                    <>
                      {this.#renderSubmitTicketBtn(
                        'https://usabg.atlassian.net/servicedesk/customer/portal/4'
                      )}
                      <CarrierContactsModal />
                    </>
                  }
                />
              </MDBCol> */}
              <MDBCol md="6" sm="12">
                <ContentHtmlCard
                  fullHeight={true}
                  contentId={42}
                  appendContent={this.#renderSubmitTicketBtn(
                    'https://usabg.atlassian.net/servicedesk/customer/portal/5'
                  )}
                />
              </MDBCol>
              <MDBCol md="6" sm="12">
                <ContentHtmlCard fullHeight={true} contentId={151} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </main>
      </DashboardLayout>
    )
  }
}

export default SupportPage
