import env from '../environments/environment'

const appConstants = () => ({
  States: [
    { name: 'Alabama', id: 'AL' },
    { name: 'Alaska', id: 'AK' },
    { name: 'Arizona', id: 'AZ' },
    { name: 'Arkansas', id: 'AR' },
    { name: 'California', id: 'CA' },
    { name: 'Colorado', id: 'CO' },
    { name: 'Connecticut', id: 'CT' },
    { name: 'Delaware', id: 'DE' },
    { name: 'District of Columbia', id: 'DC' },
    { name: 'Florida', id: 'FL' },
    { name: 'Georgia', id: 'GA' },
    { name: 'Hawaii', id: 'HI' },
    { name: 'Idaho', id: 'ID' },
    { name: 'Illinois', id: 'IL' },
    { name: 'Indiana', id: 'IN' },
    { name: 'Iowa', id: 'IA' },
    { name: 'Kansas', id: 'KS' },
    { name: 'Kentucky', id: 'KY' },
    { name: 'Lousiana', id: 'LA' },
    { name: 'Maine', id: 'ME' },
    { name: 'Maryland', id: 'MD' },
    { name: 'Massachusetts', id: 'MA' },
    { name: 'Michigan', id: 'MI' },
    { name: 'Minnesota', id: 'MN' },
    { name: 'Mississippi', id: 'MS' },
    { name: 'Missouri', id: 'MO' },
    { name: 'Montana', id: 'MT' },
    { name: 'Nebraska', id: 'NE' },
    { name: 'Nevada', id: 'NV' },
    { name: 'New Hampshire', id: 'NH' },
    { name: 'New Jersey', id: 'NJ' },
    { name: 'New Mexico', id: 'NM' },
    { name: 'New York', id: 'NY' },
    { name: 'North Carolina', id: 'NC' },
    { name: 'North Dakota', id: 'ND' },
    { name: 'Ohio', id: 'OH' },
    { name: 'Oklahoma', id: 'OK' },
    { name: 'Oregon', id: 'OR' },
    { name: 'Pennsylvania', id: 'PA' },
    { name: 'Rhode Island', id: 'RI' },
    { name: 'South Carolina', id: 'SC' },
    { name: 'South Dakota', id: 'SD' },
    { name: 'Tennessee', id: 'TN' },
    { name: 'Texas', id: 'TX' },
    { name: 'Utah', id: 'UT' },
    { name: 'Vermont', id: 'VT' },
    { name: 'Virginia', id: 'VA' },
    { name: 'Washington', id: 'WA' },
    { name: 'West Virginia', id: 'WV' },
    { name: 'Wisconsin', id: 'WI' },
    { name: 'Wyoming', id: 'WY' },
  ],

  AgentIntakeColumHeadings: [
    'State',
    'LisenceNumber',
    'Effective Date',
    'Exp Date',
    'Upload',
    'Done',
  ],

  EnrollSource: [
    { name: 'Indeed', id: 'Indeed' },
    { name: 'Referral', id: 'Referral' },
    { name: 'Text Message', id: 'Text Message' },
    { name: 'Email', id: 'Email' },
    { name: 'Other', id: 'Other' },
  ],

  Health: [
    { id: 'HLT', name: 'Health' },
    { id: 'ANt', name: 'Annuity' },
    { id: 'CNP', name: 'Cancer Plan' },
    { id: 'FLE', name: 'Final Expense' },
    { id: 'LML', name: 'Limited Medical' },
    { id: 'MMD', name: 'Major Medical' },
    { id: 'GAH', name: 'Guaranteed Acceptance Health Plans' },
    { id: 'STM', name: 'Short Term Medical' },
    { id: 'LIC', name: 'Life Insurance' },
    { id: 'HRA', name: 'Health Reimbursement Arrangement (HRA)' },
    { id: 'CIL', name: 'Critical Illness' },
    { id: 'ACP', name: 'Accident Plans' },
    { id: 'MSP', name: 'Medicare Supplements' },
    { id: 'DLI', name: 'Dental Insurance' },
    { id: 'DVD', name: 'Dental and Vision Discount Plan' },
    { id: 'ITI', name: ' International Travel Insurance' },
    { id: 'PPM', name: 'Prescription Programs' },
    { id: 'MLP', name: 'Miscellaneous Products' },
  ],

  Timezone: [
    { id: 'America/Puerto Rico', name: 'AST - America/Puerto Rico' },
    { id: 'America/New_York', name: 'EDT - America/New York' },
    { id: 'America/Chicago', name: 'CDT - America/Chicago' },
    { id: 'America/Boise', name: 'MDT - America/Boise' },
    { id: 'America/Phoenix', name: 'MST - America/Phoenix' },
    { id: 'America/Los Angeles', name: 'PDT - America/Los Angeles' },
    { id: 'America/Juneau', name: 'AKDT - America/Juneau' },
    { id: 'Pacific/Honolulu', name: 'HST - Pacific/Honolulu' },
    { id: 'Pacific/Guam', name: 'ChST - Pacific/Guam' },
    { id: 'Pacific/Wake', name: 'WAKT - Pacific/Wake' },
    { id: 'Pacific/Samoa', name: 'SST - Pacific/Samoa' },
  ],

  GoogleTimezone: [
    { name: 'Pacific/Niue', GMT: '-11:00' },
    { name: 'Pacific/Pago_Pago', GMT: '-11:00' },
    { name: 'Pacific/Honolulu', GMT: '-10:00' },
    { name: 'Pacific/Rarotonga', GMT: '-10:00' },
    { name: 'Pacific/Tahiti', GMT: '-10:00' },
    { name: 'Pacific/Marquesas', GMT: '-09:30' },
    { name: 'America/Anchorage', GMT: '-09:00' },
    { name: 'Pacific/Gambier', GMT: '-09:00' },
    { name: 'America/Los_Angeles', GMT: '-08:00' },
    { name: 'America/Tijuana', GMT: '-08:00' },
    { name: 'America/Vancouver', GMT: '-08:00' },
    { name: 'America/Whitehorse', GMT: '-08:00' },
    { name: 'Pacific/Pitcairn', GMT: '-08:00' },
    { name: 'America/Dawson_Creek', GMT: '-07:00' },
    { name: 'America/Denver', GMT: '-07:00' },
    { name: 'America/Edmonton', GMT: '-07:00' },
    { name: 'America/Hermosillo', GMT: '-07:00' },
    { name: 'America/Mazatlan', GMT: '-07:00' },
    { name: 'America/Phoenix', GMT: '-07:00' },
    { name: 'America/Yellowknife', GMT: '-07:00' },
    { name: 'America/Belize', GMT: '-06:00' },
    { name: 'America/Chicago', GMT: '-06:00' },
    { name: 'America/Costa_Rica', GMT: '-06:00' },
    { name: 'America/El_Salvador', GMT: '-06:00' },
    { name: 'America/Guatemala', GMT: '-06:00' },
    { name: 'America/Managua', GMT: '-06:00' },
    { name: 'America/Mexico_City', GMT: '-06:00' },
    { name: 'America/Regina', GMT: '-06:00' },
    { name: 'America/Tegucigalpa', GMT: '-06:00' },
    { name: 'America/Winnipeg', GMT: '-06:00' },
    { name: 'Pacific/Galapagos', GMT: '-06:00' },
    { name: 'America/Bogota', GMT: '-05:00' },
    { name: 'America/Cancun', GMT: '-05:00' },
    { name: 'America/Cayman', GMT: '-05:00' },
    { name: 'America/Guayaquil', GMT: '-05:00' },
    { name: 'America/Havana', GMT: '-05:00' },
    { name: 'America/Iqaluit', GMT: '-05:00' },
    { name: 'America/Jamaica', GMT: '-05:00' },
    { name: 'America/Lima', GMT: '-05:00' },
    { name: 'America/Nassau', GMT: '-05:00' },
    { name: 'America/New_York', GMT: '-05:00' },
    { name: 'America/Panama', GMT: '-05:00' },
    { name: 'America/Port-au-Prince', GMT: '-05:00' },
    { name: 'America/Rio_Branco', GMT: '-05:00' },
    { name: 'America/Toronto', GMT: '-05:00' },
    { name: 'Pacific/Easter', GMT: '-05:00' },
    { name: 'America/Caracas', GMT: '-04:30' },
    { name: 'America/Asuncion', GMT: '-03:00' },
    { name: 'America/Barbados', GMT: '-04:00' },
    { name: 'America/Boa_Vista', GMT: '-04:00' },
    { name: 'America/Campo_Grande', GMT: '-03:00' },
    { name: 'America/Cuiaba', GMT: '-03:00' },
    { name: 'America/Curacao', GMT: '-04:00' },
    { name: 'America/Grand_Turk', GMT: '-04:00' },
    { name: 'America/Guyana', GMT: '-04:00' },
    { name: 'America/Halifax', GMT: '-04:00' },
    { name: 'America/La_Paz', GMT: '-04:00' },
    { name: 'America/Manaus', GMT: '-04:00' },
    { name: 'America/Martinique', GMT: '-04:00' },
    { name: 'America/Port_of_Spain', GMT: '-04:00' },
    { name: 'America/Porto_Velho', GMT: '-04:00' },
    { name: 'America/Puerto_Rico', GMT: '-04:00' },
    { name: 'America/Santo_Domingo', GMT: '-04:00' },
    { name: 'America/Thule', GMT: '-04:00' },
    { name: 'Atlantic/Bermuda', GMT: '-04:00' },
    { name: 'America/St_Johns', GMT: '-03:30' },
    { name: 'America/Araguaina', GMT: '-03:00' },
    { name: 'America/Argentina/Buenos_Aires', GMT: '-03:00' },
    { name: 'America/Bahia', GMT: '-03:00' },
    { name: 'America/Belem', GMT: '-03:00' },
    { name: 'America/Cayenne', GMT: '-03:00' },
    { name: 'America/Fortaleza', GMT: '-03:00' },
    { name: 'America/Godthab', GMT: '-03:00' },
    { name: 'America/Maceio', GMT: '-03:00' },
    { name: 'America/Miquelon', GMT: '-03:00' },
    { name: 'America/Montevideo', GMT: '-03:00' },
    { name: 'America/Paramaribo', GMT: '-03:00' },
    { name: 'America/Recife', GMT: '-03:00' },
    { name: 'America/Santiago', GMT: '-03:00' },
    { name: 'America/Sao_Paulo', GMT: '-02:00' },
    { name: 'Antarctica/Palmer', GMT: '-03:00' },
    { name: 'Antarctica/Rothera', GMT: '-03:00' },
    { name: 'Atlantic/Stanley', GMT: '-03:00' },
    { name: 'America/Noronha', GMT: '-02:00' },
    { name: 'Atlantic/South_Georgia', GMT: '-02:00' },
    { name: 'America/Scoresbysund', GMT: '-01:00' },
    { name: 'Atlantic/Azores', GMT: '-01:00' },
    { name: 'Atlantic/Cape_Verde', GMT: '-01:00' },
    { name: 'Africa/Abidjan', GMT: '+00:00' },
    { name: 'Africa/Accra', GMT: '+00:00' },
    { name: 'Africa/Bissau', GMT: '+00:00' },
    { name: 'Africa/Casablanca', GMT: '+00:00' },
    { name: 'Africa/El_Aaiun', GMT: '+00:00' },
    { name: 'Africa/Monrovia', GMT: '+00:00' },
    { name: 'America/Danmarkshavn', GMT: '+00:00' },
    { name: 'Atlantic/Canary', GMT: '+00:00' },
    { name: 'Atlantic/Faroe', GMT: '+00:00' },
    { name: 'Atlantic/Reykjavik', GMT: '+00:00' },
    { name: 'Etc/GMT', GMT: '+00:00' },
    { name: 'Europe/Dublin', GMT: '+00:00' },
    { name: 'Europe/Lisbon', GMT: '+00:00' },
    { name: 'Europe/London', GMT: '+00:00' },
    { name: 'Africa/Algiers', GMT: '+01:00' },
    { name: 'Africa/Ceuta', GMT: '+01:00' },
    { name: 'Africa/Lagos', GMT: '+01:00' },
    { name: 'Africa/Ndjamena', GMT: '+01:00' },
    { name: 'Africa/Tunis', GMT: '+01:00' },
    { name: 'Africa/Windhoek', GMT: '+02:00' },
    { name: 'Europe/Amsterdam', GMT: '+01:00' },
    { name: 'Europe/Andorra', GMT: '+01:00' },
    { name: 'Europe/Belgrade', GMT: '+01:00' },
    { name: 'Europe/Berlin', GMT: '+01:00' },
    { name: 'Europe/Brussels', GMT: '+01:00' },
    { name: 'Europe/Budapest', GMT: '+01:00' },
    { name: 'Europe/Copenhagen', GMT: '+01:00' },
    { name: 'Europe/Gibraltar', GMT: '+01:00' },
    { name: 'Europe/Luxembourg', GMT: '+01:00' },
    { name: 'Europe/Madrid', GMT: '+01:00' },
    { name: 'Europe/Malta', GMT: '+01:00' },
    { name: 'Europe/Monaco', GMT: '+01:00' },
    { name: 'Europe/Oslo', GMT: '+01:00' },
    { name: 'Europe/Paris', GMT: '+01:00' },
    { name: 'Europe/Prague', GMT: '+01:00' },
    { name: 'Europe/Rome', GMT: '+01:00' },
    { name: 'Europe/Stockholm', GMT: '+01:00' },
    { name: 'Europe/Tirane', GMT: '+01:00' },
    { name: 'Europe/Vienna', GMT: '+01:00' },
    { name: 'Europe/Warsaw', GMT: '+01:00' },
    { name: 'Europe/Zurich', GMT: '+01:00' },
    { name: 'Africa/Cairo', GMT: '+02:00' },
    { name: 'Africa/Johannesburg', GMT: '+02:00' },
    { name: 'Africa/Maputo', GMT: '+02:00' },
    { name: 'Africa/Tripoli', GMT: '+02:00' },
    { name: 'Asia/Amman', GMT: '+02:00' },
    { name: 'Asia/Beirut', GMT: '+02:00' },
    { name: 'Asia/Damascus', GMT: '+02:00' },
    { name: 'Asia/Gaza', GMT: '+02:00' },
    { name: 'Asia/Jerusalem', GMT: '+02:00' },
    { name: 'Asia/Nicosia', GMT: '+02:00' },
    { name: 'Europe/Athens', GMT: '+02:00' },
    { name: 'Europe/Bucharest', GMT: '+02:00' },
    { name: 'Europe/Chisinau', GMT: '+02:00' },
    { name: 'Europe/Helsinki', GMT: '+02:00' },
    { name: 'Europe/Istanbul', GMT: '+02:00' },
    { name: 'Europe/Kaliningrad', GMT: '+02:00' },
    { name: 'Europe/Kiev', GMT: '+02:00' },
    { name: 'Europe/Riga', GMT: '+02:00' },
    { name: 'Europe/Sofia', GMT: '+02:00' },
    { name: 'Europe/Tallinn', GMT: '+02:00' },
    { name: 'Europe/Vilnius', GMT: '+02:00' },
    { name: 'Africa/Khartoum', GMT: '+03:00' },
    { name: 'Africa/Nairobi', GMT: '+03:00' },
    { name: 'Antarctica/Syowa', GMT: '+03:00' },
    { name: 'Asia/Baghdad', GMT: '+03:00' },
    { name: 'Asia/Qatar', GMT: '+03:00' },
    { name: 'Asia/Riyadh', GMT: '+03:00' },
    { name: 'Europe/Minsk', GMT: '+03:00' },
    { name: 'Europe/Moscow', GMT: '+03:00' },
    { name: 'Asia/Tehran', GMT: '+03:30' },
    { name: 'Asia/Baku', GMT: '+04:00' },
    { name: 'Asia/Dubai', GMT: '+04:00' },
    { name: 'Asia/Tbilisi', GMT: '+04:00' },
    { name: 'Asia/Yerevan', GMT: '+04:00' },
    { name: 'Europe/Samara', GMT: '+04:00' },
    { name: 'Indian/Mahe', GMT: '+04:00' },
    { name: 'Indian/Mauritius', GMT: '+04:00' },
    { name: 'Indian/Reunion', GMT: '+04:00' },
    { name: 'Asia/Kabul', GMT: '+04:30' },
    { name: 'Antarctica/Mawson', GMT: '+05:00' },
    { name: 'Asia/Aqtau', GMT: '+05:00' },
    { name: 'Asia/Aqtobe', GMT: '+05:00' },
    { name: 'Asia/Ashgabat', GMT: '+05:00' },
    { name: 'Asia/Dushanbe', GMT: '+05:00' },
    { name: 'Asia/Karachi', GMT: '+05:00' },
    { name: 'Asia/Tashkent', GMT: '+05:00' },
    { name: 'Asia/Yekaterinburg', GMT: '+05:00' },
    { name: 'Indian/Kerguelen', GMT: '+05:00' },
    { name: 'Indian/Maldives', GMT: '+05:00' },
    { name: 'Asia/Calcutta', GMT: '+05:30' },
    { name: 'Asia/Colombo', GMT: '+05:30' },
    { name: 'Asia/Katmandu', GMT: '+05:45' },
    { name: 'Antarctica/Vostok', GMT: '+06:00' },
    { name: 'Asia/Almaty', GMT: '+06:00' },
    { name: 'Asia/Bishkek', GMT: '+06:00' },
    { name: 'Asia/Dhaka', GMT: '+06:00' },
    { name: 'Asia/Omsk', GMT: '+06:00' },
    { name: 'Asia/Thimphu', GMT: '+06:00' },
    { name: 'Indian/Chagos', GMT: '+06:00' },
    { name: 'Asia/Rangoon', GMT: '+06:30' },
    { name: 'Indian/Cocos', GMT: '+06:30' },
    { name: 'Antarctica/Davis', GMT: '+07:00' },
    { name: 'Asia/Bangkok', GMT: '+07:00' },
    { name: 'Asia/Hovd', GMT: '+07:00' },
    { name: 'Asia/Jakarta', GMT: '+07:00' },
    { name: 'Asia/Krasnoyarsk', GMT: '+07:00' },
    { name: 'Asia/Saigon', GMT: '+07:00' },
    { name: 'Asia/Ho_Chi_Minh', GMT: '+07:00' },
    { name: 'Indian/Christmas', GMT: '+07:00' },
    { name: 'Antarctica/Casey', GMT: '+08:00' },
    { name: 'Asia/Brunei', GMT: '+08:00' },
    { name: 'Asia/Choibalsan', GMT: '+08:00' },
    { name: 'Asia/Hong_Kong', GMT: '+08:00' },
    { name: 'Asia/Irkutsk', GMT: '+08:00' },
    { name: 'Asia/Kuala_Lumpur', GMT: '+08:00' },
    { name: 'Asia/Macau', GMT: '+08:00' },
    { name: 'Asia/Makassar', GMT: '+08:00' },
    { name: 'Asia/Manila', GMT: '+08:00' },
    { name: 'Asia/Shanghai', GMT: '+08:00' },
    { name: 'Asia/Singapore', GMT: '+08:00' },
    { name: 'Asia/Taipei', GMT: '+08:00' },
    { name: 'Asia/Ulaanbaatar', GMT: '+08:00' },
    { name: 'Australia/Perth', GMT: '+08:00' },
    { name: 'Asia/Pyongyang', GMT: '+08:30' },
    { name: 'Asia/Dili', GMT: '+09:00' },
    { name: 'Asia/Jayapura', GMT: '+09:00' },
    { name: 'Asia/Seoul', GMT: '+09:00' },
    { name: 'Asia/Tokyo', GMT: '+09:00' },
    { name: 'Asia/Yakutsk', GMT: '+09:00' },
    { name: 'Pacific/Palau', GMT: '+09:00' },
    { name: 'Australia/Adelaide', GMT: '+10:30' },
    { name: 'Australia/Darwin', GMT: '+09:30' },
    { name: 'Antarctica/DumontDUrville', GMT: '+10:00' },
    { name: 'Asia/Magadan', GMT: '+10:00' },
    { name: 'Asia/Vladivostok', GMT: '+10:00' },
    { name: 'Australia/Brisbane', GMT: '+10:00' },
    { name: 'Australia/Hobart', GMT: '+11:00' },
    { name: 'Australia/Sydney', GMT: '+11:00' },
    { name: 'Pacific/Chuuk', GMT: '+10:00' },
    { name: 'Pacific/Guam', GMT: '+10:00' },
    { name: 'Pacific/Port_Moresby', GMT: '+10:00' },
    { name: 'Pacific/Efate', GMT: '+11:00' },
    { name: 'Pacific/Guadalcanal', GMT: '+11:00' },
    { name: 'Pacific/Kosrae', GMT: '+11:00' },
    { name: 'Pacific/Norfolk', GMT: '+11:00' },
    { name: 'Pacific/Noumea', GMT: '+11:00' },
    { name: 'Pacific/Pohnpei', GMT: '+11:00' },
    { name: 'Asia/Kamchatka', GMT: '+12:00' },
    { name: 'Pacific/Auckland', GMT: '+13:00' },
    { name: 'Pacific/Fiji', GMT: '+13:00' },
    { name: 'Pacific/Funafuti', GMT: '+12:00' },
    { name: 'Pacific/Kwajalein', GMT: '+12:00' },
    { name: 'Pacific/Majuro', GMT: '+12:00' },
    { name: 'Pacific/Nauru', GMT: '+12:00' },
    { name: 'Pacific/Tarawa', GMT: '+12:00' },
    { name: 'Pacific/Wake', GMT: '+12:00' },
    { name: 'Pacific/Wallis', GMT: '+12:00' },
    { name: 'Pacific/Apia', GMT: '+14:00' },
    { name: 'Pacific/Enderbury', GMT: '+13:00' },
    { name: 'Pacific/Fakaofo', GMT: '+13:00' },
    { name: 'Pacific/Tongatapu', GMT: '+13:00' },
    { name: 'Pacific/Kiritimati', GMT: '+14:00' },
  ],

  termLength: [
    {
      text: '1 month',
      value: '1',
    },
    {
      text: '2 months',
      value: '2',
    },
    {
      text: '3 months',
      value: '3',
    },
    {
      text: '4 months',
      value: '4',
    },
    {
      text: '5 months',
      value: '5',
    },
    {
      text: '6 months',
      value: '6',
    },
    {
      text: '7 months',
      value: '7',
    },
    {
      text: '8 months',
      value: '8',
    },
    {
      text: '9 months',
      value: '9',
    },
    {
      text: '10 months',
      value: '10',
    },
    {
      text: '11 months',
      value: '11',
    },
    {
      text: '12 months',
      value: '12',
    },
  ],

  lives: [
    {
      text: '1',
      value: 1,
    },
    {
      text: '2',
      value: 2,
    },
    {
      text: '3',
      value: 3,
    },
    {
      text: '4',
      value: 4,
    },
    {
      text: '5',
      value: 5,
    },
    {
      text: '6',
      value: 6,
    },
    {
      text: '7',
      value: 7,
    },
    {
      text: '8',
      value: 8,
    },
    {
      text: '9',
      value: 9,
    },
    {
      text: '10',
      value: 10,
    },
  ],

  agents: [90, 38, 129, 93, 130, 92, 149, 91, 223, 224, 133, 111],
  admin: [219, 36, 222, 35],

  logos: {
    master: {
      // 360x133
      sm: 'https://firebasestorage.googleapis.com/v0/b/totemic-bonus-239316.appspot.com/o/logos%2Flogo.usabg-flag.sm.jpg?alt=media',
      // 720 x 266
      md: 'https://firebasestorage.googleapis.com/v0/b/totemic-bonus-239316.appspot.com/o/logos%2Flogo.usabg-flag.md.jpg?alt=media',
      // 1280 x 472
      lg: 'https://firebasestorage.googleapis.com/v0/b/totemic-bonus-239316.appspot.com/o/logos%2Flogo.usabg-flag.lg.jpg?alt=media',
      xl: 'https://firebasestorage.googleapis.com/v0/b/totemic-bonus-239316.appspot.com/o/logos%2Flogo.usabg-flag.xl.jpg?alt=media',
      full: 'https://firebasestorage.googleapis.com/v0/b/totemic-bonus-239316.appspot.com/o/logos%2Flogo.usabg-flag.full.jpg?alt=media',
    },
    plain: {
      // 360x133
      sm: 'https://firebasestorage.googleapis.com/v0/b/totemic-bonus-239316.appspot.com/o/logos%2Flogo.usabg-plain.sm.jpg?alt=media',
      // 720 x 266
      md: 'https://firebasestorage.googleapis.com/v0/b/totemic-bonus-239316.appspot.com/o/logos%2Flogo.usabg-plain.md.jpg?alt=media',
      // 1280 x 472
      lg: 'https://firebasestorage.googleapis.com/v0/b/totemic-bonus-239316.appspot.com/o/logos%2Flogo.usabg-plain.lg.jpg?alt=media',
    },
  },

  badges: {
    // 2023
    // experience: `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/logos%2Fbadge.usabg-35.png?alt=media`,
    // 2024
    experience: `https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Flogos%2Fusabg-badge-2024.jpg?alt=media`,
  },

  paid_subscriptions: [35, 37, 38, 90, 91, 92, 93, 129, 130, 149],
  unpaid_subscriptions: [111, 133, 223, 224, 225],

  defaults: {
    user_goals: {
      fact_finders: 25,
      appts_set: 25,
      calls: 1000,
      spoken_to: 200,
      hours_worked: 40,
      referrals_requested: 3,
      life_quoted: 3,
      presentations_offered: 10,
    },
  },

  pending_agent_days: 60,
  session_inactive_warning: 58 * (60 * 1000),
  session_inactive_logout: 10,
  // session_inactive_warning: 2 * 10 * 1000,
  // session_inactive_logout: 30,

  onboarding: {
    // (Boolean) Indicates if the agent can select more than one agent specialization (HEALTH, LIFE, SENIOR) during onboarding.
    permit_multi_specialization: false,
  },

  social_media: {
    linkedin: {
      link: 'https://www.linkedin.com/company/usa-benefits-group',
      icon: 'icn.linkedin.png',
      name: 'USABG LinkedIn',
    },
    twitter: {
      link: 'https://x.com/usa_b_g',
      icon: 'icn.x.svg',
      name: 'USABG Twitter',
    },
    facebook: {
      link: 'https://www.facebook.com/groups/USABGAGENTS/',
      icon: 'icn.facebook.png',
      name: 'USABG Facebook',
    },
    instagram: {
      link: 'https://www.instagram.com/usabenefitsgroup/',
      icon: 'icn.instagram.png',
      name: 'USABG Instagram',
    },
    vimeo: {
      link: 'https://vimeo.com/showcase/8843553',
      icon: 'icn.vimeo.png',
      name: 'USABG Vimeo Testimonials',
    },
    bm10x: {
      link: 'https://10x.bmagencybuilders.com/',
      icon: 'icn.10x-crm.png',
      name: 'BeastMode 10x CRM',
    },
    tiktok: {
      link: 'https://www.tiktok.com/@usabenefitsgroup',
      bucket: 'totemic-bonus-239316.appspot.com',
      icon: 'icn.tiktok.png',
      name: 'USABG TikTok',
    },
    youtube: {
      link: 'https://www.youtube.com/@usabenefitsgroup',
      icon: 'icn.youtube.png',
      name: 'USABG Youtube',
    }
  },
  spoken_languages: [
    'English',
    'Spanish',
    'Portuguese',
    'French',
    'Russian',
    'Arabic',
    'German',
    'Japanese',
    'Korean',
    'Chinese',
    'Italian',
  ],
  name_pronunciation_link_prefix: `https://app2.nameshouts.com/names/all-languages/pronounce-`,
  states: [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ],
  ledger_transaction_types_converted: [
    'Agent Achievement',
    'Agent Transfer',
    'Carrier Bonus',
    'Cash Deposit',
    'Cash Withdrawal',
    'Fmo Bonus',
    'Expiration',
    'Lead Credit Import',
    'Lead Vendor Transfer',
    'Purchase',
    'Rymax Transfer',
    'Swag Store Transfer',
    'Travel Request',
    'Withholding',
  ],
  coverage_categories: ['ACA', 'HEALTH', 'LIFE', 'SENIOR'],
  commission_level_requirements: {
    2023: {
      agent: 20000,
      district: {
        leader: 20000,
        team: 20000,
      },
      region: {
        leader: 20000,
        team: 20000,
      },
      division: {
        leader: 20000,
        team: 20000,
      }
    },
    2024: {
      agent: 20000,
      district: {
        leader: 30000,
        team: 50000,
      },
      region: {
        leader: 40000,
        team: 100000,
      },
      division: {
        leader: 50000,
        team: 200000,
      }
    },
    2025: {
      agent: 20000,
      district: {
        leader: 30000,
        team: 62500,
      },
      region: {
        leader: 50000,
        team: 125000,
      },
      division: {
        leader: 0,
        team: 0,
      }
    }
  }
})

export default appConstants()
