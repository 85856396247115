import React, { useEffect, useState } from 'react'
import { MDBBtn } from 'mdbreact'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import { UserEmailHistoryFactory } from '../../../../shared/factories'
import AdminApi from '../../../../api/admin-api/admin-api'

import { ResendEmailModal } from '../ResendEmailModal/ResendEmailModal'

export const ResendEmailButton = () => {
  const isAdmin = UserProfileService.isA(
    ['system-admin', 'agency-owner', 'internal-admin', 'internal-staff'],
    true
  )
  const [isLoading, setIsLoading] = useState(true)
  const [emailHisotry, setEmailHisotry] = useState([])
  const [emails, setEmails] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    let isMounted = true // Flag to track component mount state

    const fetchUserEmailHistory = async () => {
      try {
        const UserEmailHistory = 
          await UserEmailHistoryFactory.search({
            search: {
              user_id: UserProfileService.getUserId(),
            },
            pagination: false,
          })
        
        const Emails = await AdminApi.getNotificationEmails();

        if (isMounted) { // Only update state if the component is still mounted
          setEmailHisotry(UserEmailHistory)
          if (Emails && Array.isArray(Emails?.data?.data)) setEmails(Emails?.data?.data)
          setIsLoading(false)
        }
      } catch (error) {
        if (isMounted) setIsLoading(false)
      }
    }

    fetchUserEmailHistory()

    return () => {
      isMounted = false // Cleanup function to prevent state updates on unmounted components
    }
  }, [])
  const openResendEmailModal = async () => {
    setIsOpen(true)
  }

  if (!isAdmin) return <></>

  return (
    <>
      <MDBBtn
        disabled={isLoading}
        className="btn-block"
        onClick={() => openResendEmailModal()}
        sm="true"
        color="danger"
      >
        {isLoading ? 'Loading ...' : 'Resend Emails'}
      </MDBBtn>
      <ResendEmailModal 
        data={emailHisotry} 
        emails={emails}
        show={isOpen} 
        onCloseModal={setIsOpen} 
      />
    </>
  )
}
