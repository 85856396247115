import React from 'react'
import env from './../../environments/environment'
import { toJS } from 'mobx'
import EnrollingAgentBar from './components/EnrollingAgentBar/EnrollingAgentBar.component'
import EnrollmentStageManager from './components/EnrollmentStageManager/EnrollmentStageManager.component'
import EnrollmentUpline from './components/EnrollmentUpline/EnrollmentUpline.component'
import EnrollmentStore from './shared/Enrollment.store'
import Navbar from './../../components/newhome/Navbar'
import moment from 'moment'
import { toast } from 'react-toastify'

import './EnrollmentPage.scss'

class EnrollmentPage extends React.Component {
  state = {
    useDemo: false,
    upline: false,
  }

  _loadDependencies(upline) {
    EnrollmentStore.isLoading = true
    EnrollmentStore.setUpline(upline).then(
      async (res) => {
        switch (res) {
          case true:
            await Promise.all([
              EnrollmentStore.fetchCommissionLevels(),
              EnrollmentStore.fetchTerms(),
              EnrollmentStore.fetchRecruitingLeadSources(),
            ])
            this.setState({ upline: toJS(EnrollmentStore.upline) })
            break
          case 'UPLINE_CANNOT_RECRUIT':
            toast.error('The selected agent can not enroll new agents.', {
              position: toast.POSITION.TOP_RIGHT,
            })
            break
          case 'UPLINE_LOCKED_RECRUIT':
            toast.error(
              'The selected recruiter must complete their account requirements before enrolling new agents.',
              { position: toast.POSITION.TOP_RIGHT }
            )
            break
          case 'INVALID_UPLINE_USER':
            toast.error('The selected recruiter is invalid.', {
              position: toast.POSITION.TOP_RIGHT,
            })
            break
        }

        EnrollmentStore.isLoading = false
      },
      (err) => {
        console.log('Failed to load enrollment dependencies. ', err)
        EnrollmentStore.isLoading = false
      }
    )
  }

  _shouldActivateDemo() {
    if (
      ['local', 'beta'].indexOf(env.env) > -1 &&
      this.props?.location?.search
    ) {
      let search = this.props.location.search
      return (search.charAt(0) === '?' ? search.substr(1) : search) === 'demo=1'
    }

    return false
  }

  _activateDemo() {
    EnrollmentStore.intake.u_fname = 'James[TEST]'
    EnrollmentStore.intake.u_lname = 'Miller[TEST]'
    EnrollmentStore.intake.u_email =
      'sw+usabg.' +
      Math.floor(Math.random() * 9999999999) +
      '@stevenawaskey.com'
    EnrollmentStore.intake.u_password = 'secret'
    EnrollmentStore.intake.u_address1 = '5141 Mariner Blvd'
    EnrollmentStore.intake.u_city = 'Spring Hill'
    EnrollmentStore.intake.u_zip = '34609'
    EnrollmentStore.intake.u_phone = '8005551212'
    EnrollmentStore.intake.u_birthday = moment().subtract(30, 'years').toDate()
    EnrollmentStore.intake.u_state = 'FL'
    EnrollmentStore.intake.u_enroll_source = 'Indeed'
    EnrollmentStore.intake.u_enroll_source_detail = ''
    EnrollmentStore.intake.commission_id = '127'
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props

    this._loadDependencies(params?.u_login)

    EnrollmentStore.reset()
    EnrollmentStore.setIp()

    // If in dev env & param passed, populate demo data.
    if (this._shouldActivateDemo()) this._activateDemo()
  }

  render() {
    toast.configure()

    return (
      <div id="EnrollmentPage">
        <EnrollingAgentBar goTo={(goTo) => this.props.history.push(goTo)} />
        <Navbar phone={EnrollmentStore.upline?.u_marketing_phone || false} />
        <div className="text-center enroll-heading">
          <div>Welcome to USABG</div>
          <h3>New Agent Enrollment</h3>
        </div>
        <div className="enroll-upline-container">
          <EnrollmentUpline upline={this.state.upline} />
        </div>
        <div className="enroll-stages-container">
          {this.state.upline ? (
            <EnrollmentStageManager
              useDemo={this.state.useDemo}
              upline={this.state.upline}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    )
  }
}

export default EnrollmentPage
