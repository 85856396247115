import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBDataTable,
  MDBIcon,
  MDBRow,
  MDBCol,
} from 'mdbreact'
import './ResendEmailModal.scss'
import UserEmailHistoryService from '../../../../shared/services/UserEmailHistory.service'
import { toast } from 'react-toastify'

const columns = [
  {
    label: 'Name',
    field: 'email_name',
    sort: 'asc',
  },
  {
    label: 'Subject',
    field: 'email_subject',
    sort: 'asc',
  },
  {
    label: 'Email To',
    field: 'email_to',
    sort: 'asc',
  },
  {
    label: 'Emailed at',
    field: 'email_sent',
    sort: 'asc',
  },
]

const replacePlaceholders = (template, data) => {
  return template.replace(/{(.*?)}/g, (match, key) => {
    return key in data ? data[key] : match
  })
}

const isHiddenKey = (key) => key && ['agent_password'].includes(key)

const isHtmlKey = (key) =>
  key &&
  [
    'carrier_agent_intro_details',
    'carrier_agent_eo_policy_table',
    'carrier_agent_attachment_table',
    'carrier_agent_state_license_table',
  ].includes(key)

export const ResendEmailModal = ({ data, show, onCloseModal, emails }) => {
  const [emailHistories, setEmailHistories] = useState([])
  const [infoOpened, setInfoOpened] = useState(false)
  const [selectedEmail, setSelectedEmail] = useState(null)
  const [isResending, setIsResending] = useState(false)

  const resendEmail = async (id) => {
    setIsResending(true)
    try {
      await UserEmailHistoryService.resend(id)
      toast.success('The email has been resent!', {
        position: toast.POSITION.TOP_RIGHT,
      })
      setIsResending(false)
    } catch (error) {
      toast.error('Something went wrong! Failed to resend the email', {
        position: toast.POSITION.TOP_RIGHT,
      })
      setIsResending(false)
    }
  }

  useEffect(() => {
    if (data?.length > 0 && emails?.length > 0) {
      const email_histories = data.map((item) => {
        const email_template = emails.find(
          (email) => email.id === item.get('template_id')
        )

        return {
          email_name: email_template?.email_name,
          email_subject: replacePlaceholders(
            email_template?.email_subject,
            item.get('payload')?.merge_fields
          ),
          email_to: item.get('recipients')?.to,
          email_sent: (
            <span
              searchvalue={
                item.get('created_at')
                  ? moment(item.get('created_at')).format('YYYY/MM/DD')
                  : ''
              }
            >
              {item.get('created_at')
                ? moment(item.get('created_at')).format('MM/DD/YYYY')
                : 'N/A'}
            </span>
          ),
          clickEvent: () => {
            setInfoOpened(true)
            setSelectedEmail({
              id: item.get('id'),
              email_cc: item.get('recipients')?.cc || [],
              email_bcc: item.get('recipients')?.bcc || [],
              email_payload: item.get('payload')?.merge_fields,
            })
          },
        }
      })
      setEmailHistories(email_histories)
    }
  }, [data, emails])

  return (
    <MDBModal
      isOpen={show}
      toggle={() => onCloseModal(false)}
      size="lg"
      id="ResendEmailModal"
    >
      <MDBModalHeader>Email History</MDBModalHeader>
      <MDBModalBody>
        {data?.length > 0 ? (
          <div>
            {!infoOpened ? (
              <MDBRow>
                <MDBCol size="12">
                  <MDBDataTable
                    searching={false}
                    sortRows={[
                      'email_name',
                      'email_subject',
                      'email_to',
                      'email_sent',
                    ]}
                    paging={true}
                    striped
                    responsive
                    bordered
                    data={{ columns: columns, rows: emailHistories }}
                    entries={10}
                    entriesOptions={[10, 20, 50]}
                    hover
                  />
                </MDBCol>
              </MDBRow>
            ) : (
              <MDBRow>
                <MDBCol size="12" lg="8">
                  <MDBDataTable
                    searching={false}
                    sortRows={[
                      'email_name',
                      'email_subject',
                      'email_to',
                      'email_sent',
                    ]}
                    paging={true}
                    striped
                    responsive
                    bordered
                    data={{ columns: columns, rows: emailHistories }}
                    entries={10}
                    entriesOptions={[10, 20, 50]}
                    hover
                  />
                </MDBCol>
                <MDBCol size="12" lg="4">
                  <div className="emailDetail">
                    <div className="emailDetailHeader">
                      <h5>Email Details</h5>
                      <MDBIcon
                        icon="times-circle"
                        onClick={(e) => {
                          setInfoOpened(false)
                          setSelectedEmail(null)
                        }}
                        className="close-icon"
                      ></MDBIcon>
                    </div>
                    <div>
                      <strong>Email CC:</strong>
                      {selectedEmail.email_cc.length > 0 ? (
                        selectedEmail.email_cc.map((item, index) => {
                          return <p key={index}>{item}</p>
                        })
                      ) : (
                        <p>N/A</p>
                      )}
                    </div>
                    <div>
                      <strong>Email BCC:</strong>
                      {selectedEmail.email_bcc.length > 0 ? (
                        selectedEmail.email_bcc.map((item, index) => {
                          return <p key={index}>{item}</p>
                        })
                      ) : (
                        <p>N/A</p>
                      )}
                    </div>
                    <div>
                      {Object.keys(selectedEmail.email_payload).map((key) => (
                        <div key={key}>
                          <strong style={{ textTransform: 'capitalize' }}>
                            {key.replace(/_/g, ' ')}:
                          </strong>
                          <p>
                            {isHiddenKey(key) ? (
                              '*****'
                            ) : isHtmlKey(key) ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${selectedEmail.email_payload[key]}`,
                                }}
                              />
                            ) : (
                              selectedEmail.email_payload[key]
                            )}
                          </p>
                        </div>
                      ))}
                    </div>
                    <div>
                      <MDBBtn
                        color="success"
                        size="sm"
                        onClick={() => {
                          resendEmail(selectedEmail.id)
                        }}
                        disabled={isResending}
                      >
                        {isResending ? 'Resending...' : 'Resend'}
                      </MDBBtn>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
            )}
          </div>
        ) : (
          'No emails sent to this agent'
        )}
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn
          color="danger"
          size="sm"
          onClick={() => {
            onCloseModal(false)
            setInfoOpened(false)
          }}
        >
          Close
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  )
}
