import BaseModelFactory from './base.factory'
import LocationService from './../services/Location.service'
import Location from './../models/location.model'

class LocationFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: LocationService, Model: Location }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: LocationService,
      Model: Location,
    })
}

export default LocationFactory
