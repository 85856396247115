import BaseModelFactory from './base.factory'
import OpportunityService from './../services/Opportunity.service'
import Opportunity from './../models/location.model'

class OpportunityFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: OpportunityService, Model: Opportunity }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: OpportunityService,
      Model: Opportunity,
    })
}

export default OpportunityFactory
