import React, { useState, useEffect, lazy, Suspense } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { UIDropdown } from './../../components/forms/form-fields'
import ContactList from './ContactList.component'
import ContactSearch from './ContactSearch.component'
import Store from './CRM.store'

// import './CreateContactBtn.component.scss'

const CreateContactBtn = () => {
  const { Location, createNewContact } = Store

  if (!Location || !Location?.id()) return <></>

  return <button onClick={() => createNewContact()}>create new</button>
}

export default observer(CreateContactBtn)
