import env from './../../environments/environment'
import React, { Component, Fragment } from 'react'
import { b64toBlob } from './../../shared/utilities/b64toBlob.function'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import UserNotesWidget from './../../components/UserNotes/UserNotesWidget.component'
import ResetEnrollmentButton from './components/ResetEnrollmentButton/ResetEnrollmentButton.component'
import ResyncCorporateEmailButton from './components/ResyncCorporateEmailButton/ResyncCorporateEmailButton.component'
import ResetUplineSignatureButton from './components/ResetUplineSignatureButton/ResetUplineSignatureButton.component'
import RequiredTrainingChart from './components/RequiredTrainingChart/RequiredTrainingChart.component'
import AddAdminFeeWaiverButton from './components/AddAdminFeeWaiverButton/AddAdminFeeWaiverButton.component'
import HasOnboardedToggleSwitch from './components/HasOnboardedToggleSwitch/HasOnboardedToggleSwitch.component'
import IsTeamRecruiterToggleSwitch from './components/IsTeamRecruiterToggleSwitch/IsTeamRecruiterToggleSwitch.component'
import UpdatePaymentToggleSwitch from './components/UpdatePaymentToggleSwitch/UpdatePaymentToggleSwitch.component'
import UserActiveButton from './components/UserActiveButton/UserActiveButton.component'
import { ViewAwardButton } from './components/ViewAwardButton/ViewAwardButton.component'
import StorageService from './../../shared/services/Storage.service'
import {
  MDBAlert,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBFileInput,
  MDBBtn,
  MDBInput,
  MDBListGroup,
  MDBListGroupItem,
  MDBSelect,
  MDBCardHeader,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from 'mdbreact'
import placeholder_picture from '../../assets/images/user_placeholder.png'
import { toast } from 'react-toastify'
import appConstants from '../../constants/appConstants'
import AdminApi from '../../api/admin-api/admin-api'
import profilePicGuidelines from '../../assets/images/picguidelines.jpg'
import moment from 'moment'
import AgentTerms from './../../components/agents/AgentTerms/AgentTerms.component'
import ToolTip from './../../components/shared/ToolTip/ToolTip.component'
import { UIDatePickerInput } from './../../components/forms/form-fields'
import 'react-datepicker/dist/react-datepicker.css'
import AgentCorporateEmailLink from './components/AgentCorporateEmailLink/AgentCorporateEmailLink.component'
import UserProfileService from './../../shared/services/UserProfile.service'
import UploadService from './../../shared/services/Upload.service'
import {
  phoneToStorage,
  phoneToView,
} from './../../shared/formatters/telephone.formatter'
import { phone as phonePattern } from './../../shared/patterns/telephone.pattern'
import { email as emailPattern } from './../../shared/patterns/email.pattern'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import './AccountProfilePage.scss'
// import UserAlertsStore from '../../components/users/UserAlertsIndicator/UserAlerts.store'
import UserUserTypesService from '../../shared/services/UserUserTypes.service'
import UsertypeService from '../../shared/services/Usertype.service'
import { invalidMarketingTitles } from '../../constants/invalidMarketingTitles'
import { ResendEmailButton } from './components/ResendEmailButton/ResendEmailButton.component'
const localUserData = StorageService.get('user_details')

const timezones = appConstants.Timezone.map((t) => {
  return { text: t.name, value: t.id }
})
const states = appConstants.States.map((t) => {
  return { text: t.name, value: t.id }
})
const spokenLanguages = appConstants.spoken_languages.map((t) => {
  return { text: t, value: t }
})

const getContentType = (data) => {
  let match = data && data.split(',')[0].match(/\w+\/\w+/)
  return match && match.shift()
}

const ckCanSetUpline = () => {
  return new Promise(async (resolve, reject) => {
    if (parseInt(UserProfileService.getCurrentUserTypeId(true)) === 219)
      return resolve(true)

    let canSetUpline = false
    try {
      canSetUpline = !!(await UserProfileService.canUser(
        'global.users.update-upline',
        null,
        true
      ))
    } catch (ex) {
      return false
    }
    resolve(canSetUpline)
  })
}

const getIsUsertypePaid = (uId) => {
  return appConstants.paid_subscriptions.includes(uId) === true
    ? 'paid'
    : appConstants.unpaid_subscriptions.includes(uId)
    ? 'unpaid'
    : ''
}

class AccountProfile extends Component {
  _isAdminUser = UserProfileService.isA(['system-admin', 'agency-owner'], true)
  _isBSTAdmin =
    UserProfileService.isA(['internal-admin'], true) &&
    UserProfileService.get('u_email', true) === 'melvin@usabg.com'
  _isStaffUser = UserProfileService.isA(
    ['system-admin', 'agency-owner', 'internal-staff', 'internal-admin'],
    true
  )
  _isAssumedUser = UserProfileService.isAssumed()
  _canSetUpline = false

  maxDob = moment().subtract(18, 'years').toDate()

  state = {
    prefix: '',
    suffix: '',
    loading: false,
    submitting: false,
    formError: false,
    errorMessage: '',
    timezones: timezones,
    states: states,
    spokenLanguages: spokenLanguages,
    userData: [],
    clonedUserData: [],
    notesHistoryList: [],
    profile_pic: '',
    userRoles: {},
    urlParams: {},
    userTypeList: [],
    userTypes: [],
    commissionLevelList: [],
    commissionLevels: [],
    divisionsList: [],
    divisions: [],
    uplineUsersList: [],
    uplineUsers: [],
    userTermsModal: false,
    profilePicModal: false,
    isReadOnly: false,
    permittedAttribs: [],
    src: null,
    crop: {
      unit: '%',
      width: 30,
      aspect: 1 / 1.2,
    },
    minCropSize: {
      width: 150,
      height: 180,
    },
    permitChangeUsertype: null,
    enableChangeUsertype: null,
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params }, this.fetchDependencies)

    if (this._isStaffUser)
      ckCanSetUpline().then(
        (canSetUpline) => (this._canSetUpline = canSetUpline)
      )
  }

  _toggleUserTermsModal = () =>
    this.setState({ userTermsModal: !this.state.userTermsModal })

  _toggleProfilePicModal = () =>
    this.setState({ profilePicModal: !this.state.profilePicModal })

  //Change event for input fields
  onChange = (event, format) => {
    event.persist()

    let value = event.target.value
    switch (format) {
      case 'phone':
        value = value ? phoneToView(value) : value
        break
      default:
        break
    }

    this.setState((prevState) => {
      let userData = Object.assign({}, prevState.userData)
      userData[event.target.name] = value
      return { userData }
    })
  }

  onDateChange = (value) => {
    this.setState((prevState) => {
      let userData = Object.assign({}, prevState.userData)
      userData['u_birthday'] = value
      return { userData }
    })
  }

  onTimezoneChange = (value) => {
    this.setState((prevState) => {
      let userData = Object.assign({}, prevState.userData)
      userData['u_timezone'] = value
      return { userData }
    })
  }
  onStatesChange = (value) => {
    this.setState((prevState) => {
      let userData = Object.assign({}, prevState.userData)
      userData['u_state'] = value
      return { userData }
    })
  }
  onSpokenLanguageChange = (value) => {
    this.setState((prevState) => {
      let userData = Object.assign({}, prevState.userData)
      userData['u_spoken_language'] = value
      return { userData }
    })
  }

  onUserTypeChange = (value) => {
    this.setState((prevState) => {
      let userData = Object.assign({}, prevState.userData)
      userData['usertype_id'] = value
      return { userData }
    })
  }

  onCommissionLevelChange = (value) => {
    this.setState((prevState) => {
      let userData = Object.assign({}, prevState.userData)
      userData['commission_id'] = value
      return { userData }
    })
  }

  onDivisionChange = (value) => {
    this.setState((prevState) => {
      let userData = Object.assign({}, prevState.userData)
      userData['u_devision'] = value
      return { userData }
    })
  }

  onUplineChange = (value) => {
    this.setState((prevState) => {
      let userData = Object.assign({}, prevState.userData)
      userData['u_upline_id'] = value
      return { userData }
    })
  }

  /*onFileChange = value => {
        this.setState({profile_pic: value})
    }*/

  fetchDependencies = async () => {
    this.setState({ loading: true })

    Promise.all([
      this.fetchUplineUsers(),
      this.fetchDivisions(),
      this.fetchCommissionLevels(),
      this.fetchUserTypes(),
    ])
      .then((results) => {
        let stateUpd = {}
        results.forEach((result) => {
          if (typeof result === 'object') stateUpd = { ...stateUpd, ...result }
        })

        this.setState(stateUpd)
        this._fetchCurrentUserdata().finally(() =>
          this.setState({ loading: false })
        )
      })
      .catch(() => this.setState({ loading: false }))
  }

  fetchUserTypes = async () => {
    try {
      return { userTypeList: (await AdminApi.getUserTypes())?.data?.data || [] }
    } catch (ex) {
      return { userTypeList: [] }
    }
  }

  fetchCommissionLevels = async () => {
    try {
      return {
        commissionLevelList:
          (await AdminApi.getCommissions())?.data?.data || [],
      }
    } catch (ex) {
      return { commissionLevelList: [] }
    }
  }

  fetchDivisions = async () => {
    try {
      return {
        divisionsList: (await AdminApi.getDivisions())?.data?.data || [],
      }
    } catch (ex) {
      return { divisionsList: [] }
    }
  }

  fetchUplineUsers = async () => {
    try {
      return {
        uplineUsersList: (
          (await AdminApi.getAllUplineUsers())?.data?.data || []
        ).sort((a, b) =>
          a?.u_name > b?.u_name ? 1 : a?.u_name < b?.u_name ? -1 : 0
        ),
      }
    } catch (ex) {
      return { uplineUsersList: [] }
    }
  }

  _canViewHistory = () => {
    if (
      UserProfileService.isA(
        ['agency-owner', 'internal-admin', 'internal-staff', 'system-admin'],
        true
      )
    )
      return true

    // recruiter is seperated incase we want to limit per-agent access.
    if (UserProfileService.isA(['recruiter-group'], true)) return true

    return false
  }

  _isPermitted = (attrib) =>
    !this.state.isReadOnly ||
    (Array.isArray(this.state.permittedAttribs)
      ? this.state.permittedAttribs.includes(attrib)
      : false)

  _loadProfile = (
    userData,
    permittedAttribs,
    imageUrl,
    enableChangeUsertype
  ) => {
    const isPermitted = (attrib) =>
        permittedAttribs === true
          ? true
          : Array.isArray(permittedAttribs) &&
            permittedAttribs.includes(attrib),
      isReadOnly = permittedAttribs !== true

    let clonedUserData = {},
      notesHistoryList = [],
      stateUpd = {
        isReadOnly,
        permittedAttribs: Array.isArray(permittedAttribs)
          ? permittedAttribs
          : [],
      }

    // Clone the initial user data payload.
    clonedUserData = JSON.parse(JSON.stringify(userData))

    // Build the Usertype & Commission Level change history.
    // Limited by public access.
    notesHistoryList = this._buildUserHistoryList(
      isPermitted('usertype_history') ? userData.usertypes_history : '',
      isPermitted('commission_history') ? userData.commission_history : '',
      permittedAttribs === true ? true : 1
    )

    stateUpd.timezones = this.state.timezones

    if (isPermitted('u_timezone'))
      stateUpd.timezones = stateUpd.timezones.map((item) => ({
        ...item,
        checked: item.value === userData.u_timezone,
      }))
    if (isPermitted('u_state'))
      stateUpd.states = this.state.states.map((item) => ({
        ...item,
        checked: item.value === userData.u_state,
      }))

    if (isPermitted('u_birthday')) {
      if (userData.u_birthday && typeof userData.u_birthday === 'string') {
        if (!isNaN(Date.parse(userData.u_birthday.split(/T|\s/).shift()))) {
          clonedUserData.u_birthday = moment(
            userData.u_birthday.split(/T|\s/).shift()
          ).toDate()
          userData.u_birthday = moment(
            userData.u_birthday.split(/T|\s/).shift()
          ).toDate()
        } else {
          clonedUserData.u_birthday = userData.u_birthday
        }
      }
    }

    if (isPermitted('u_enrolled')) {
      if (userData.u_enrolled && typeof userData.u_enrolled === 'string') {
        userData.u_enrolled = userData.u_enrolled.split(/T|\s/).shift()
        clonedUserData.u_enrolled = userData.u_enrolled
      }
    }

    if (isPermitted('u_spoken_language')) {
      if (!userData.u_spoken_language) userData.u_spoken_language = []
      if (
        userData.u_spoken_language &&
        typeof userData.u_spoken_language === 'string'
      ) {
        userData.u_spoken_language = JSON.parse(userData.u_spoken_language)
        clonedUserData.u_spoken_language = userData.u_spoken_language
      }
      if (userData.u_spoken_language) {
        this.setState((prev) => {
          return {
            ...prev,
            spokenLanguages: spokenLanguages.map((lang) => {
              return {
                ...lang,
                checked: userData.u_spoken_language.includes(lang.value),
              }
            }),
          }
        })
      }
    }

    // Main User profile data objects.
    stateUpd.userData = userData
    stateUpd.notesHistoryList = notesHistoryList
    stateUpd.clonedUserData = clonedUserData
    stateUpd.prefix = imageUrl.prefix
    stateUpd.suffix = imageUrl.suffix
    stateUpd.enableChangeUsertype = enableChangeUsertype

    // Agent Phones
    stateUpd.userData.u_phone = isPermitted('u_phone')
      ? phoneToView(stateUpd.userData.u_phone || '')
      : ''
    stateUpd.userData.u_fax = isPermitted('u_fax')
      ? phoneToView(stateUpd.userData.u_fax || '')
      : ''
    stateUpd.userData.u_mobile = isPermitted('u_mobile')
      ? phoneToView(stateUpd.userData.u_mobile || '')
      : ''
    stateUpd.userData.u_tollfree = isPermitted('u_tollfree')
      ? phoneToView(stateUpd.userData.u_tollfree || '')
      : ''

    // Selectable dropdowns are locked when readonly
    if (!isReadOnly) {
      stateUpd.userTypes = this.state.userTypeList
        .map((item) => {
          // 1. Identify the current usertype label/name.
          if (parseInt(item.id) === parseInt(stateUpd.userData['usertype_id']))
            stateUpd.userTypeCurrentValue = item.displayname

          // 2. Create dropdown item from usertype record.
          item = {
            text: item.displayname,
            value: `${item.id}`,
            checked:
              parseInt(item.id) === parseInt(stateUpd.userData['usertype_id']),
          }

          // 3. Permit the agent's current usertype into the dropdown list.
          if (
            parseInt(stateUpd.userData['usertype_id']) === parseInt(item.value)
          )
            return item

          // 4. Confirm the selectable usertypes are of a matching
          // paid/unpaid subscription status to the current agent's usertype.
          if (
            getIsUsertypePaid(parseInt(item.value)) !==
            getIsUsertypePaid(parseInt(stateUpd.userData['usertype_id']))
          )
            return false

          // 5. Permit all options if logged in user is an admin?
          if (this._isAdminUser || this._isBSTAdmin) {
            if (UserProfileService.isA(['system-admin'], true)) return item
            if (UserProfileService.isA(['agency-owner'], true))
              return !['system-admin'].includes(
                UsertypeService.idToName(item.value)
              )
                ? item
                : false
            return !['system-admin', 'agency-owner'].includes(
              UsertypeService.idToName(item.value)
            )
              ? item
              : false
          }

          // 6. If the current user does not have access to change
          // usertypes, stop the filter here.  Do not return any
          // other usertypes.
          if (!enableChangeUsertype) return false

          // 7. If we're viewing an admin's account, deny all usertype options.
          if (
            [35, 219].indexOf(parseInt(stateUpd.userData['usertype_id'])) > -1
          )
            return false

          // 8. If logged in user is staff, block them from elevated usertypes.
          if (this._isStaffUser) {
            if ([35, 219].indexOf(parseInt(item.value)) > -1) return false
            return item
          }

          // 9. If the logged in user is an assumed account, limit
          // usertype options.  The only option to add here
          // is limited to training / limited to contracting.
          if (this._isAssumedUser) {
            if (
              [91, 149].indexOf(parseInt(stateUpd.userData['usertype_id'])) > -1
            )
              if ([91, 149].indexOf(parseInt(item.value)) > -1) return item
          }

          return false
        })
        .filter((u) => u)

      stateUpd.commissionLevels = this.state.commissionLevelList.map((item) => {
        if (parseInt(item.id) === parseInt(stateUpd.userData['commission_id']))
          this.setState({ commissionLevelCurrentValue: item.com_name })
        return {
          text: item.com_name,
          value: `${item.id}`,
          checked:
            parseInt(item.id) === parseInt(stateUpd.userData['commission_id']),
        }
      })

      stateUpd.divisions = this.state.divisionsList.map((item) => {
        if (parseInt(item.id) === parseInt(stateUpd.userData['u_devision']))
          this.setState({ divisionCurrentValue: item.d_name })
        return {
          text: item.d_name,
          value: `${item.id}`,
          checked:
            parseInt(item.id) === parseInt(stateUpd.userData['u_devision']),
        }
      })

      stateUpd.uplineUsers = this.state.uplineUsersList.map((item) => {
        if (parseInt(item.id) === parseInt(stateUpd.userData['u_upline_id']))
          this.setState({ uplineCurrentValue: item.u_name })
        return {
          text: item.u_name,
          value: `${item.id}`,
          checked:
            parseInt(item.id) === parseInt(stateUpd.userData['u_upline_id']),
        }
      })
    } else {
      stateUpd.userTypes = []
      if (isPermitted('usertype_id')) {
        stateUpd.userTypes = this.state.userTypeList
          .filter(
            (item) =>
              parseInt(item.id) === parseInt(stateUpd.userData['usertype_id'])
          )
          .map((item) => {
            // 1. Identify the current usertype label/name.
            stateUpd.userTypeCurrentValue = item.displayname

            // 2. Create dropdown item from usertype record.
            return {
              text: item.displayname,
              value: `${item.id}`,
              checked: true,
            }
          })
      }

      stateUpd.commissionLevels = []
      if (isPermitted('commission_id')) {
        stateUpd.commissionLevels = this.state.commissionLevelList
          .filter(
            (item) =>
              parseInt(item.id) === parseInt(stateUpd.userData['commission_id'])
          )
          .map((item) => {
            // 1. Identify the current usertype label/name.
            stateUpd.commissionLevelCurrentValue = item.com_name

            // 2. Create dropdown item from commission record.
            return {
              text: item.com_name,
              value: `${item.id}`,
              checked: true,
            }
          })
      }

      stateUpd.divisions = []
      if (isPermitted('u_devision')) {
        stateUpd.divisions = this.state.divisionsList
          .filter(
            (item) =>
              parseInt(item.id) === parseInt(stateUpd.userData['u_devision'])
          )
          .map((item) => {
            // 1. Identify the current division label/name.
            stateUpd.divisionCurrentValue = item.d_name

            // 2. Create dropdown item from division record.
            return {
              text: item.d_name,
              value: `${item.id}`,
              checked: true,
            }
          })
      }

      stateUpd.uplineUsers = []
      if (isPermitted('u_upline_id')) {
        stateUpd.uplineUsers = this.state.uplineUsersList
          .filter(
            (item) =>
              parseInt(item.id) === parseInt(stateUpd.userData['u_upline_id'])
          )
          .map((item) => {
            // 1. Identify the current division label/name.
            stateUpd.uplineCurrentValue = item.u_name

            // 2. Create dropdown item from division record.
            return {
              text: item.u_name,
              value: `${item.id}`,
              checked: true,
            }
          })
      }
    }

    this.setState(stateUpd)
    this.fetchUserDetails()
  }

  _fetchUserTypeHistory = async (user_id) => {
    return new Promise((resolve, reject) => {
      UserUserTypesService.history(user_id)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  _fetchCurrentUserdata = async () => {
    return new Promise((resolve, reject) => {
      AdminApi.getProfileData(
        this.state.urlParams && this.state.urlParams.id
          ? this.state.urlParams.id
          : ''
      )
        .then(async (result) => {
          if (result && result?.data?.access) {
            const usertypes_history = await this._fetchUserTypeHistory(
              result.data.data.id
            )

            result.data.data.usertypes_history = usertypes_history

            const enableChangeUsertype = await this._determineCanChangeUsertype(
              result?.data?.data
            )

            if (result.data.access?.is_read_only === true) {
              this._loadProfile(
                result.data.data,
                result.data.access.permitted_attribs,
                result.data.imageUrl,
                enableChangeUsertype
              )
              return resolve()
            } else if (!result.data.access?.is_read_only) {
              this._loadProfile(
                result.data.data,
                true,
                result.data.imageUrl,
                enableChangeUsertype
              )
              return resolve()
            }
          }

          return reject()
        })
        .catch((error) => {
          toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
          resolve()
        })
    })
  }

  fetchUserDetails = async () => {
    AdminApi.fetchUserDetailsAuth()
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState({ userRoles: result.data.data })
        }
      })
      .catch((error) => {
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  _determineCanChangeUsertype = async (userDetails) => {
    // Revert to cached setting.
    if (
      this.state.enableChangeUsertype === true ||
      this.state.enableChangeUsertype === false
    )
      return this.state.enableChangeUsertype

    // Limit option by level of access.
    // A. Permit all viable options if logged in user is an sys-admin.
    if (UserProfileService.isA(['system-admin'], true)) return true

    const accountUsertypeId =
      userDetails && userDetails?.usertype_id
        ? parseInt(userDetails?.usertype_id)
        : false

    // B. Permit all viable options if logged in user is an agency-owner.
    if (this._isAdminUser) {
      if (accountUsertypeId)
        return !['system-admin'].includes(
          UsertypeService.idToName(accountUsertypeId)
        )
      return true
    }

    // C. Permit all viable options if logged in user is an BST admin.
    if (this._isBSTAdmin)
      return !['system-admin', 'agency-owner'].includes(
        UsertypeService.idToName(accountUsertypeId)
      )

    // D. Check if the user-specific permission has been defined.
    if (
      this.state.permitChangeUsertype === true ||
      this.state.permitChangeUsertype === false
    )
      return this.state.permitChangeUsertype

    return await this._checkCanChangeUsertypePermission().then(
      (permitChangeUsertype) => {
        this.setState({ permitChangeUsertype })
        return permitChangeUsertype
      }
    )
  }

  _checkCanChangeUsertypePermission = async () => {
    if (this.state.permitChangeUsertype === null) {
      let permitChangeUsertype = false
      try {
        permitChangeUsertype = await UserProfileService.canUser(
          'global.users.update-usertype'
        )
      } catch (ex) {}
      return Promise.resolve(!!permitChangeUsertype)
    }

    return Promise.resolve(!!this.state.permitChangeUsertype)
  }

  _buildUserHistoryList = (usertype_history, commission_history, limit) => {
    var commissionList =
        commission_history &&
        commission_history.split('<br />').filter((el) => {
          return el.length !== 0
        }),
      userNoteHistory = []
        .concat(
          usertype_history && usertype_history.length
            ? usertype_history.map(
                (i) =>
                  `U| Set to ${i.displayname} by ${
                    i.changed_by
                      ? i.changed_by
                      : (i.u_fname + ' ' + i.u_lname).trim()
                  } ${
                    i.change_trigger ? 'due to ' + i.change_trigger : ''
                  } on ${i.start_at}`
              )
            : []
        )
        .concat(
          commissionList && commissionList.length
            ? commissionList.map((i) => `C|${i}`)
            : []
        )

    if (!isNaN(limit)) {
      userNoteHistory.slice(0, limit)
    }

    // Apply renderable array to componenet state.
    return userNoteHistory.map((u) =>
      u
        .replace(/^C\|/, '<strong>Comm:&nbsp;</strong>')
        .replace(/^U\|/, '<strong>Usertype:&nbsp;</strong>')
    )
  }

  validate = async () => {
    const {
      u_address1,
      u_birthday,
      u_city,
      u_company_email,
      u_email,
      u_fax,
      u_fname,
      u_lname,
      u_mobile,
      u_phone,
      u_quote_email,
      u_state,
      u_timezone,
      u_tollfree,
      u_zip,
      u_marketing_title,
    } = this.state.userData

    if (!u_fname || !u_fname.trim()) {
      this.setState({ formError: true, errorMessage: 'First name required' })
      return
    }
    if (!u_lname || !u_lname.trim()) {
      this.setState({ formError: true, errorMessage: 'Last name required' })
      return
    }

    if (
      u_marketing_title &&
      invalidMarketingTitles.includes(u_marketing_title.trim().toLowerCase())
    ) {
      this.setState({
        formError: true,
        errorMessage: 'Invalid Marketing title',
      })
      return
    }

    if (!u_birthday) {
      this.setState({ formError: true, errorMessage: 'Birthdate Required' })
      return
    }

    if (!u_timezone) {
      this.setState({ formError: true, errorMessage: 'Timezone Required' })
      return
    }

    if (!u_email || !u_email.trim()) {
      this.setState({
        formError: true,
        errorMessage: 'Personal E-mail address required',
      })
      return
    }

    if (!emailPattern.test(u_email)) {
      this.setState({
        formError: true,
        errorMessage: 'Invalid E-mail format for personal E-mail',
      })
      return
    }

    if (u_company_email && !emailPattern.test(u_company_email)) {
      this.setState({
        formError: true,
        errorMessage: 'Invalid E-mail format for company E-mail',
      })
      return
    }

    if (u_quote_email && !emailPattern.test(u_quote_email)) {
      this.setState({
        formError: true,
        errorMessage: 'Invalid E-mail format for quote E-mail',
      })
      return
    }

    if (!u_phone || !u_phone.trim()) {
      this.setState({ formError: true, errorMessage: 'Phone number required' })
      return
    }

    if (!phonePattern.test(phoneToStorage(u_phone || ''))) {
      this.setState({ formError: true, errorMessage: 'Invalid Phone Number' })
      return
    }

    if (u_mobile && !phonePattern.test(phoneToStorage(u_mobile || ''))) {
      this.setState({ formError: true, errorMessage: 'Invalid Mobile Number' })
      return
    }

    if (u_tollfree && !phonePattern.test(phoneToStorage(u_tollfree || ''))) {
      this.setState({
        formError: true,
        errorMessage: 'Invalid Tollfree Number',
      })
      return
    }

    if (u_fax && !phonePattern.test(phoneToStorage(u_fax || ''))) {
      this.setState({ formError: true, errorMessage: 'Invalid Fax Number' })
      return
    }

    if (!u_address1 || !u_address1.trim()) {
      this.setState({ formError: true, errorMessage: 'Address required' })
      return
    }

    if (!u_city || !u_city.trim()) {
      this.setState({ formError: true, errorMessage: 'City required' })
      return
    }

    if (!u_state) {
      this.setState({ formError: true, errorMessage: 'State required' })
      return
    }

    if (!u_zip || !u_zip.trim()) {
      this.setState({ formError: true, errorMessage: 'Zip required' })
      return
    }

    /*if (!u_picture && !this.state.profile_pic) {
            this.setState({formError: true, errorMessage: 'Profile pic required'});
            return;
        }*/

    this.setState({ formError: false, errorMessage: '' })
  }

  handleUpdate = async () => {
    await this.validate()
    const { formError, errorMessage, loading } = this.state
    const {
      u_nickname,
      npn,
      u_address1,
      u_address2,
      u_birthday,
      u_city,
      u_devision,
      u_company_email,
      u_email,
      u_fax,
      u_fname,
      u_lname,
      u_name_pronunciation,
      u_marketing_title,
      u_mobile,
      u_notes_admin,
      u_private_notes,
      u_phone,
      u_quote_email,
      u_state,
      u_timezone,
      u_tollfree,
      u_upline_id,
      u_spoken_language,
      u_zip,
      usertype_id,
      commission_id,
    } = this.state.userData

    if (loading) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }

    const data = {}

    if (u_fname && u_fname.trim() !== this.state.clonedUserData.u_fname) {
      data['u_fname'] = u_fname.trim()
    }
    if (u_lname && u_lname.trim() !== this.state.clonedUserData.u_lname) {
      data['u_lname'] = u_lname.trim()
    }
    if (
      u_name_pronunciation &&
      u_name_pronunciation.trim() !==
        this.state.clonedUserData.u_name_pronunciation
    ) {
      data['u_name_pronunciation'] = u_name_pronunciation.trim()
    }
    if (
      u_marketing_title &&
      u_marketing_title.trim() !== this.state.clonedUserData.u_marketing_title
    ) {
      data['u_marketing_title'] = u_marketing_title.trim()
    }
    if (npn && npn.trim() !== this.state.clonedUserData.npn) {
      data['npn'] = npn.trim()
    }
    if (usertype_id && usertype_id !== this.state.clonedUserData.usertype_id) {
      data['usertype_id'] = usertype_id
    }

    if (
      commission_id &&
      commission_id !== this.state.clonedUserData.commission_id
    ) {
      data['commission_id'] = commission_id
    }

    if (u_devision && u_devision !== this.state.clonedUserData.u_devision) {
      data['u_devision'] = u_devision
    }

    if (u_upline_id && u_upline_id !== this.state.clonedUserData.u_upline_id) {
      data['u_upline_id'] = u_upline_id
    }

    if (u_birthday !== this.state.clonedUserData.u_birthday) {
      data['u_birthday'] = moment(u_birthday).format('YYYY-MM-DD')
    }
    if (u_timezone !== this.state.clonedUserData.u_timezone) {
      data['u_timezone'] = u_timezone
    }
    if (
      `${u_nickname}`.trim() !==
      `${this.state.clonedUserData.u_nickname}`.trim()
    ) {
      data['u_nickname'] = u_nickname.trim()
    }
    if (u_email && u_email.trim() !== this.state.clonedUserData.u_email) {
      data['u_email'] = u_email.trim()
    }
    if (
      (u_company_email &&
        u_company_email.trim() !== this.state.clonedUserData.u_company_email) ||
      (!u_company_email && this.state.clonedUserData.u_company_email)
    ) {
      data['u_company_email'] = u_company_email.trim()
    }
    if (
      (u_quote_email &&
        u_quote_email.trim() !== this.state.clonedUserData.u_quote_email) ||
      (!u_quote_email && this.state.clonedUserData.u_quote_email)
    ) {
      data['u_quote_email'] = u_quote_email.trim()
    }

    if (
      (u_phone && u_phone.trim() !== this.state.clonedUserData.u_phone) ||
      (!u_phone && this.state.clonedUserData.u_phone)
    ) {
      if (
        phoneToStorage(u_phone || '') !==
        phoneToStorage(this.state.clonedUserData.u_phone || '')
      )
        data['u_phone'] = phoneToStorage(u_phone)
    }

    if (
      (u_mobile && u_mobile.trim() !== this.state.clonedUserData.u_mobile) ||
      (!u_mobile && this.state.clonedUserData.u_mobile)
    ) {
      if (
        phoneToStorage(u_mobile || '') !==
        phoneToStorage(this.state.clonedUserData.u_mobile || '')
      )
        data['u_mobile'] = phoneToStorage(u_mobile)
    }

    if (
      (u_tollfree &&
        u_tollfree.trim() !== this.state.clonedUserData.u_tollfree) ||
      (!u_tollfree && this.state.clonedUserData.u_tollfree)
    ) {
      if (
        phoneToStorage(u_tollfree || '') !==
        phoneToStorage(this.state.clonedUserData.u_tollfree || '')
      )
        data['u_tollfree'] = phoneToStorage(u_tollfree)
    }

    if (
      (u_fax && u_fax.trim() !== this.state.clonedUserData.u_fax) ||
      (!u_fax && this.state.clonedUserData.u_fax)
    ) {
      if (
        phoneToStorage(u_fax || '') !==
        phoneToStorage(this.state.clonedUserData.u_fax || '')
      )
        data['u_fax'] = phoneToStorage(u_fax)
    }

    if (
      u_address1 &&
      u_address1.trim() !== this.state.clonedUserData.u_address1
    ) {
      data['u_address1'] = u_address1.trim()
    }
    if (
      u_address2 &&
      u_address2.trim() !== this.state.clonedUserData.u_address2
    ) {
      data['u_address2'] = u_address2.trim()
    }
    if (u_city && u_city.trim() !== this.state.clonedUserData.u_city) {
      data['u_city'] = u_city.trim()
    }
    if (u_state !== this.state.clonedUserData.u_state) {
      data['u_state'] = u_state
    }
    if (u_zip && u_zip.trim() !== this.state.clonedUserData.u_zip) {
      data['u_zip'] = u_zip.trim()
    }
    if (
      u_notes_admin &&
      u_notes_admin.trim() !== this.state.clonedUserData.u_notes_admin
    ) {
      data['u_notes_admin'] = u_notes_admin.trim()
    }

    if (
      u_private_notes &&
      u_private_notes.trim() !== this.state.clonedUserData.u_private_notes
    ) {
      data['u_private_notes'] = u_private_notes.trim()
    }
    if (u_spoken_language.length) {
      data['u_spoken_language'] = JSON.stringify(u_spoken_language)
    }

    // if (this.state.profile_pic) {
    //   data['u_picture'] = this.state.profile_pic
    // }

    if (data.constructor !== Object || !Object.keys(data).length)
      return toast.error('Please change some values!', {
        position: toast.POSITION.TOP_RIGHT,
      })

    data.u_changed = UserProfileService.getUserId(this._isAssumedUser)
    data.u_changed_name = [
      UserProfileService.get('u_fname', this._isAssumedUser),
      UserProfileService.get('u_lname', this._isAssumedUser),
    ]
      .join(' ')
      .trim()
    data.id = this.state.userRoles.id

    const form_data = new FormData()
    for (let key in data) form_data.append(key, data[key])

    this.updateProfile(form_data, false).then((updates) => {
      // if (data.u_picture) {
      //   UserAlertsStore.setNumberOfAlerts(UserAlertsStore.numberOfAlerts - 1)
      // }
      Object.keys(data)
        .filter(
          (k) =>
            ['id', 'u_changed', 'u_changed_name'].indexOf(k) < 0 &&
            typeof updates[k] !== 'undefined'
        )
        .forEach((k) => UserProfileService.set(k, updates[k]))
    })
  }

  handleUpdateHeadshot = async () => {
    if (this.state.profile_pic) {
      try {
        await UploadService.upload(this.headshotData)
        toast.success(`User headshot updated.`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } catch (ex) {
        toast.error(`${ex}`, { position: toast.POSITION.TOP_RIGHT })
      }
    }
  }

  updateProfile = async (data, pageReload) => {
    this.setState({ submitting: true })

    return new Promise((resolve, reject) => {
      AdminApi.updateProfile(data)
        .then((result) => {
          this.setState({
            loading: true,
            submitting: false,
            u_fname: '',
            u_lname: '',
            u_name_pronunciation: '',
            u_timezone: '',
            u_marketing_title: '',
            usertype_id: 0,
            u_active: '',
            u_email: '',
            u_address1: '',
            u_address2: '',
            u_city: '',
            u_state: '',
            u_zip: '',
            commission_id: 0,
            u_upline_id: 0,
            u_birthday: new Date(),
            u_enrolled: new Date(),
            u_devision: 0,
            u_picture: '',
            src: null,
            crop: {
              unit: '%',
              width: 30,
              aspect: 1 / 1.2,
            },
          })

          toast.success('User updated successfully', {
            position: toast.POSITION.TOP_RIGHT,
          })

          this._fetchCurrentUserdata().finally(() => {
            resolve(result?.data?.data || {})
            this.setState({ loading: false })
          })
        })
        .catch((error) => {
          this.setState({ submitting: false })
          toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
          reject(error)
        })
    })
  }

  /*===========================  image cropper start  =====================================*/
  headshotData = null
  onSelectFile = (file) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const payload = { filename: file?.name, data: reader.result }
      this.headshotData = new FormData()
      this.headshotData.append('upload_type', 'headshot')
      this.headshotData.append(
        'base_64',
        b64toBlob(payload.data.split(',')[1], getContentType(payload.data))
      )
      this.headshotData.append(`filename`, file.name)
      this.headshotData.append(`mime`, file?.type)

      this.setState({ src: reader.result })
    })
    reader.readAsDataURL(file)
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image
  }

  onCropComplete = (crop) => {
    this.makeClientCrop(crop)
  }

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    const { minCropSize } = this.state
    crop.width = crop.width > minCropSize.width ? crop.width : minCropSize.width
    crop.height =
      crop.height > minCropSize.height ? crop.height : minCropSize.height
    this.setState({ crop })
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      )
      this.setState({ profile_pic: croppedImageUrl })
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    /*canvas.width = crop.width;
        canvas.height = crop.height;*/

    canvas.width = Math.ceil(crop.width * scaleX)
    canvas.height = Math.ceil(crop.height * scaleY)
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      /*crop.width,
            crop.height*/
      crop.width * scaleX,
      crop.height * scaleY
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          return
        }
        blob.name = fileName
        window.URL.revokeObjectURL(this.fileUrl)
        this.fileUrl = window.URL.createObjectURL(blob)
        // resolve(this.fileUrl);
        resolve(blob)
      }, 'image/jpeg')
    })
  }

  render() {
    toast.configure()
    const { crop, src } = this.state
    const { loading, notesHistoryList, submitting, userRoles, isReadOnly } =
      this.state
    const showPrivateNotes =
      (userRoles && userRoles.admin_area) || this._isAssumedUser
    const {
      u_nickname,
      id,
      npn,
      u_address1,
      u_address2,
      u_city,
      u_company_email,
      u_email,
      u_fax,
      u_fname,
      u_lname,
      u_name_pronunciation,
      u_marketing_title,
      u_mobile,
      u_notes_admin,
      u_private_notes,
      u_phone,
      u_picture,
      u_quote_email,
      u_state,
      u_timezone,
      u_tollfree,
      u_zip,
    } = this.state.userData

    const u_birthday = this.state.userData.u_birthday || null
    // ? moment(this.state.userData.u_birthday).format('MM/DD/YYYY')
    // : ''

    return (
      <Fragment>
        <DashboardLayout>
          <main id="AccountProfilePage" className="mainSection">
            <MDBModal
              isOpen={this.state.profilePicModal}
              toggle={this._toggleProfilePicModal}
              size="lg"
            >
              <MDBModalHeader toggle={this._toggleProfilePicModal}>
                Profile Pic Guidelines
              </MDBModalHeader>
              <MDBModalBody>
                <img
                  src={profilePicGuidelines}
                  className="img-fluid"
                  alt="profile pic"
                />
              </MDBModalBody>
            </MDBModal>
            <MDBModal
              isOpen={this.state.userTermsModal}
              toggle={this._toggleUserTermsModal}
              size="xl"
            >
              <MDBModalHeader
                toggle={this._toggleUserTermsModal}
              ></MDBModalHeader>
              <MDBModalBody>
                <AgentTerms userId={id} hideHeader={true} />
              </MDBModalBody>
            </MDBModal>
            <h2>Account Profile</h2>
            <hr />
            {loading ? (
              <h2>Loading...</h2>
            ) : (
              <MDBRow>
                <MDBCol lg="8" className="mb-3">
                  <MDBCard>
                    <MDBCardHeader color="indigo">My Profile</MDBCardHeader>
                    <MDBCardBody className="mx-4 mt-4">
                      <form
                        className={`agent-profile-form ${
                          isReadOnly ? 'read-only' : ''
                        }`}
                      >
                        <div className="form-row">
                          {!isReadOnly || this._isPermitted('u_fname') ? (
                            <div className="form-group col-md-6">
                              <MDBInput
                                label="Legal First Name"
                                group
                                type="text"
                                name="u_fname"
                                value={u_fname || ''}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly || this._isPermitted('u_lname') ? (
                            <div className="form-group col-md-6">
                              <MDBInput
                                label="Legal Last Name"
                                group
                                type="text"
                                name="u_lname"
                                value={u_lname || ''}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly ||
                          this._isPermitted('u_name_pronunciation') ? (
                            <div className="form-group col-md-6 has-helper-link">
                              <MDBInput
                                label="Name pronunciation"
                                group
                                type="text"
                                name="u_name_pronunciation"
                                onChange={this.onChange}
                                value={u_name_pronunciation}
                                validate
                                readOnly={isReadOnly}
                                maxLength="128"
                              />
                              <small className="text-left helper-link">
                                Not sure how to pronounce?{' '}
                                <a
                                  href={`${
                                    appConstants.name_pronunciation_link_prefix
                                  }${
                                    u_name_pronunciation ||
                                    `${u_fname?.toLowerCase()}-${u_lname?.toLowerCase()}`
                                  }`}
                                  target="_BLANK"
                                  rel="noopener noreferrer"
                                >
                                  Click here to hear it!
                                </a>
                              </small>
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly ||
                          this._isPermitted('u_marketing_title') ? (
                            <div className="form-group col-md-6">
                              <MDBInput
                                label="Marketing Title"
                                group
                                type="text"
                                name="u_marketing_title"
                                value={u_marketing_title || ''}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly || this._isPermitted('npn') ? (
                            <div className="form-group col-md-6 has-helper-link">
                              <MDBInput
                                label="NPN Number"
                                group
                                type="text"
                                name="npn"
                                value={npn || ''}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                              <small className="text-left helper-link">
                                Misplaced NPN number?{' '}
                                <a
                                  href="https://nipr.com/help/look-up-your-npn"
                                  target="_BLANK"
                                  rel="noopener noreferrer"
                                >
                                  Look it up here!
                                </a>
                              </small>
                            </div>
                          ) : (
                            <></>
                          )}
                          {this._isAssumedUser || isReadOnly ? (
                            <>
                              {!isReadOnly ||
                              this._isPermitted('usertype_id') ? (
                                <div className="form-group col-md-6">
                                  {this._isStaffUser &&
                                  this.state.enableChangeUsertype ? (
                                    <MDBSelect
                                      disabled={
                                        !this.state.enableChangeUsertype ||
                                        !(this._isAdminUser || this._isBSTAdmin)
                                      }
                                      readOnly={isReadOnly}
                                      options={this.state.userTypes}
                                      value={
                                        this.state.userData['usertype_id'] ||
                                        (isReadOnly
                                          ? 'Unknown Usertype'
                                          : 'Choose Usertype')
                                      }
                                      selected={
                                        this.state.userTypeCurrentValue ||
                                        (isReadOnly
                                          ? 'Unknown Usertype'
                                          : 'Choose Usertype')
                                      }
                                      getValue={(value) => {
                                        this.onUserTypeChange(value[0])
                                      }}
                                      getTextContent={(value) =>
                                        this.setState({
                                          userTypeCurrentValue: value,
                                        })
                                      }
                                      label="Usertype"
                                    />
                                  ) : (
                                    <MDBInput
                                      readonly={
                                        !this.state.enableChangeUsertype
                                      }
                                      disabled={
                                        !(this._isAdminUser || this._isBSTAdmin)
                                      }
                                      label="Usertype"
                                      type="text"
                                      name="usertype_id"
                                      value={
                                        this.state.userTypeCurrentValue || ''
                                      }
                                    />
                                  )}
                                </div>
                              ) : (
                                <></>
                              )}
                              {!isReadOnly ||
                              this._isPermitted('commission_id') ? (
                                <div className="form-group col-md-6">
                                  {this._isStaffUser ? (
                                    <MDBSelect
                                      readOnly={isReadOnly}
                                      options={this.state.commissionLevels}
                                      value={
                                        this.state.userData['commission_id'] ||
                                        (isReadOnly
                                          ? 'Unknown Commission Level'
                                          : 'Choose Commission Level')
                                      }
                                      selected={
                                        this.state
                                          .commissionLevelCurrentValue ||
                                        (isReadOnly
                                          ? 'Unknown Commission Level'
                                          : 'Choose Commission Level')
                                      }
                                      getValue={(value) => {
                                        this.onCommissionLevelChange(value[0])
                                      }}
                                      getTextContent={(value) =>
                                        this.setState({
                                          commissionLevelCurrentValue: value,
                                        })
                                      }
                                      label="Commission Level"
                                    />
                                  ) : (
                                    <MDBInput
                                      disabled={true}
                                      label="Commission Level"
                                      type="text"
                                      name="commission_id"
                                      value={
                                        this.state
                                          .commissionLevelCurrentValue || ''
                                      }
                                    />
                                  )}
                                </div>
                              ) : (
                                <></>
                              )}
                              {!isReadOnly ||
                              this._isPermitted('u_devision') ? (
                                <div className="form-group col-md-6">
                                  {this._isStaffUser ? (
                                    <MDBSelect
                                      readOnly={isReadOnly}
                                      options={this.state.divisions}
                                      value={
                                        this.state.userData['u_devision'] ||
                                        (isReadOnly
                                          ? 'Unknown Division'
                                          : 'Choose Division')
                                      }
                                      selected={
                                        this.state.divisionCurrentValue ||
                                        (isReadOnly
                                          ? 'Unknown Division'
                                          : 'Choose Division')
                                      }
                                      getValue={(value) => {
                                        this.onDivisionChange(value[0])
                                      }}
                                      getTextContent={(value) =>
                                        this.setState({
                                          divisionCurrentValue: value,
                                        })
                                      }
                                      label="Division"
                                    />
                                  ) : (
                                    <MDBInput
                                      disabled={true}
                                      label="Division"
                                      type="text"
                                      name="division_id"
                                      value={
                                        this.state.divisionCurrentValue || ''
                                      }
                                    />
                                  )}
                                </div>
                              ) : (
                                <></>
                              )}
                              {this._canSetUpline ? (
                                <div className="form-group col-md-6">
                                  <MDBSelect
                                    search
                                    options={this.state.uplineUsers}
                                    value={
                                      this.state.userData['u_upline_id'] ||
                                      'Choose Upline Leader'
                                    }
                                    selected={
                                      this.state.uplineCurrentValue ||
                                      'Choose Upline Leader'
                                    }
                                    getValue={(value) => {
                                      this.onUplineChange(value[0])
                                    }}
                                    getTextContent={(value) =>
                                      this.setState({
                                        uplineCurrentValue: value,
                                      })
                                    }
                                    label="Upline Leader"
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly || this._isPermitted('u_birthday') ? (
                            <div className="form-group col-md-6">
                              <div className="md-form">
                                <UIDatePickerInput
                                  label="Date of Birth"
                                  name="u_birthday"
                                  id="dp_u_birthday"
                                  showYearDropdown
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  dateFormat="MM/dd/yyyy"
                                  maxDate={this.maxDob}
                                  selected={u_birthday}
                                  onChange={(date) =>
                                    this.onDateChange(date?.target?.value)
                                  }
                                  readOnly={isReadOnly}
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly || this._isPermitted('u_nickname') ? (
                            <div className="form-group col-md-6">
                              <MDBInput
                                label="Nickname"
                                group
                                type="text"
                                name="u_nickname"
                                value={u_nickname || ''}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly || this._isPermitted('u_email') ? (
                            <div className="form-group col-md-6">
                              <MDBInput
                                label={
                                  isReadOnly ? (
                                    'Primary E-mail (Login)'
                                  ) : (
                                    <ToolTip
                                      label={'Primary E-mail (Login)'}
                                      content={
                                        'This is your primary email used to login and to identify your account.'
                                      }
                                    />
                                  )
                                }
                                group
                                type="text"
                                name="u_email"
                                value={u_email || ''}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly ||
                          this._isPermitted('u_company_email') ? (
                            <div className="form-group col-md-6">
                              <MDBInput
                                label={
                                  isReadOnly ? (
                                    'Company E-mail'
                                  ) : (
                                    <ToolTip
                                      label={'Company E-mail'}
                                      content={
                                        'This is commonly your USABG corporate email and is the default email for your subsite.  If not set, your subsite will use your Primary Email.'
                                      }
                                    />
                                  )
                                }
                                group
                                type="text"
                                name="u_company_email"
                                value={u_company_email || ''}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly || this._isPermitted('u_quote_email') ? (
                            <div className="form-group col-md-6">
                              <MDBInput
                                label={
                                  isReadOnly ? (
                                    'Quote E-mail'
                                  ) : (
                                    <ToolTip
                                      label={'Quote E-mail'}
                                      content={
                                        "This is the default email for your subsite Quote & Contact submission forms.  If not set, we'll use your Primary Email."
                                      }
                                    />
                                  )
                                }
                                group
                                type="text"
                                name="u_quote_email"
                                value={u_quote_email || ''}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly || this._isPermitted('u_timezone') ? (
                            <div className="form-group col-md-6">
                              <MDBSelect
                                options={this.state.timezones}
                                value={u_timezone || 'Choose your timezone'}
                                selected={u_timezone || 'Choose your timezone'}
                                getValue={(value) => {
                                  this.onTimezoneChange(value[0])
                                }}
                                label="Timezone"
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly || this._isPermitted('u_phone') ? (
                            <div className="form-group col-md-6">
                              <MDBInput
                                label="Phone Number"
                                group
                                type="text"
                                name="u_phone"
                                value={u_phone || ''}
                                onBlur={(evt) => this.onChange(evt, 'phone')}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly || this._isPermitted('u_mobile') ? (
                            <div className="form-group col-md-6">
                              <MDBInput
                                label="Mobile Number"
                                group
                                type="text"
                                name="u_mobile"
                                value={u_mobile || ''}
                                onBlur={(evt) => this.onChange(evt, 'phone')}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly || this._isPermitted('u_tollfree') ? (
                            <div className="form-group col-md-6">
                              <MDBInput
                                label="Toll Free"
                                group
                                type="text"
                                name="u_tollfree"
                                value={u_tollfree || ''}
                                onBlur={(evt) => this.onChange(evt, 'phone')}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly || this._isPermitted('u_fax') ? (
                            <div className="form-group col-md-6">
                              <MDBInput
                                label="Fax Number"
                                group
                                type="text"
                                name="u_fax"
                                value={u_fax || ''}
                                onBlur={(evt) => this.onChange(evt, 'phone')}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly || this._isPermitted('u_address1') ? (
                            <div className="form-group col-md-6">
                              <MDBInput
                                label="Address 1"
                                group
                                type="text"
                                name="u_address1"
                                value={u_address1 || ''}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly || this._isPermitted('u_address2') ? (
                            <div className="form-group col-md-6">
                              <MDBInput
                                label="Address 2"
                                group
                                type="text"
                                name="u_address2"
                                value={u_address2 || ''}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly || this._isPermitted('u_city') ? (
                            <div className="form-group col-md-6">
                              <MDBInput
                                label="City"
                                group
                                type="text"
                                name="u_city"
                                value={u_city || ''}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly || this._isPermitted('u_state') ? (
                            <div className="form-group col-md-6">
                              <MDBSelect
                                options={this.state.states}
                                value={u_state || 'Choose your State'}
                                selected={u_state || 'Choose your State'}
                                getValue={(value) => {
                                  this.onStatesChange(value[0])
                                }}
                                label="State"
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly || this._isPermitted('u_zip') ? (
                            <div className="form-group col-md-6">
                              <MDBInput
                                label="Zip"
                                group
                                type="text"
                                name="u_zip"
                                value={u_zip || ''}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {!isReadOnly ||
                          this._isPermitted('spoken_language') ? (
                            <div className="form-group col-md-6">
                              <MDBSelect
                                options={this.state.spokenLanguages}
                                multiple
                                getValue={(value) => {
                                  this.onSpokenLanguageChange(value)
                                }}
                                label="Spoken Language"
                                readOnly={isReadOnly}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        {!isReadOnly && userRoles && userRoles.admin_area ? (
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <MDBInput
                                label="Admin Notes"
                                group
                                type="textarea"
                                name="u_notes_admin"
                                value={u_notes_admin || ''}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {(!isReadOnly && userRoles && userRoles.admin_area) ||
                        this._isAssumedUser ? (
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <MDBInput
                                label="Private Notes"
                                group
                                type="textarea"
                                name="u_private_notes"
                                value={u_private_notes || ''}
                                onChange={this.onChange}
                                validate
                                readOnly={isReadOnly}
                              />
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {!isReadOnly ? (
                          <div className="text-center mb-4 mt-5">
                            <MDBBtn
                              color="danger"
                              type="button"
                              className="btn-block z-depth-2"
                              onClick={this.handleUpdate}
                            >
                              {submitting ? 'Saving...' : 'Save Profile'}
                            </MDBBtn>
                          </div>
                        ) : (
                          <></>
                        )}
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                  {!isReadOnly && id && (
                    <MDBCard>
                      <MDBCardHeader color="indigo">Notes</MDBCardHeader>
                      <MDBCardBody>
                        <UserNotesWidget userId={id} />
                        {showPrivateNotes && (
                          <UserNotesWidget private={1} userId={id} />
                        )}
                      </MDBCardBody>
                    </MDBCard>
                  )}
                </MDBCol>
                <MDBCol lg="4">
                  <MDBCard testimonial>
                    <MDBCardHeader color="indigo">
                      Profile Photo & Details
                    </MDBCardHeader>
                    <MDBCardBody>
                      <h4 className="card-title">
                        {!!this.state.clonedUserData.u_fname
                          ? this.state.clonedUserData.u_fname
                          : ''}{' '}
                        {!!this.state.clonedUserData.u_lname
                          ? this.state.clonedUserData.u_lname
                          : ''}
                      </h4>
                      <hr />
                      <img
                        src={
                          u_picture
                            ? `https://firebasestorage.googleapis.com/v0/b/${
                                env.integrations.firebase.config.storageBucket
                              }/o/${encodeURIComponent(u_picture)}?alt=media`
                            : placeholder_picture
                        }
                        alt="Professional Head Shot"
                        className="rounded img-fluid d-block headshot-ratio w-100"
                        style={{ objectFit: 'cover' }}
                      />
                      {!isReadOnly || this._isPermitted('profile_pic') ? (
                        <form>
                          <MDBFileInput
                            name="profile_pic"
                            getValue={(value) => this.onSelectFile(value[0])}
                          />
                          {src && (
                            <ReactCrop
                              src={src}
                              crop={crop}
                              ruleOfThirds
                              onImageLoaded={this.onImageLoaded}
                              onComplete={this.onCropComplete}
                              onChange={this.onCropChange}
                            />
                          )}
                          <MDBRow>
                            <MDBCol size="12">
                              <MDBBtn
                                sm="true"
                                onClick={this._toggleProfilePicModal}
                                className="btn-block"
                                style={{ marginBottom: '5px' }}
                                color="indigo"
                              >
                                Profile Pic Guidelines
                              </MDBBtn>
                            </MDBCol>
                            <MDBCol size="12">
                              <MDBBtn
                                sm="true"
                                color="success"
                                className="btn-block"
                                onClick={this.handleUpdateHeadshot}
                              >
                                Upload Picture
                              </MDBBtn>
                            </MDBCol>
                          </MDBRow>
                        </form>
                      ) : (
                        <></>
                      )}
                      <hr />
                      <MDBListGroup>
                        {this._isStaffUser ? (
                          <MDBListGroupItem
                            style={{ backgroundColor: 'rgba(220,220,220,0.8)' }}
                          >
                            <div
                              style={{
                                fontSize: '12px',
                                fontWeight: '500',
                                color: 'rgba(120,120,120,0.6)',
                                textAlign: 'center',
                              }}
                            >
                              USABG STAFF ONLY - PRIVATE
                            </div>
                            <div
                              style={{
                                fontSize: '14px',
                                fontWeight: '500',
                                color: 'rgba(120,120,120,0.8)',
                                textAlign: 'center',
                              }}
                            >
                              <b>User ID:</b>{' '}
                              {!!this.state.clonedUserData.id
                                ? this.state.clonedUserData.id
                                : ''}
                            </div>
                            <AgentCorporateEmailLink
                              userId={this.state.clonedUserData?.id}
                            />
                          </MDBListGroupItem>
                        ) : (
                          <></>
                        )}
                        {!isReadOnly || this._isPermitted('u_email') ? (
                          <MDBListGroupItem>
                            <b>Login:</b>{' '}
                            {!!this.state.clonedUserData.u_email
                              ? this.state.clonedUserData.u_email
                              : 'N/A'}
                          </MDBListGroupItem>
                        ) : (
                          <></>
                        )}
                        {!isReadOnly || this._isPermitted('displayname') ? (
                          <MDBListGroupItem>
                            <b>Account Type:</b>{' '}
                            {!!this.state.clonedUserData.displayname
                              ? this.state.clonedUserData.displayname
                              : 'N/A'}
                          </MDBListGroupItem>
                        ) : (
                          <></>
                        )}
                        {!isReadOnly || this._isPermitted('d_name') ? (
                          <MDBListGroupItem>
                            <b>Division:</b>{' '}
                            {!!this.state.clonedUserData.d_name
                              ? this.state.clonedUserData.d_name
                              : 'N/A'}
                          </MDBListGroupItem>
                        ) : (
                          <></>
                        )}
                        {!isReadOnly || this._isPermitted('com_name') ? (
                          <MDBListGroupItem>
                            <b>Commission Level:</b>{' '}
                            {!!this.state.clonedUserData.com_name
                              ? this.state.clonedUserData.com_name
                              : 'N/A'}
                          </MDBListGroupItem>
                        ) : (
                          <></>
                        )}
                        {!isReadOnly ||
                        this._isPermitted('upline_fname') ||
                        this._isPermitted('upline_lname') ? (
                          <MDBListGroupItem>
                            <b>Upline Leader:</b>{' '}
                            {!!this.state.clonedUserData.upline_fname
                              ? this.state.clonedUserData.upline_fname
                              : ''}{' '}
                            {!!this.state.clonedUserData.upline_lname
                              ? this.state.clonedUserData.upline_lname
                              : ''}{' '}
                            {!this.state.clonedUserData.upline_fname &&
                            !this.state.clonedUserData.upline_lname
                              ? 'N/A'
                              : ''}
                          </MDBListGroupItem>
                        ) : (
                          <></>
                        )}
                        {!isReadOnly || this._isPermitted('u_enrolled') ? (
                          <MDBListGroupItem>
                            <b>Enrollment Date:</b>{' '}
                            {!!this.state.clonedUserData.u_enrolled
                              ? moment(
                                  this.state.clonedUserData.u_enrolled
                                ).format('MM/DD/YYYY')
                              : 'N/A'}
                          </MDBListGroupItem>
                        ) : (
                          <></>
                        )}
                        {!isReadOnly || this._isPermitted('u_active') ? (
                          <MDBListGroupItem>
                            <b>Account Status:</b>{' '}
                            {!!this.state.clonedUserData.u_active
                              ? 'Active'
                              : 'Inactive'}
                          </MDBListGroupItem>
                        ) : (
                          <></>
                        )}
                        {!isReadOnly || this._isPermitted('u_lastlogin') ? (
                          <MDBListGroupItem>
                            <b>Last Login:</b>{' '}
                            {!!this.state.clonedUserData.u_lastlogin
                              ? moment(
                                  this.state.clonedUserData.u_lastlogin
                                ).format('llll')
                              : 'N/A'}
                          </MDBListGroupItem>
                        ) : (
                          <></>
                        )}
                        {!isReadOnly || this._isPermitted('created_at') ? (
                          <MDBListGroupItem>
                            <b>Account Created:</b>{' '}
                            {!!this.state.clonedUserData.created_at
                              ? moment(
                                  this.state.clonedUserData.created_at
                                ).format('llll')
                              : 'N/A'}
                          </MDBListGroupItem>
                        ) : (
                          <></>
                        )}
                      </MDBListGroup>
                      {!isReadOnly &&
                      localUserData &&
                      [35, 36, 219, 222].includes(
                        JSON.parse(localUserData).usertype_id
                      ) ? (
                        <div
                          className="text-center"
                          style={{ marginTop: '1rem' }}
                        >
                          <MDBBtn
                            color="primary"
                            className="btn-block"
                            onClick={() => this._toggleUserTermsModal()}
                          >
                            View User's Accepted Terms
                          </MDBBtn>
                        </div>
                      ) : (
                        <></>
                      )}
                    </MDBCardBody>
                  </MDBCard>
                  {this._canViewHistory() ? (
                    <>
                      <MDBCard className="mt-5 mb-5 user-history">
                        <MDBCardHeader color="indigo">
                          Usertype & Commission Level History
                        </MDBCardHeader>
                        <MDBCardBody className="mx-4 mt-4">
                          <MDBAlert color="danger" className="no-agents-alert">
                            Note: Historical information is only visible to
                            Leaders.{' '}
                            <strong>Agents can not see this history.</strong>
                          </MDBAlert>
                          <MDBListGroup>
                            {notesHistoryList && notesHistoryList.length ? (
                              notesHistoryList.map((item, i) => {
                                return (
                                  <MDBListGroupItem
                                    key={i}
                                    dangerouslySetInnerHTML={{ __html: item }}
                                  ></MDBListGroupItem>
                                )
                              })
                            ) : (
                              <MDBListGroupItem>
                                No agent notes on record.
                              </MDBListGroupItem>
                            )}
                          </MDBListGroup>
                        </MDBCardBody>
                      </MDBCard>
                      <div className="mb-3">
                        <RequiredTrainingChart />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {!isReadOnly ? (
                    <>
                      <div className="mb-3">
                        <ResetEnrollmentButton
                          onReset={(res) => {
                            if (res) {
                              if (res === true)
                                toast.success(
                                  "Agent's enrollment date has been updated to today.",
                                  { position: toast.POSITION.TOP_RIGHT }
                                )
                              else if (res)
                                toast.error(res, {
                                  position: toast.POSITION.TOP_RIGHT,
                                })
                            }
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <ResyncCorporateEmailButton
                          onResync={(res) => {
                            if (res) {
                              if (res === 'resync')
                                toast.success(
                                  "Agent's corporate email has been re-synced & instructional email sent.",
                                  { position: toast.POSITION.TOP_RIGHT }
                                )
                              else if (res === 'delete')
                                toast.success(
                                  "Agent's corporate email has been deleted.",
                                  { position: toast.POSITION.TOP_RIGHT }
                                )
                              else if (res)
                                toast.error(res, {
                                  position: toast.POSITION.TOP_RIGHT,
                                })
                            }
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <AddAdminFeeWaiverButton
                          onWaived={(res) => {
                            if (res) {
                              if (res === true)
                                toast.success(
                                  "Agent's subscription admin fee has been waived for today.",
                                  { position: toast.POSITION.TOP_RIGHT }
                                )
                              else if (res)
                                toast.error(res, {
                                  position: toast.POSITION.TOP_RIGHT,
                                })
                            }
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <ResetUplineSignatureButton
                          onReset={(res) => {
                            if (res) {
                              if (res === true) {
                                toast.success(
                                  "Agent's upline signature has been updated.",
                                  { position: toast.POSITION.TOP_RIGHT }
                                )
                              } else if (res)
                                toast.error(res, {
                                  position: toast.POSITION.TOP_RIGHT,
                                })
                            }
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <ViewAwardButton />
                      </div>
                      <div className='mb-3'>
                        <ResendEmailButton />
                      </div>
                      <div className="mb-3">
                        <UserActiveButton
                          onChange={(res) => {
                            if (res) {
                              if (res === true) {
                                let changeTo = ''
                                switch (
                                  parseInt(UserProfileService.get('u_active'))
                                ) {
                                  case 1:
                                    changeTo = 'Active'
                                    break
                                  case 0:
                                    changeTo = 'Deactivated'
                                    break
                                  case -1:
                                    changeTo = 'Protected'
                                    break
                                  case -2:
                                    changeTo = 'Blacklisted'
                                    break
                                  default:
                                    return ''
                                }

                                toast.success(
                                  `Agent's account active status has been changed to '${changeTo}'.`,
                                  { position: toast.POSITION.TOP_RIGHT }
                                )
                              } else if (res)
                                toast.error(res, {
                                  position: toast.POSITION.TOP_RIGHT,
                                })
                            }
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <HasOnboardedToggleSwitch
                          onToggle={(res) => {
                            if (res) {
                              if (res === true)
                                toast.success(
                                  'Success.  Agent has completed onboarding.',
                                  { position: toast.POSITION.TOP_RIGHT }
                                )
                              else if (res === false)
                                toast.success(
                                  'Success.  Agent has NOT completed onboarding.',
                                  { position: toast.POSITION.TOP_RIGHT }
                                )
                              else if (res)
                                toast.error(res, {
                                  position: toast.POSITION.TOP_RIGHT,
                                })
                            }
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <IsTeamRecruiterToggleSwitch
                          onToggle={(res) => {
                            if (res) {
                              if (res === true)
                                toast.success(
                                  'Success.  Agent is setup as a Team Recruiter.',
                                  { position: toast.POSITION.TOP_RIGHT }
                                )
                              else if (res === false)
                                toast.success(
                                  'Success.  Agent is NOT setup as a Team Recruiter.',
                                  { position: toast.POSITION.TOP_RIGHT }
                                )
                              else if (res)
                                toast.error(res, {
                                  position: toast.POSITION.TOP_RIGHT,
                                })
                            }
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <UpdatePaymentToggleSwitch
                          onToggle={(res) => {
                            if (res === true)
                              toast.success(
                                'Success.  Enable to show update payment method modal',
                                { position: toast.POSITION.TOP_RIGHT }
                              )
                            else if (res === false)
                              toast.success(
                                'Success.  Disable to show update payment method modal',
                                { position: toast.POSITION.TOP_RIGHT }
                              )
                            else if (res)
                              toast.error(res, {
                                position: toast.POSITION.TOP_RIGHT,
                              })
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </MDBCol>
              </MDBRow>
            )}
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AccountProfile
