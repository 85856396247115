import React from 'react'
import AdminApi from './../../api/admin-api/admin-api.js'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBDataTable,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
} from 'mdbreact'
import PageHeading from './../../components/shared/PageHeading.component'
import LoadingSpinner from './../../components/shared/LoadingSpinner.component'
import moment from 'moment'
import { UISelect } from './../../components/forms/form-fields'

import './SubscriptionReports.page.scss'

class SubscriptionReportsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
      report: '',
      reportDescription: '',
      reportId: null,
      reportDates: [],
      reportData: [],
      tableData: { columns: [], rows: [] },
    }
  }

  componentDidMount() {
    this.changeReport('cancel-reason').then((reportDates) => {
      let reportId = null
      if (reportDates && Array.isArray(reportDates) && reportDates.length)
        reportId = reportDates[0] && reportDates[0]?.id

      if (reportId) this.setState({ reportId }, this.fetchReport)
    })
  }

  changeReport = async (report, event) => {
    if (event) event.preventDefault()

    let reportDescription = ''
    switch (report) {
      case 'cancel-reason':
        reportDescription =
          'Daily automated report prepared by Stripe providing subscription cancelation details for the previous 45 days from the date of report.  The report reflects the most recent close of business (daily batch out).'
        break

      case 'subscription-payments-by-product':
        reportDescription =
          'Daily automated report prepared by Stripe providing subscription payment details broken down by product type.  The report reflects the most recent close of business (daily batch out).'
        break

      case 'cancel-failed-payments':
        reportDescription =
          'Daily automated report prepared by Stripe providing failed payment details including agent name.  The report reflects the most recent close of business (daily batch out).'
        break

      case 'refunds':
        reportDescription =
          'Daily automated report prepared by Stripe providing refund payment details including agent name.  The report reflects the most recent close of business (daily batch out).'
        break

      default:
        break
    }

    this.setState({
      report,
      reportDescription,
      isFetching: true,
      tableData: { columns: [], rows: [] },
    })
    const reportDates =
      (
        await AdminApi.getReportData(
          'subscriptions',
          `${report}-options`,
          moment().format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        )
      )?.data?.data || []
    this.setState({ reportDates, isFetching: false })

    return reportDates
  }

  compileTableHeaders = () => {
    switch (this.state.report) {
      case 'cancel-reason':
        return [
          { label: 'Agent ID', field: 'AgentID' },
          { label: 'Agent Name', field: 'AgentName' },
          { label: 'Agent Email', field: 'AgentEmail' },
          { label: 'Billing Date', field: 'BillingDate' },
          { label: 'Canceled At', field: 'CanceledAt' },
          { label: 'Stripe Customer ID', field: 'CustomerID' },
          { label: 'Billing Name', field: 'CustomerName' },
          { label: 'Billing Email', field: 'CustomerEmail' },
          { label: 'Subscription ID', field: 'SubscriptionID' },
          { label: 'Subscription Status', field: 'SubscriptionStatus' },
          { label: 'Payment Failure Code', field: 'PaymentFailureCode' },
          { label: 'Payment Failure Date', field: 'PaymentFailureDate' },
          { label: 'Payment Failure Message', field: 'PaymentFailureMessage' },
        ]

      case 'subscription-payments-by-product':
        return [
          { label: 'Agent Name', field: 'AgentName' },
          { label: 'Product Name', field: 'ProductName' },
          { label: 'Payment Date', field: 'CreatedAt' },
          { label: 'Total Revenue', field: 'TotalRevenue' },
        ]

      case 'cancel-failed-payments':
        return [
          { label: 'Agent Name', field: 'AgentName' },
          { label: 'Agent Email', field: 'AgentEmail' },
          { label: 'Payment Date', field: 'PaymentDate' },
          { label: 'Amount', field: 'Amount' },
          { label: 'Failure Code', field: 'FailureCode' },
          { label: 'Outcome Reasons', field: 'OutcomeReason' },
          { label: 'Payment Intent', field: 'PaymentIntent' },
          { label: 'Failure Message', field: 'FailureMessage' },
          { label: 'Purchase Description', field: 'PurchaseDescription' },
          { label: 'Charge ID', field: 'ChargeId' },
          { label: 'Customer ID', field: 'CustomerId' },
        ]

      case 'refunds':
        return []

      default:
        return []
    }
  }

  compileTableData = (reportData) => {
    switch (this.state.report) {
      case 'cancel-reason':
        return reportData
          .sort((rowA, rowB) => {
            let a = moment(rowA?.CanceledAt).toDate().getTime(),
              b = moment(rowB?.CanceledAt).toDate().getTime()
            return a < b ? 1 : b < a ? -1 : 0
          })
          .map((row) => {
            return {
              AgentID: row?.AgentID,
              AgentName: row?.AgentName,
              AgentEmail: row?.AgentEmail,
              BillingDate: row?.BillingDate,
              CanceledAt: row?.CanceledAt,
              CustomerID: row?.CustomerID ? (
                <a
                  href={`https://dashboard.stripe.com/customers/${row.CustomerID}`}
                  target="_BLANK"
                  rel="noopener noreferrer"
                >
                  {row.CustomerID}
                </a>
              ) : (
                '---'
              ),
              CustomerName: row?.CustomerName,
              CustomerEmail: row?.CustomerEmail,
              SubscriptionID: row?.SubscriptionID ? (
                <a
                  href={`https://dashboard.stripe.com/subscriptions/${row.SubscriptionID}`}
                  target="_BLANK"
                  rel="noopener noreferrer"
                >
                  {row.SubscriptionID}
                </a>
              ) : (
                '---'
              ),
              SubscriptionStatus: row?.SubscriptionStatus,
              PaymentFailureCode: row?.PaymentFailureCode,
              PaymentFailureDate: row?.PaymentFailureDate,
              PaymentFailureMessage: row?.PaymentFailureMessage,
            }
          })

      case 'subscription-payments-by-product':
        return reportData
          .sort((rowA, rowB) => {
            let a = moment(rowA?.created_at).toDate().getTime(),
              b = moment(rowB?.created_at).toDate().getTime()
            return a < b ? 1 : b < a ? -1 : 0
          })
          .map((row) => {
            return {
              AgentName: row?.customer_name,
              ProductName: row?.product_name,
              CreatedAt: row?.created_at,
              TotalRevenue: row?.total_revenue,
            }
          })

      case 'cancel-failed-payments':
        return reportData
          .sort((rowA, rowB) => {
            let a = moment(rowA?.payment_date).toDate().getTime(),
              b = moment(rowB?.payment_date).toDate().getTime()
            return a < b ? 1 : b < a ? -1 : 0
          })
          .map((row) => {
            return {
              Amount: row?.amount,
              ChargeId: row?.charge_id,
              AgentName: row?.agent_name,
              AgentEmail: row?.agent_email,
              CustomerId: row?.customer_id,
              FailureCode: row?.failure_code,
              PaymentDate: row?.payment_date,
              OutcomeReason: row?.outcome_reason,
              PaymentIntent: row?.payment_intent,
              FailureMessage: row?.failure_message,
              PurchaseDescription: row?.['Purchase Description'],
            }
          })

        return []

      case 'refunds':
        console.log(this.state.report, reportData)
        return []

      default:
        return []
    }
  }

  setTableData = () => {
    this.setState({
      tableData: {
        columns: this.compileTableHeaders(),
        rows: this.compileTableData(this.state.reportData),
      },
    })
  }

  fetchReport = async () => {
    this.setState({ isFetching: true })
    this.setState(
      {
        reportData:
          (
            await AdminApi.getReportData(
              'subscriptions',
              this.state.report,
              moment().format('YYYY-MM-DD'),
              moment().format('YYYY-MM-DD'),
              null,
              { report_id: this.state.reportId }
            )
          )?.data?.data || [],
        isFetching: false,
      },
      this.setTableData
    )
  }

  renderReportOptions = () => {
    if (
      Array.isArray(this.state.reportDates) &&
      this.state.reportDates.length
    ) {
      return (
        <UISelect
          label="Select Report Date"
          className="placeholder-color-white"
          options={this.state.reportDates.map((opt) => ({
            value: opt.id,
            text: opt.report_title,
          }))}
          style={{ color: '#fff' }}
          getValue={(e) => {
            this.setState(
              { isFetching: true, reportId: e.shift() },
              this.fetchReport
            )
          }}
        />
      )
    }

    return <></>
  }

  render() {
    const reportTitle =
      this.state.reportId &&
      this.state.reportDates
        .filter((opt) => `${opt.id}` === `${this.state.reportId}`)
        .shift()?.report_title

    return (
      <>
        <DashboardLayout>
          <main
            id="SubscriptionReportsPage"
            className="adminStyle mainSection pb-5 pt-3"
          >
            <MDBContainer fluid className="mt-2">
              <PageHeading
                label="Subscription Reports"
                url={this.props?.match?.url}
                from={this.props?.location?.state?.from}
                goTo={(url) =>
                  this.props.history.push(url, { from: '/reports/leaders' })
                }
              />
              <MDBRow>
                <MDBCol size="12" sm="6">
                  <ul id="ReportSelector" className="row mb-4">
                    <li
                      className={
                        this.state.report === 'cancel-reason' ? 'active' : ''
                      }
                    >
                      <a
                        onClick={(event) =>
                          this.changeReport('cancel-reason', event)
                        }
                        href={`/reports/subscriptions/cancel-reason`}
                      >
                        Subscription Canceled Reason
                      </a>
                    </li>
                    <li
                      className={
                        this.state.report === 'subscription-payments-by-product'
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        onClick={(event) =>
                          this.changeReport(
                            'subscription-payments-by-product',
                            event
                          )
                        }
                        href={`/reports/subscriptions/subscription-payments-by-product`}
                      >
                        Subscription Payments By Product
                      </a>
                    </li>
                    <li
                      className={
                        this.state.report === 'cancel-failed-payments'
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        onClick={(event) =>
                          this.changeReport('cancel-failed-payments', event)
                        }
                        href={`/reports/subscriptions/cancel-failed-payments`}
                      >
                        Failed Payments
                      </a>
                    </li>
                    <li
                      className={
                        this.state.report === 'refunds' ? 'active' : ''
                      }
                    >
                      <a
                        onClick={(event) => this.changeReport('refunds', event)}
                        href={`/reports/subscriptions/refunds`}
                      >
                        Refunds
                      </a>
                    </li>
                  </ul>
                </MDBCol>
                <MDBCol size="12" sm="6">
                  {this.renderReportOptions()}
                </MDBCol>
                <MDBCol size="12">
                  <MDBCard>
                    <MDBCardHeader>
                      <MDBRow>
                        <MDBCol size="12" md="6">
                          {reportTitle || 'No Report or Date Selected.'}
                        </MDBCol>
                        <MDBCol
                          size="12"
                          md="6"
                          style={{ fontSize: '12px', fontWeight: '500' }}
                        >
                          {this.state.reportDescription}
                        </MDBCol>
                      </MDBRow>
                    </MDBCardHeader>
                    <MDBCardBody>
                      {this.state.isFetching ? (
                        <div className="loading-wrapper">
                          <LoadingSpinner size="md" isActive={true} />
                        </div>
                      ) : (
                        <MDBDataTable
                          searching={false}
                          paging={false}
                          striped
                          responsive
                          bordered
                          data={this.state.tableData}
                        />
                      )}
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </>
    )
  }
}

export default SubscriptionReportsPage
