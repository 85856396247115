export const commissionLevels = {
    agent: "USABG205",
    benefitsAdvisor: "USABG10",
    benefitsCounselor: "USABG20",
    district : "USABG305",
    division: "USABG505",
    employee: "00",
    fmo: "USABG1000",
    referralPartner: "RP100",
    region: "USABG405",
    seniorBenefitsCounselor: "USABG30",
}