import RestApi from './../../api/automation-api-client'

const srvcEndpoint = '/policies'
export default {
  search: async (req, opts) => RestApi.request('GET', srvcEndpoint, req, opts),
  get: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, id, req, opts),
  store: async (data, opts) =>
    RestApi.request('POST', srvcEndpoint, data, opts),
  update: async (id, data, opts) =>
    RestApi.request('PUT', srvcEndpoint, id, data, opts),
  delete: async (id, opts) => RestApi.request('DELETE', srvcEndpoint, id, opts),

  resetPoints: async (id, data) =>
    RestApi.request('PUT', srvcEndpoint, id, data, {
      endpoint: `/avs/reset-points/${id}`,
    }),
  checkAwards: async (id) =>
    RestApi.request(
      'PUT',
      srvcEndpoint,
      null,
      {},
      { endpoint: `/check-milestone-awards/${id}` }
    ),
  verifyWritingCode: async (data) =>
    RestApi.request('POST', srvcEndpoint, null, data, {
      endpoint: `/verify-writing-codes`,
    }),
}
