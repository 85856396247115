import React, { Component } from 'react'
import env from './../../environments/environment'
import PAGE_CONFIG from './../../constants/public-facing'
import AdminApi from '../../api/admin-api/admin-api'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import {
  phoneToView,
  phoneToStorage,
} from './../../shared/formatters/telephone.formatter'
import appConstants from '../../constants/appConstants'

import './Footer.scss'

class Footer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentYear: new Date().getFullYear(),
      contactData: {},
      numOfOrganization: null,
    }
  } //constructor

  componentDidMount() {
    AdminApi.getContactDetails()
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState({
            contactData: result.data.data,
          })
        }
      })
      .catch((error) => {
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })

    this.setOrg()
  }

  setOrg() {
    this.state.numOfOrganization = 88
  }

  renderOffices() {
    if (PAGE_CONFIG?.footer?.locations?.enabled === true) {
      const { contactData } = this.state
      return (
        <section
          className="section-40 section-sm-75 home-footer"
          style={{ backgroundColor: 'rgb(220,220,220)' }}
        >
          <div
            className="row offices justify-content-center"
            style={{
              textAlign: 'center',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}
          >
            <div className="col-lg-4 col-md-6" style={{ padding: '20px' }}>
              <div className="img-location" style={{ marginBottom: '25px' }}>
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fimg.office.spring-hill.jpg?alt=media`}
                  alt="USABG HQ"
                />
              </div>
              <strong>USABG Headquarters</strong>
              <br />
              5141 Mariner Blvd.
              <br />
              Spring Hill, FL 34609
              <br />
              Office:{' '}
              <a
                href={`tel:${phoneToStorage(contactData.a_phone)}`}
                style={{ textDecoration: 'underline' }}
              >
                {phoneToView(contactData.a_phone)}
              </a>
            </div>
            <div className="col-lg-4 col-md-6" style={{ padding: '20px' }}>
              <div className="img-location" style={{ marginBottom: '25px' }}>
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fimg.office.ohio.jpg?alt=media`}
                  alt="USABG Ohio"
                />
              </div>
              <strong>USABG Ohio</strong>
              <br />
              9373 Cincinnati Columbus Rd.
              <br />
              West Chester Township, OH. 45069
              <br />
              Office:{' '}
              <a
                href={`tel:${phoneToStorage('(937) 880-1040')}`}
                style={{ textDecoration: 'underline' }}
              >
                {phoneToView('(937) 880-1040')}
              </a>
            </div>
            <div className="col-lg-4 col-md-6" style={{ padding: '20px' }}>
              <div className="img-location" style={{ marginBottom: '25px' }}>
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fimg.office.springboro.jpg?alt=media`}
                  alt="USABG Springboro"
                />
              </div>
              <strong>USABG Springboro</strong>
              <br />
              15 South Main Street
              <br />
              Springboro, OH. 45066
              <br />
              Office:{' '}
              <a
                href={`tel:${phoneToStorage('(937) 619-9939')}`}
                style={{ textDecoration: 'underline' }}
              >
                {phoneToView('(937) 619-9939')}
              </a>
            </div>
            <div className="col-lg-4 col-md-6" style={{ padding: '20px' }}>
              <div className="img-location" style={{ marginBottom: '25px' }}>
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fimg.brentwood.png?alt=media`}
                  alt="USABG Brentwood"
                />
              </div>
              <strong>USABG Brentwood</strong>
              <br />
              116 Wilson Pike Cir
              <br />
              Suite 240 Brentwood TN 37027
              <br />
              Office:{' '}
              <a
                href={`tel:${phoneToStorage('(615) 492-1292')}`}
                style={{ textDecoration: 'underline' }}
              >
                {phoneToView('(615) 492-1292')}
              </a>
            </div>
            <div className="col-lg-4 col-md-6" style={{ padding: '20px' }}>
              <div className="img-location" style={{ marginBottom: '25px' }}>
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Fimg.office.nathan.jpg?alt=media`}
                  alt="USABG Nathan and Catherine Curry"
                />
              </div>
              <strong>USABG Daytona</strong>
              <br />
              913 Big Tree Rd
              <br />
              Daytona Beach, FL 32119
              <br />
              Office:{' '}
              <a
                href={`tel:${phoneToStorage('(386) 341-6791')}`}
                style={{ textDecoration: 'underline' }}
              >
                {phoneToView('(386) 341-6791')}
              </a>
            </div>
          </div>
        </section>
      )
    }

    return <></>
  }

  render() {
    const { contactData } = this.state
    return (
      <>
        {this.renderOffices()}
        <section className="section-40 section-sm-75 bg-cello">
          <div className="shell">
            <div className="range range-xs-center">
              <div className="cell-xs-9 cell-sm-11 cell-lg-12">
                <div className="range">
                  {PAGE_CONFIG?.footer?.introduction?.enabled === true ? (
                    <>
                      <div className="cell-sm-6 cell-md-10 cell-lg-4">
                        <div
                          style={{ maxWidth: '510px' }}
                          className="inset-lg-right-20"
                        >
                          <a href="index.html" className="brand brand-inverse">
                            <h4 className="text--white">USABG</h4>
                          </a>
                          <p className="offset-top-22 text-spacing--30 text-kashmir-blue">
                            Our friends and family are our clients, and our
                            clients become our friends and family for life.
                          </p>
                        </div>
                      </div>
                      <br />
                      <br />
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="cell-sm-6 offset-top-50 offset-lg-top-0">
                    <h6 className="h6 text--white">Quick links</h6>
                    <div
                      style={{ maxWidth: '270px' }}
                      className="row offset-top-22 offset-md-top-30"
                    >
                      <div className="col-xs-6">
                        <ul className="list-marked-variant-2">
                          <li>
                            <a href="/">Home</a>
                          </li>
                          <li>
                            <a href="/#about-us">About</a>
                          </li>
                          <li>
                            <a href="/#products">Products</a>
                          </li>
                          {/*<li><a href="/#our-mission">Careers</a></li>*/}
                        </ul>
                      </div>
                      <div className="col-xs-6">
                        <ul className="list-marked-variant-2">
                          <li>
                            <a
                              href={
                                (env.integrations?.automation?.url ||
                                  'https://automation.usabg.com') +
                                '/service-status'
                              }
                              className="text-nowrap"
                            >
                              Service Status
                            </a>
                          </li>
                          <li>
                            <a href="/contact">Contact</a>
                          </li>
                          <li>
                            <a href="/login">Login</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="cell-sm-6 offset-top-50 offset-lg-top-0">
                    <h6 className="h6 text--white">
                      Contact Our Corporate Office
                    </h6>
                    <address className="contact-info offset-top-22 offset-md-top-30 offset-lg-top-35 text-left">
                      <div className="unit unit-horizontal unit-spacing-md unit-middle">
                        <div className="unit-left">
                          <span className="icon icon-xs icon-white material-icons-phone"></span>
                        </div>
                        <div className="unit-body">
                          <a
                            href={`tel:${phoneToStorage(contactData.a_phone)}`}
                            className="link-white"
                          >
                            {phoneToView(contactData.a_phone)}
                          </a>
                        </div>
                      </div>
                      <div className="unit unit-horizontal unit-spacing-md unit-middle offset-top-15">
                        <div className="unit-left">
                          <span className="icon icon-xs icon-white fa fa-envelope-o"></span>
                        </div>
                        <div className="unit-body">
                          <a
                            href={`mailto:${contactData.a_email}`}
                            className="link-white"
                          >
                            {contactData.a_email}
                          </a>
                        </div>
                      </div>
                      <div className="unit unit-horizontal unit-spacing-md offset-top-15">
                        <div className="unit-left">
                          <span className="icon icon-xs icon-white material-icons-place"></span>
                        </div>
                        <div className="unit-body">
                          <span className="link-white reveal-inline cursor-pointer">
                            {contactData.a_address}, {contactData.a_city},{' '}
                            {contactData.a_state} {contactData.a_zip}
                          </span>
                        </div>
                      </div>
                    </address>
                  </div>
                </div>
                <div className="offset-top-50 offset-lg-top-0 mt-5">
                  <h6 className="h6 text--white">
                    Richard Banville LLC Licensed States
                  </h6>
                  <address className="contact-info offset-top-22 offset-md-top-30 offset-lg-top-35 text-left">
                    <div className="d-flex flex-wrap">
                      {appConstants.states
                        .filter(
                          (state) =>
                            !['new york', 'california'].includes(
                              `${state}`.trim().toLowerCase()
                            )
                        )
                        .map((state, index) => {
                          return (
                            <span key={index} className="mr-3 mb-1 text--white">
                              {state}
                            </span>
                          )
                        })}
                    </div>
                  </address>
                  <div className="mt-3 text--white">
                    We do not offer every plan available in your area. Currently
                    we represent {this.state.numOfOrganization} organizations.
                    <br></br>
                    Please contact{' '}
                    <a href="http://medicare.gov/">medicare.gov</a>{' '}
                    medicare.gov, 1-800-MEDICARE, or your local State Health
                    Insurance Program (SHIP) to get information on all of your
                    options.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="page-foot bg-cello">
          <div className="shell">
            <hr className="divider-bismark-04" />
          </div>
          <section className="section-35">
            <div className="shell text-center">
              <div className="range range-sm-justify range-sm-middle">
                {/* <div className="cell-sm-6 text-sm-right">
                  <div className="group-sm group-middle">
                    <p className="text-italic text-white">Follow Us:</p>
                    <ul className="list-inline list-inline-reset">
                      <li>
                        <a
                          href="https://www.youtube.com/playlist?list=PL5JDy4WQGFnmy3wztOlzuwE_i8y_9JvnL"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="icon icon-circle icon-blue-bayoux icon-xxs-smallest fa fa-youtube"
                        >
                          {''}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="cell-sm-4 offset-top-15 offset-sm-top-0 text-sm-left">
                  <p className="rights text-white">
                    <span id="copyright-year">{this.state.currentYear}</span>
                    <span>&nbsp;&#169;&nbsp;</span>
                    <span>USABG.&nbsp;</span>
                    <Link to={'/privacy-policy'} className="link-white-v2">
                      Privacy Policy
                    </Link>
                  </p>
                </div>
                <div className="cell-sm-8 offset-top-15 offset-sm-top-0 text-sm-left">
                  <p className="rights text-white">
                    Not connected with or endorsed by the United States
                    Government or the Federal Medicare Program.
                    <br />
                    This is solicitation for insurance.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </footer>
      </>
    )
  }
}

export default Footer
