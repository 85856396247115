import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import {
  MDBCol,
  MDBIcon,
  MDBAlert,
  MDBContainer,
  MDBRow,
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBSpinner,
} from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'
import { Subscription } from 'rxjs'
import TrainingsPageService from './shared/TrainingsPage.service'
import TrainingList from './components/TrainingList/TrainingList.component'
import TrainingVideoForm from './components/TrainingVideoForm/TrainingVideoForm.component'
import TrainingTypeForm from './components/TrainingTypeForm/TrainingTypeForm.component'
import TrainingValidator from './components/TrainingValidator/TrainingValidator.component'
import TrainingQuiz from './components/TrainingQuiz/TrainingQuiz.component'
import trainingsHeaderImg from './assets/bg.trainings-page.jpg'
import UserProfileService from './../../shared/services/UserProfile.service'
import ActivityStore from '../../shared/stores/activity.store'

import './TrainingsPage.scss'
import TrainingQuizService from '../../shared/services/TrainingQuiz.service'
import TrainingQuestionService from '../../shared/services/TrainingQuestion.service'
import TrainingAnswerService from '../../shared/services/TrainingAnswer.service'
import UserTrainingQuizService from '../../shared/services/UserTrainingQuiz.service'

class VideoInterval {
  #intervalId = null

  clear = () => {
    if (this.#intervalId) clearInterval(this.#intervalId)
    this.#intervalId = null
  }

  start = (callback, timeout) => {
    this.clear()
    if (callback && typeof callback === 'function')
      this.#intervalId = setInterval(
        callback,
        parseInt(isNaN(timeout) ? 1000 : timeout)
      )
  }
}

class TrainingsPage extends React.Component {
  state = {
    loading: false,
    submitting: false,
    content: '',
    trainings: [],
    trainingTypes: [],
    trainingLoading: true,
    trainingTypesLoading: true,

    videoUrl: null,
    videoTitle: null,

    training: null,
    trainingType: null,

    canCreate: false,
    canEdit: false,
    canDelete: false,
    inputValue: '',
    requires_quiz: null,
    training_quiz: null,
    training_questions: [],
    training_answers: [],
    training_quiz_loading: false,
  }

  #VideoInterval = null
  #_subscriptions$ = new Subscription()

  componentDidMount() {
    this.#VideoInterval = new VideoInterval()
    this.#fetchTrainingPageData(11)

    this.#_subscriptions$.add(
      TrainingsPageService.fetchTrainingTypes().subscribe((types) =>
        this.#setTrainingTypes(types)
      )
    )
    this.#_subscriptions$.add(
      TrainingsPageService.fetchTrainings().subscribe((trainings) =>
        this.#setTrainings(trainings)
      )
    )

    if (parseInt(UserProfileService.getCurrentUserTypeId()) !== 91) {
      this.#_subscriptions$.add(
        TrainingsPageService.getViewVideo().subscribe((training) =>
          this.#viewVideo(training)
        )
      )
      this.#_subscriptions$.add(
        TrainingsPageService.getEditVideo().subscribe((training) =>
          this.setState({ training })
        )
      )
      this.#_subscriptions$.add(
        TrainingsPageService.getEditType().subscribe((trainingType) =>
          this.setState({ trainingType })
        )
      )

      TrainingsPageService.fetchTrainingAttended()
      this.fetchUserTrainingQuizes()
    }

    this.#checkPermissions()
  }

  fetchUserTrainingQuizes = async () => {
    const user_training_quizes = (
      await UserTrainingQuizService.search({ pagination: false })
    )?.models
    TrainingsPageService.UserTrainingQuizSubject.next(user_training_quizes)
  }

  componentWillUnmount() {
    this.#_subscriptions$.unsubscribe()
    if (this.#VideoInterval && this.#VideoInterval?.clear)
      this.#VideoInterval.clear()
  }

  #setTrainings = (trainings) => {
    let trainingTypes = this.state.trainingTypes
    trainingTypes = Array.isArray(trainingTypes)
      ? trainingTypes.map((type) => {
          type.count = trainings.filter(
            (training) =>
              parseInt(training.training_type_id) === parseInt(type.id)
          ).length
          return type
        })
      : trainingTypes

    this.setState({
      trainingTypes,
      trainings,
      trainingLoading: !trainings.length,
    })
  }

  #setTrainingTypes = (types) => {
    types = Array.isArray(types)
      ? types.map((type) => {
          type.count = this.state.trainings.filter(
            (training) =>
              parseInt(training.training_type_id) === parseInt(type.id)
          ).length
          return type
        })
      : types

    this.setState({
      trainingTypes: types,
      loading: !types.length,
      trainingTypesLoading: !types.length,
    })
  }

  #checkPermissions = async () => {
    if (
      await UserProfileService.canUser([
        'trainings.create',
        'trainings.update',
        'trainings.delete',
      ])
    )
      this.setState({ canCreate: true, canEdit: true, canDelete: true })
  }

  #fetchTrainingPageData = async (id) => {
    this.setState({ loading: true })
    AdminApi.getContentById(id)
      .then((result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.content
        )
          this.setState({ content: result.data.data.content || '' })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  #createVideo = () =>
    this.state.canCreate ? TrainingsPageService.setEditVideo(true) : {}

  #createType = () =>
    this.state.canCreate ? TrainingsPageService.setEditType(true) : {}

  #viewVideo = async (video) => {
    if (video) {
      this.setState(
        {
          videoTitle: video.training_name,
          videoUrl: (
            <>
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src={video.training_link.replace(/(<([^>]+)>)/gi, '')}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  title={video.training_name}
                ></iframe>
              </div>
            </>
          ),
          requires_quiz: video.requires_quiz,
        },
        () => this.#VideoInterval.start(ActivityStore.restartTimer)
      )

      if (video.requires_quiz) {
        this.setState({ training_quiz_loading: true })

        const training_quiz = (
          await TrainingQuizService.search({
            search: { training_id: video.id },
          })
        )?.models[0]
        const training_questions = (
          await TrainingQuestionService.search({
            search: { quiz_id: training_quiz?.id },
          })
        )?.models
        const question_ids = training_questions.map((question) => question.id)
        const training_answers = (
          await TrainingAnswerService.search({
            search: {
              question_id: question_ids?.length > 0 ? question_ids : null,
            },
          })
        )?.models

        this.setState({
          training_quiz: training_quiz,
          training_questions: training_questions,
          training_answers: training_answers,
          training_quiz_loading: false,
        })
      }
    } else {
      this.setState({ videoTitle: null, videoUrl: <></> }, () =>
        this.#VideoInterval.clear()
      )
    }
  }

  render() {
    toast.configure()
    const {
        loading,
        content,
        trainingTypes,
        trainings,
        trainingLoading,
        trainingTypesLoading,
      } = this.state,
      isManager =
        this.state.canCreate ||
        this.state.canEdit ||
        UserProfileService.isA([
          'recruiter-group',
          'system-admin',
          'internal-admin',
          'division-admin',
          'region-admin',
          'district-admin',
        ]),
      showEmpty =
        this.state.canCreate || this.state.canEdit || this.state.canDelete,
      filteredTrainingTypes = (
        trainingTypes && trainingTypes.length ? trainingTypes : []
      )
        .filter(
          (trainingType) =>
            (isManager && parseInt(trainingType.is_manager) === 1) ||
            parseInt(trainingType.is_manager) === 0
        )
        .filter((trainingType) => showEmpty || trainingType.count)
        .filter((trainingType) =>
          trainings.length > 0
            ? trainings.filter(
                (training) =>
                  training.training_type_id === trainingType.id &&
                  training.training_name
                    .toLowerCase()
                    .includes(this.state.inputValue.toLowerCase())
              ).length > 0
            : true
        )
    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="TrainingsPage" className="mainSection">
            <MDBModal
              className="blocked-access"
              disableBackdrop={true}
              isOpen={
                parseInt(UserProfileService.getCurrentUserTypeId()) === 91
              }
              toggle={() => ({})}
              size="fluid"
            >
              <MDBModalHeader color="danger" toggle={() => ({})}>
                USABG Training Videos
              </MDBModalHeader>
              <MDBModalBody>
                <div>
                  <h4>We know you're excited!</h4>
                  <h5>But you have some steps to complete first ...</h5>
                </div>
                <br />
                <div className="pt-2 pb-5">
                  Agents must complete contracting requirements before starting
                  training videos. If you feel that your contracting
                  requirements have been met, reach out to your upline for
                  further assistance.
                  <br />
                  <a
                    href={`mailto:${
                      UserProfileService.getUserDetails().upline_email
                    }`}
                  >
                    Email {UserProfileService.getUserDetails().upline_fname}{' '}
                    {UserProfileService.getUserDetails().upline_lname}
                  </a>
                  <br />
                  <a className="btn" href="/welcome">
                    Back to Onboarding
                  </a>
                </div>
              </MDBModalBody>
            </MDBModal>

            <MDBModal
              disableBackdrop={true}
              isOpen={!!this.state.videoTitle}
              toggle={() => TrainingsPageService.setViewVideo(null)}
              size="fluid"
            >
              <MDBModalHeader
                toggle={() => TrainingsPageService.setViewVideo(null)}
              >
                USABG Training Video
                <br />
                <span>{this.state.videoTitle}</span>
              </MDBModalHeader>
              <MDBModalBody>
                {this.state.requires_quiz ? (
                  <MDBRow>
                    <MDBCol size="12" lg="8">
                      {this.state.videoUrl}
                    </MDBCol>
                    <MDBCol size="12" lg="4" className="mt-4 mt-lg-0">
                      {this.state.training_quiz_loading ? (
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <MDBSpinner></MDBSpinner>
                        </div>
                      ) : (
                        <TrainingQuiz
                          training_quiz={this.state.training_quiz}
                          training_questions={this.state.training_questions}
                          training_answers={this.state.training_answers}
                          onSuccess={() =>
                            TrainingsPageService.setViewVideo(null)
                          }
                        />
                      )}
                    </MDBCol>
                  </MDBRow>
                ) : (
                  <div>{this.state.videoUrl}</div>
                )}
              </MDBModalBody>
            </MDBModal>

            <MDBModal
              disableBackdrop={true}
              isOpen={this.state.training}
              toggle={() => TrainingsPageService.setEditVideo(false)}
              size="fluid"
            >
              <MDBModalHeader
                color="indigo"
                toggle={() => TrainingsPageService.setEditVideo(false)}
              >
                {(this.state.training === true ? 'Create new' : 'Update') +
                  ' Training Video'}
              </MDBModalHeader>
              <MDBModalBody>
                <TrainingVideoForm training={this.state.training} />
              </MDBModalBody>
            </MDBModal>

            <MDBModal
              disableBackdrop={true}
              isOpen={this.state.trainingType}
              toggle={() => TrainingsPageService.setEditType(false)}
              size="fluid"
            >
              <MDBModalHeader
                color="indigo"
                toggle={() => TrainingsPageService.setEditType(false)}
              >
                {(this.state.trainingType === true ? 'Create new' : 'Update') +
                  ' Training Type'}
              </MDBModalHeader>
              <MDBModalBody>
                <TrainingTypeForm type={this.state.trainingType} />
              </MDBModalBody>
            </MDBModal>

            <MDBContainer fluid className="mt-5">
              {loading ? (
                <h2>Loading...</h2>
              ) : (
                <>
                  <MDBRow className="mb-3">
                    <MDBCol sm="12" lg="8">
                      <h2>Trainings</h2>
                      {this.state.canCreate ? (
                        <>
                          <MDBBtn
                            color="indigo"
                            onClick={this.#createVideo}
                            className="add-new"
                          >
                            <MDBIcon icon="plus" /> Add New Training Video
                          </MDBBtn>
                          <MDBBtn
                            color="indigo"
                            onClick={this.#createType}
                            className="add-new"
                          >
                            <MDBIcon icon="plus" /> Add New Training Type
                          </MDBBtn>
                        </>
                      ) : (
                        <></>
                      )}
                      <hr />
                      <div className="header-wrapper">
                        <img
                          src={trainingsHeaderImg}
                          alt="trainings-header-img"
                        />
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    </MDBCol>
                    <MDBCol sm="12" lg="4">
                      <TrainingValidator
                        userId={UserProfileService.getUserId()}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol sm="12" lg="6">
                      <MDBInput
                        size="lg"
                        label="Search Trainings By Entering Keyword Here"
                        type="search"
                        className="background-color-white"
                        name="trainings_input"
                        onChange={(e) =>
                          e.target.value.length > 2
                            ? this.setState({ inputValue: e.target.value })
                            : this.setState({ inputValue: '' })
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    {filteredTrainingTypes.length ? (
                      filteredTrainingTypes.map((trainingType, idx) => (
                        <MDBCol
                          key={`training-list-col-${trainingType.id}-${idx}`}
                          sm="12"
                          lg="6"
                        >
                          <TrainingList
                            key={`training-list-ul-${trainingType.id}-${idx}`}
                            canEdit={this.state.canEdit}
                            type={trainingType}
                            typeId={trainingType.id}
                            inputValue={this.state.inputValue}
                          />
                        </MDBCol>
                      ))
                    ) : (
                      <MDBCol>
                        {!trainingLoading && !trainingTypesLoading ? (
                          <MDBAlert color="info">
                            <strong>On no</strong>&nbsp;
                            <MDBIcon icon="exclamation" />
                            &nbsp;
                            {`There are no training videos matching the search '${this.state.inputValue}.'`}
                          </MDBAlert>
                        ) : (
                          'Loading ...'
                        )}
                      </MDBCol>
                    )}
                  </MDBRow>
                </>
              )}
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default TrainingsPage
