import { makeAutoObservable } from 'mobx'
import UserProfileService from './../../../shared/services/UserProfile.service'
import ReportService from './../../../shared/services/Report.service'
import {
  UserFactory,
  LedgerFactory,
  LedgerTransactionFactory,
} from './../../../shared/factories'
import moment from 'moment'
import TransferStore from './Transfer.store'

const searchAgentLedgers = async ({ term, agentId, pagination }) => {
  pagination = pagination && typeof pagination === 'object' ? pagination : {}

  try {
    const search = { order_by: { updated_at: 'DESC' }, pagination }
    if (term || agentId) {
      search.search = {}
      if (term) search.search = { ...search.search, _global: `${term}` }
      if (agentId) search.search = { ...search.search, user_id: agentId }
    }

    return await LedgerFactory.search(search)
  } catch (ex) {
    console.error(`Failed to search agent wallets ${ex}.`)
    return { models: [], pagination: {} }
  }
}

const searchAgentLedgerTransactions = async ({ ledgerId, pagination }) => {
  pagination = pagination && typeof pagination === 'object' ? pagination : {}

  try {
    const search = {
      order_by: { created_at: 'DESC' },
      pagination,
    }
    if (ledgerId) {
      search.search = { ledger_id: ledgerId }
      return await LedgerTransactionFactory.search(search)
    }
    return { models: [], pagination: {} }
  } catch (ex) {
    console.error(`Failed to search agent wallet transactions ${ex}.`)
    return { models: [], pagination: {} }
  }
}

const getAgents = async (id) => {
  try {
    return (
      (
        await UserFactory.search({
          search: { id },
          pagination: { per_page: id.length },
        })
      )?.models || []
    )
  } catch (ex) {
    console.error(`Failed to search agents ${ex}.`)
    return []
  }
}

const getAgentLedger = async (Agent) => {
  const agentId = Agent && Agent?.id()
  if (!isNaN(agentId)) {
    return new Promise(async (resolve, reject) => {
      const { models } = await searchAgentLedgers({ agentId })
      resolve((models || []).shift())
    })
  }
}

const updateAgentsWithLedgers = async (Ledgers, Agents) => {
  const agentIds = Ledgers.map((AL) => AL.get('user_id'))
    .filter((agentId) => agentId && !isNaN(agentId))
    .filter((agentId) => !Agents.hasOwnProperty(agentId))
    .filter((val, idx, self) => self.indexOf(val) === idx)

  if (agentIds.length)
    (await getAgents(agentIds)).forEach((Agent) => (Agents[Agent.id()] = Agent))
}

const exportReport = async ({
  category,
  report,
  start,
  stop,
  coverages,
  orderBy,
  search,
}) => {
  let result
  try {
    result = await ReportService.export({
      category,
      report,
      start,
      stop,
      coverages,
      orderBy,
      search,
    })
  } catch (ex) {
    console.error(ex)
  }

  if (result && result.url) return result.url

  return false
}

class LedgersStore {
  TransferStore = TransferStore

  isLoading = false
  isSearching = false
  isSaving = false

  Ledger = null
  Ledgers = []
  LedgerTransactions = []
  Transaction = null
  transShadow = {}
  Agents = {}
  AgentResults = []
  Doners = {}
  WalletMeta = null
  canAccessCashWithdrawal = null

  updatingUpcomingExpirations = false
  upcomingExpirations = {}
  ledgerPagination = {}
  ledgerTransactionPagination = {}

  isRollingUp = false
  isApproveWithheld = false
  searchTerm = ''
  searchAgentId = null

  constructor() {
    makeAutoObservable(this)
  }

  _initAdmin = async () => {
    this.isLoading = true

    const { models, pagination } = await searchAgentLedgers({})
    this.Ledgers = models
    this.ledgerPagination = pagination

    if (Array.isArray(models)) {
      await updateAgentsWithLedgers(models, this.Agents)
    }

    this.isLoading = false
    this.updateUpcomingExpirations()
  }

  _initAgent = async () => {
    this.isLoading = true

    const User = await UserProfileService.instance(),
      Ledger = ((await User.ledger(true).all()) || []).shift()

    this.setCanUserAccessCashWithdrawal(User)

    this.Agents[User.id()] = User
    this.Ledger = Ledger

    if (this.Ledger && this.Ledger.isNew() === false) {
      const Response = await searchAgentLedgerTransactions({
        ledgerId: this.Ledger.id(),
        pagination: { per_page: 50 },
      })
      this.LedgerTransactions = Response.models
      this.ledgerTransactionPagination = Response.pagination
    } else {
      this.LedgerTransactions = []
      this.ledgerTransactionPagination = {}

      this.createAgentLedger(User)
    }

    this.defineTransactionSources()
    this.updateUpcomingExpirations()
    this.isLoading = false
  }

  init = async (initType) => {
    if (initType === 'admin') return this._initAdmin()
    else if (initType === 'agent') return this._initAgent()
  }

  defineTransactionSources = async () => {
    const donerIds = this.LedgerTransactions.filter(
      (LT) => LT.get('doner_id') !== null && !isNaN(LT.get('doner_id'))
    )
      .map((LT) => parseInt(LT.get('doner_id')))
      .filter((val, idx, self) => self.indexOf(val) === idx)
    if (donerIds.length) {
      const userIds = {}

      ;(
        (await LedgerFactory.search({
          search: { id: donerIds },
          pagination: false,
        })) || []
      ).forEach((Ledger) => (userIds[Ledger.get('user_id')] = Ledger.id()))

      if (Object.keys(userIds).length)
        (
          (await UserFactory.search({
            search: { id: Object.keys(userIds) },
            pagination: false,
          })) || []
        ).forEach((Doner) => (this.Doners[userIds[Doner.id()]] = Doner))
    }
  }

  updateUpcomingExpirations = async () => {
    if (this.Ledger && this.Ledger?.isNew() === false) {
      this.updatingUpcomingExpirations = true
      const upcomingExpirations = await this.Ledger.getUpcomingExpirations()
      this.upcomingExpirations = Object.values(upcomingExpirations).length
        ? upcomingExpirations
        : true
      this.updatingUpcomingExpirations = false
    }
  }

  toggleCanUserAccessCashWithdrawal = async () => {
    const metaValue = this.WalletMeta?.get('meta_value')
      ? JSON.parse(this.WalletMeta.get('meta_value'))
      : false
    if (metaValue) {
      metaValue.current = parseInt(metaValue.current) === 1 ? 0 : 1
      metaValue.history.push({
        current: metaValue.current,
        change_at: moment().utc().toISOString(),
        change_by: UserProfileService.getUserId(true),
      })

      this.WalletMeta.set('meta_value', JSON.stringify(metaValue))
      this.WalletMeta.save()
      this.canAccessCashWithdrawal = parseInt(metaValue.current) === 1
    }
  }

  setCanUserAccessCashWithdrawal = async (User) => {
    this.canAccessCashWithdrawal = false
    this.WalletMeta = null
    if (User) {
      let WalletMeta = (
        await User.meta(true).key('wallet---permit-cash-withdrawal')
      ).shift()
      this.WalletMeta = WalletMeta
        ? WalletMeta
        : await User.meta(true).create({
            meta_key: 'wallet---permit-cash-withdrawal',
          })
    }

    if (this.WalletMeta) {
      let metaValue = this.WalletMeta?.get('meta_value')
        ? this.WalletMeta.get('meta_value')
        : null
      metaValue =
        metaValue && typeof metaValue === 'string'
          ? JSON.parse(metaValue)
          : metaValue && typeof metaValue === 'object'
          ? metaValue
          : null
      metaValue = !metaValue ? { current: 0, history: [] } : metaValue

      if (
        !this.WalletMeta.get('meta_value') ||
        this.WalletMeta.get('meta_value') !== JSON.stringify(metaValue)
      )
        this.WalletMeta.set('meta_value', JSON.stringify(metaValue))

      if (parseInt(metaValue?.current) === 1)
        this.canAccessCashWithdrawal = true
    }
  }

  createAgentLedger = async (Agent) => {
    this.LedgerTransactions = []
    const Ledger = await LedgerFactory.create({
      balance: 0,
      notes: `[{"message":"Wallet created.", "user_id": "${UserProfileService.get(
        'id',
        true
      )}", "author": "${[
        UserProfileService.get('u_fname', true),
        UserProfileService.get('u_lname', true),
      ]
        .join(' ')
        .trim()}", "timestamp": "${moment().utc().toISOString()}"}]`,
    })

    if (Agent) {
      Ledger.set('user_id', Agent.id())
      await Ledger.save()
      this.Agents[Agent.id()] = Agent
      this.Ledgers.push(Ledger)
    }

    this.Ledger = Ledger
    return Ledger
  }

  searchAgentLedgers = async ({ pagination, callback }) => {
    this.isSearching = true

    let search = {}
    if (this.searchAgentId) search.agentId = this.searchAgentId
    else if (this.searchTerm) search.term = this.searchTerm

    const Response = await searchAgentLedgers({ ...search, pagination })

    this.Ledgers = Response.models
    this.ledgerPagination = Response.pagination

    if (Array.isArray(Response.models)) {
      await updateAgentsWithLedgers(Response.models, this.Agents)
    }

    this.isSearching = false
  }

  searchAgents = async (searchTerm) => {
    this.isLoading = true
    this.AgentResults =
      (await UserFactory.search({ search: { _global: searchTerm } }))?.models ||
      []
    this.isLoading = false
  }

  searchAgentLedgerTransactions = async ({ pagination }) => {
    this.isSearching = true
    const params = { ledgerId: this.Ledger.id(), pagination },
      Response = await searchAgentLedgerTransactions(params)
    this.LedgerTransactions = Response.models
    this.ledgerTransactionPagination = Response.pagination
    this.isSearching = false
  }

  findAgentById = async (agentId) => {
    const Agents = await getAgents(agentId)
    if (Agents && Agents.length > 0) return Agents.shift()
    return
  }

  findLedgerById = async (ledgerId) => {
    return await LedgerFactory.findById(ledgerId)
  }

  findOrCreateLedgerByAgentId = async (agentId) => {
    const Agent = await this.findAgentById(agentId)
    if (Agent) return await this.findOrCreateLedgerByAgent(Agent)
  }

  findOrCreateLedgerByAgent = async (Agent) => {
    if (Agent) this.AgentResults = []

    // 1. attempt to fetch agent ledger.
    const Ledger = await getAgentLedger(Agent)

    if (Ledger) return Ledger

    return await this.createAgentLedger(Agent)
  }

  // Agents
  getAgentNameByLedger = (Ledger) => {
    if (Ledger && this.Agents[Ledger.get('user_id')])
      return [
        this.Agents[Ledger.get('user_id')].get('u_fname'),
        this.Agents[Ledger.get('user_id')].get('u_lname'),
      ]
        .filter((n) => n)
        .join(' ')
        .trim()
    return ''
  }

  getAgentEmailByLedger = (Ledger) => {
    if (Ledger && this.Agents[Ledger.get('user_id')])
      return this.Agents[Ledger.get('user_id')].get('u_email')
    return ''
  }

  edit = async (Ledger) => {
    if (this.Ledger && parseInt(this.Ledger?.id()) === parseInt(Ledger?.id()))
      return

    this.isLoading = true
    this.upcomingExpirations = {}
    this.Ledger = Ledger
    this.LedgerTransactions =
      Ledger.id() > 0 && !Ledger.isNew()
        ? (await searchAgentLedgerTransactions({ ledgerId: Ledger.id() }))
            ?.models || []
        : []
    this.isLoading = false

    this.updateUpcomingExpirations()
  }

  editTransaction = (Transaction) => {
    if (this.TransferStore) this.TransferStore.edit(Transaction)
    else this.Transaction = Transaction
  }

  rollupTransaction = async (Transaction) => {
    if (Transaction?.get('trans_state') === 'PENDING') {
      this.isRollingUp = Transaction.id()
      try {
        await Transaction.rollup()
      } catch (ex) {
        this.isRollingUp = false
        throw new Error(`${ex}`)
        return
      }

      await this.searchAgentLedgerTransactions({
        pagination: this.ledgerTransactionPagination,
      })
      const Ledger = this.Ledger
      // await Ledger.updateBalance()
      await Ledger.reload()
      this.Ledger = Ledger
      this.isRollingUp = null
    }
  }

  approveWithheldTransaction = async (Transaction) => {
    this.isApproveWithheld = Transaction.id()
    try {
      await Transaction.approveWithheld()
    } catch (ex) {
      this.isApproveWithheld = false
      throw new Error(`${ex}`)
      return
    }

    await this.searchAgentLedgerTransactions({
      pagination: this.ledgerTransactionPagination,
    })
    const Ledger = this.Ledger
    // await Ledger.updateBalance()
    await Ledger.reload()
    this.Ledger = Ledger
    this.isApproveWithheld = null
  }

  exportReport = exportReport

  end = () => {
    this.isLoading = false
    this.isSearching = false
    this.isSaving = false

    this.Ledger = null
    this.Ledgers = []
    this.LedgerTransactions = []
    this.Transaction = null
    this.transShadow = {}
    this.Agents = {}
    this.AgentResults = []
    this.Doners = {}
    this.WalletMeta = null
    this.canAccessCashWithdrawal = null

    this.updatingUpcomingExpirations = false
    this.upcomingExpirations = {}
    this.ledgerPagination = {}
    this.ledgerTransactionPagination = {}

    this.searchTerm = ''
    this.searchAgentId = null

    this.TransferStore.end()
  }
}

export default new LedgersStore()
