import React, { useState, useEffect, lazy, Suspense } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { UIDropdown } from './../../components/forms/form-fields'
import Store from './CRM.store'

// import './ContactList.component.scss'

const ContactList = () => {
  const { Location, fetchContactsByLocation } = Store,
    locationId = Location?.id(),
    isDisabled = !Store.Locations || !Store.Locations.length,
    options = toJS(Store.Locations || []).sort((a, b) =>
      a.get('name').localeCompare(b.get('name'))
    )

  useEffect(() => {
    fetchContactsByLocation(locationId)
  }, [locationId])

  return (
    <table>
      <tbody>
        <tr>
          <td>
            ContactList
            {/*      <UIDropdown
        label="My 10X Location"
        name="location_id"
        disabled={isDisabled}
        className="location-selector"
        options={options.map(L => ({
            text:     (<>{L.get('name') + ' '}<small>{L.id()}</small></>),
            value:    `${L.id()}`,
            checked:  (Store.Location?.id() && `${Store.Location?.id()}` === `${L.id()}`)
          }))}
        selected={Store.Location&&Store.Location?.id()}
        onChange={(evt) => Store.Location = Store.Locations.find(L => L.id() === evt.target.value)}
        required={true}
        value={Store.Location&&Store.Location?.id()}
        rules={{required: true}}
      />*/}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default observer(ContactList)
