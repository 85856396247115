import React, { useState, useEffect, lazy, Suspense } from 'react'
import env from './../../environments/environment'
import Loader from './../../components/Loader/Loader'
import PageHeading from './../../components/shared/PageHeading.component'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import { toast } from 'react-toastify'
import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBIcon,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBBtn,
  MDBInput,
} from 'mdbreact'
import CarrierSerivce from './../../shared/services/Carrier.service'
import LocationSerivce from './../../shared/services/Location.service'
import PaginationComponent from './../../components/adminShared/Pagination.component'
import LocationSelector from './LocationSelector.component'
import ContactSelector from './ContactSelector.component'
import ContactForm from './ContactForm.component'

import Store from './CRM.store'

// import React, { useState, useEffect, lazy, Suspense } from 'react'
// import AdminApi from '../api/admin-api/admin-api'
// import StorageService from './../shared/services/Storage.service'
// import { toast } from 'react-toastify'

// const Navbar = lazy(() => import('../components/newhome/Navbar'))

import './CRM.page.scss'

const CRMPage = ({ location }) => {
  // const { init, isInitializing, isInitialized, Location } = Store
  const { init, isInitialized } = Store

  useEffect(() => {
    init()
  }, [isInitialized])

  return (
    <Suspense fallback={<Loader />}>
      <DashboardLayout>
        <main id="UserEventLogPage" className="mainSection pb-5">
          <MDBContainer fluid className="mt-5">
            <PageHeading label="Customers (CRM)" />
          </MDBContainer>
          <MDBContainer className="pb-5">
            <MDBRow>
              <MDBCol>
                <LocationSelector />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <ContactSelector />
              </MDBCol>
              <MDBCol>
                <ContactForm />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </main>
      </DashboardLayout>
    </Suspense>
  )
}

export default CRMPage
