import React, { useState, useEffect, lazy, Suspense } from 'react'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'
import { toJS } from 'mobx'
import { UIInput } from './../../components/forms/form-fields'
import ContactList from './ContactList.component'
import ContactSearch from './ContactSearch.component'
import CreateContactBtn from './CreateContactBtn.component'
import Store from './CRM.store'
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
} from 'mdbreact'

// import './ContactForm.component.scss'

const input = (Contact, field, validity, setValidity) => {
  let label = '',
    rules = {},
    required = false

  switch (field) {
    case 'first_name':
      label = 'First Name'
      required = true
      rules = {
        required: true,
        minLength: 2,
        maxLength: 255,
        format: 'name',
      }
      break
    case 'last_name':
      label = 'Last Name'
      required = true
      rules = {
        required: true,
        minLength: 2,
        maxLength: 255,
        format: 'name',
      }
      break
    case 'email':
      label = 'Email Address'
      required = false
      rules = {
        required: false,
        minLength: 2,
        maxLength: 255,
        format: 'email',
      }
      break
    case 'phone':
      label = 'Phone Number'
      required = false
      rules = {
        required: false,
        minLength: 2,
        maxLength: 255,
        format: 'phone',
      }
      break
    default:
      break
  }

  return (
    <UIInput
      label={label}
      name={field}
      type="text"
      value={Contact.get(field) || ''}
      onChange={(evt) => Contact.set(field, evt.target.value)}
      rules={rules}
      required={required}
      showValidity={validity.show}
      onValidityChange={(v) => setValidity({ ...validity, ...{ [field]: v } })}
    />
  )
}

const ContactForm = () => {
  const { Contact } = Store,
    locationId = Contact && Contact?.all()?.location_id,
    [validity, setValidity] = useState({ show: false })

  if (!Contact || !locationId) return <></>

  const onSubmit = async (evt) => {
    evt.preventDefault()

    const invalid = Object.keys(validity).filter(
      (key) =>
        typeof validity[key] === 'object' && validity[key]?.isValid !== true
    )
    if (invalid && invalid.length)
      return setValidity({ ...validity, show: true })

    try {
      await Contact.save()
    } catch (ex) {
      toast.error(`${ex}`)
      return false
    }

    return false
  }

  return (
    <MDBCard id="ContactFormComponent">
      <MDBCardHeader>
        <MDBRow>
          <MDBCol>Customer Profile</MDBCol>
          <MDBCol>
            <MDBBtn className="close" onClick={() => (Store.Contact = null)}>
              <MDBIcon icon="times" />
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBCardHeader>
      <MDBCardBody>
        <form className="container" onSubmit={(evt) => onSubmit(evt)}>
          <MDBRow>
            <MDBCol>
              {input(Contact, 'first_name', validity, setValidity)}
            </MDBCol>
            <MDBCol>
              {input(Contact, 'last_name', validity, setValidity)}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>{input(Contact, 'email', validity, setValidity)}</MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>{input(Contact, 'phone', validity, setValidity)}</MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <MDBBtn type="submit">Save Contact</MDBBtn>
            </MDBCol>
          </MDBRow>
        </form>
      </MDBCardBody>
    </MDBCard>
  )
}

export default observer(ContactForm)

// 'first_name',
// 'last_name',
// 'email',
// 'phone',
// 'dnd',
// 'type',
// 'source',
// 'created_at',
// 'updated_at',
