import React, { Component, Fragment } from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBInput,
  MDBSelect,
  MDBDatePicker,
} from 'mdbreact'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import { toast } from 'react-toastify'
import AdminApi from './../../api/admin-api/admin-api'
import DateService from './../../shared/services/Date.service'
import appConstants from './../../constants/appConstants'
import {
  phoneToStorage,
  phoneToView,
} from './../../shared/formatters/telephone.formatter'

import './AddProspectPage.scss'
import NoteListComponent from './NoteList/NoteList'

const StatesList = appConstants.States.map((t) => {
  return { text: t.name, value: t.id }
})

class AddProspectPage extends Component {
  state = {
    loading: false,
    fetching: false,
    submitting: false,
    breakWidth: 1300,
    windowWidth: 0,
    userData: {},
    pro_fname: '',
    pro_lname: '',
    pro_email: '',
    pro_phone: '',
    pro_mobile: '',
    pro_address: '',
    pro_city: '',
    pro_state: '',
    pro_zip: '',
    pro_notes: '',
    pro_claimed_on: new Date(),
    urlParams: {},
    errorsExist: false,
    isSetEmail: false,
  }

  #errors = {}

  #renderErrorField = (field) => {
    if (field && this.#errors.hasOwnProperty(field) && this.state.errorsExist)
      return <span className="error-msg">{this.#errors[field]}</span>
    return <></>
  }

  componentDidMount() {
    this.fetchCurrentUserdata()
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params })

    if (params && params.id) {
      this.fetchProspectsData(params.id)
    }
  }

  fetchCurrentUserdata = async () => {
    this.setState({ fetching: true })
    AdminApi.getProfileData()
      .then((result) => {
        if (result && result.data && result.data.data) {
          this.setState({ userData: result.data.data })
        }
        this.setState({ fetching: false })
      })
      .catch((error) => {
        this.setState({ fetching: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchProspectsData = async (id) => {
    this.setState({ loading: true })
    AdminApi.getProspectById(id)
      .then((result) => {
        this.setState({ loading: false })
        if (result && result.data && result.data.data) {
          // const states = StatesList.map((item)=> {
          //     return {...item, 'checked': item.value === result.data.data.pro_state}
          // })StatesList

          const data = result.data.data
          this.setState({
            pro_fname: data.pro_fname,
            pro_email: data.pro_email,
            pro_phone: data.pro_phone
              ? phoneToView(data.pro_phone)
              : data.pro_phone,
            pro_address: data.pro_address,
            pro_city: data.pro_city,
            pro_state: data.pro_state,
            pro_zip: data.pro_zip,
            pro_claimed_on: DateService.dateOnly(data.pro_claimed_on),
            pro_notes: data.pro_notes,
          })

          // console.log("Claimed Date: ",data.pro_claimed_on,DateService.dateOnly(data.pro_claimed_on));
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  #validate = () => {
    this.#errors = {}

    const { pro_fname, pro_email, pro_phone, pro_claimed_on } = this.state,
      regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      regPhone = /^(1(-|\s)?)?\(?\d{3}\)?(-|\s)?\d{3}(-|\s)?\d{4}$/

    if (!pro_fname || !pro_fname.trim())
      this.#errors.pro_fname = 'Prospect name required'
    else if (pro_fname.trim().indexOf(' ') < 1)
      this.#errors.pro_fname = 'Prospect first & last name required'

    if (!pro_email || !pro_email.trim())
      this.#errors.pro_email = 'Prospect Email required'
    else if (!regEmail.test(pro_email))
      this.#errors.pro_email = 'Invalid Email pattern'

    if (!pro_phone || !pro_phone.trim())
      this.#errors.pro_phone = 'Prospect phone required'
    else if (!regPhone.test(pro_phone))
      this.#errors.pro_phone = 'Invalid phone number'

    if (!pro_claimed_on) this.#errors.pro_phone = 'Claimed Date required'

    if (Object.keys(this.#errors).length) {
      this.setState({ errorsExist: true })
      return false
    }

    this.setState({ errorsExist: false })
    return true
  }

  #createProspects = async () => {
    const {
        pro_fname,
        pro_email,
        pro_phone,
        pro_address,
        pro_city,
        pro_state,
        pro_zip,
        pro_claimed_on,
        pro_notes,
        userData,
      } = this.state,
      id = this.state.urlParams.id
    let data = {
      recruiter_id: userData.id,
      pro_recruiter: `${userData.u_fname} ${userData.u_lname}`,
      pro_fname: pro_fname,
      pro_email: pro_email,
      pro_phone: pro_phone ? phoneToStorage(pro_phone) : pro_phone,
      pro_claimed_on: DateService.dateOnlyToString(pro_claimed_on),
      table_name: 'prospects',
    }

    if (id) data.id = id
    if (pro_address) data.pro_address = pro_address
    if (pro_city) data.pro_city = pro_city
    if (pro_state) data.pro_state = pro_state
    if (pro_zip) data.pro_zip = pro_zip
    if (pro_notes) data.pro_notes = pro_notes

    const form_data = new FormData()

    for (let key in data) form_data.append(key, data[key])

    AdminApi.createProspect(form_data)
      .then((result) => {
        this.setState({ submitting: false })
        this.props.history.goBack()
        toast.success(`Prospect ${id ? 'Updated' : 'Added'} successfully`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ submitting: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  #validEmail = () => {
    this.#errors = {}

    const { pro_email } = this.state,
      regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

    if (!pro_email || !pro_email.trim())
      this.#errors.pro_email = 'Prospect Email required'
    else if (!regEmail.test(pro_email))
      this.#errors.pro_email = 'Invalid Email pattern'

    if (Object.keys(this.#errors).length) {
      this.setState({ errorsExist: true })
      return false
    }

    this.setState({ errorsExist: false })
    return true
  }

  #fetchNotes = () => {
    if (!this.#validEmail()) return
    this.#errors = {}
    this.setState({ isSetEmail: true, errorsExist: false })
  }

  #handleSubmit = async () => {
    if (this.state.loading || this.state.submitting || !this.#validate()) return

    this.setState({ submitting: true })

    AdminApi.isProspectExist({ email: this.state.pro_email })
      .then((result) => this.#createProspects())
      .catch((error) => {
        this.setState({ submitting: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()
    const {
      loading,
      submitting,
      pro_fname,
      pro_email,
      pro_phone,
      pro_address,
      pro_city,
      pro_state,
      pro_zip,
      pro_claimed_on,
      pro_notes,
    } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main id="AddProspectPage" className="mainSection">
            {loading ? (
              <h2>Loading...</h2>
            ) : (
              <MDBContainer fluid className="mt-5">
                <MDBBreadcrumb>
                  <MDBBreadcrumbItem>
                    <a href="/prospects">List</a>
                  </MDBBreadcrumbItem>
                  <MDBBreadcrumbItem active>Add Prospect</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <h2>Add Prospect</h2>
                <hr />
                <MDBRow>
                  <MDBCol lg="4">
                    <form className="content-wrapper">
                      {!this.state.isSetEmail ? (
                        <>
                          <MDBInput
                            label="Email"
                            id={'pro_email'}
                            value={pro_email || ''}
                            getValue={(value) => {
                              this.setState({ pro_email: value })
                            }}
                          />
                          {this.#renderErrorField('pro_email')}
                          <div className="text-right mt-4">
                            <MDBBtn
                              color="unique"
                              type="button"
                              onClick={this.#fetchNotes}
                            >
                              Continue
                            </MDBBtn>
                          </div>
                        </>
                      ) : (
                        <>
                          <MDBInput
                            label="Name"
                            id={'pro_name'}
                            value={pro_fname || ''}
                            getValue={(value) => {
                              this.setState({ pro_fname: value })
                            }}
                          />
                          {this.#renderErrorField('pro_fname')}

                          {/*<MDBInput label="Name"
                                                      id={pro_lname}
                                                      value={pro_lname || ''}
                                                      getValue={(value) => {this.setState({pro_lname: value})}} />*/}

                          <MDBInput
                            label="Email"
                            id={'pro_email'}
                            value={pro_email || ''}
                            disabled={true}
                            getValue={(value) => {
                              this.setState({ pro_email: value })
                            }}
                          />
                          {this.#renderErrorField('pro_email')}

                          <MDBInput
                            label="Phone"
                            id="pro_phone"
                            value={pro_phone || ''}
                            getValue={(value) => {
                              this.setState({ pro_phone: value })
                            }}
                          />
                          {this.#renderErrorField('pro_phone')}

                          <MDBInput
                            label="Address"
                            id="pro_address"
                            value={pro_address || ''}
                            getValue={(value) => {
                              this.setState({ pro_address: value })
                            }}
                          />

                          <MDBInput
                            label="City"
                            id="pro_city"
                            value={pro_city || ''}
                            getValue={(value) => {
                              this.setState({ pro_city: value })
                            }}
                          />

                          <MDBSelect
                            options={StatesList.map((item) => ({
                              ...item,
                              checked: item.value === this.state.pro_state,
                            }))}
                            value={pro_state || 'Choose your State'}
                            selected={pro_state || 'Choose your State'}
                            getValue={(value) => {
                              if (value[0] !== this.state.pro_state)
                                this.setState({ pro_state: value[0] })
                            }}
                            label="State"
                            search
                          />

                          <MDBInput
                            label="Zip"
                            id="pro_zip"
                            value={pro_zip || ''}
                            getValue={(value) => {
                              this.setState({ pro_zip: value })
                            }}
                          />

                          <label htmlFor="pro_claimed_on">Claimed Date</label>
                          <MDBDatePicker
                            value={pro_claimed_on}
                            format="MM/DD/YYYY"
                            name="pro_claimed_on"
                            id="pro_claimed_on"
                            getValue={(value) => {
                              this.setState({ pro_claimed_on: value })
                            }}
                          />
                          {this.#renderErrorField('pro_claimed_on')}

                          <MDBInput
                            type="textarea"
                            label="Notes"
                            id="pro_notes"
                            value={pro_notes || ''}
                            getValue={(value) => {
                              this.setState({ pro_notes: value })
                            }}
                          />
                          <MDBRow className="mt-4">
                            <MDBCol lg="6">
                              <div>
                                <MDBBtn
                                  color="light"
                                  type="button"
                                  onClick={() => {
                                    this.setState({ isSetEmail: false })
                                  }}
                                >
                                  Reset
                                </MDBBtn>
                              </div>
                            </MDBCol>
                            <MDBCol lg="6">
                              <div className="text-right">
                                <MDBBtn
                                  color="unique"
                                  type="button"
                                  onClick={this.#handleSubmit}
                                >
                                  {submitting ? 'Saving...' : 'Save'}
                                </MDBBtn>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </>
                      )}
                    </form>
                  </MDBCol>
                  {this.state.isSetEmail ? (
                    <MDBCol lg="4">
                      <NoteListComponent
                        email={this.state.pro_email}
                        onSelect={(note) => {
                          this.setState({ pro_notes: note })
                        }}
                      />
                    </MDBCol>
                  ) : (
                    <></>
                  )}
                </MDBRow>
              </MDBContainer>
            )}
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default AddProspectPage
