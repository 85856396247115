import React, { Component } from 'react'
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardFooter,
  MDBAlert,
  MDBCollapse,
} from 'mdbreact'
import { toast } from 'react-toastify'
import stripePrices from './../../../../constants/stripePrices.constants'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import UserCarrierService from './../../../../shared/services/UserCarrier.service'
import UserMetaService from './../../../../shared/services/UserMeta.service'
import CarrierService from './../../../../shared/services/Carrier.service'
import DateService from './../../../../shared/services/Date.service'
import ContentService from './../../../../shared/services/Content.service'
import AgentTermAcceptance from './../../../../components/agents/AgentTerms/AgentTermAcceptance.component'
import { setGroupEandO } from './../../../../shared/utilities/setGroupEandO.function'
import BillingService from './../../shared/Billing.service'
import { Subscription } from 'rxjs'
import SyncSubscription from './../SyncSubscription/SyncSubscription.component'
import { DowngradeModal } from './components/DowngradeModal/DowngradeModal'
import { getIp } from './../../../../shared/utilities/getIp.function'
import { UIInput } from '../../../../components/forms/form-fields'
import PromoteCodeService from '../../../../shared/services/PromotionCode.service'
import UserUserTypesService from '../../../../shared/services/UserUserTypes.service'

import './SubscriptionPackage.scss'

const GRACE_PERIOD_DAYS = 1,
  SIG_AGENT_ONE_TIME = 50,
  SIG_AGENT_RECURRING = 49.95

const acceptTerms = async () => {
  const User = await UserProfileService.instance()
  if (User) {
    try {
      return await User.signTerms({
        term_slugs: [
          'sig-agent-terms',
          'sig-producer-eo',
          'non-captive-non-solicit',
          'subscriber-agreement',
        ],
        accepted_ip: await getIp(),
      })
    } catch (ex) {}
  }

  return false
}

class SubscriptionPackage extends Component {
  state = {
    showDowngradeModal: false,
    downgradeTo: null,
    downgradeContent: {},
    confirmUpgradeAccount: false,
    subSource: null,

    confirmModal: false,
    modifying: false,
    subscriptionId: null,
    subscription: {},
    paymentMethodValid: false,

    adminFeeWaived: false,
    promotecode: null,
    lastUserTypeHistory: null,
  }

  __subscriptions$ = new Subscription()
  _customerId = null
  _customer = null

  _showStripeIds = UserProfileService.isA(
    ['internal-staff', 'internal-admin', 'system-admin', 'agency-owner'],
    true
  )

  get activeSubscriptionId() {
    return this.status === 'active' && this.state.subscriptionId
  }

  get isOnGrace() {
    if (this.state.adminFeeWaived) return true

    if (GRACE_PERIOD_DAYS > 0) {
      return [111, 133, 223, 224].includes(this.state.lastUserTypeHistory?.usertype_id) 
        && this.state.lastUserTypeHistory?.changed_at
        ? new Date().getTime() / 1000 <
            new Date(this.state.lastUserTypeHistory?.changed_at).getTime() / 1000 +
              GRACE_PERIOD_DAYS * 86400
        : false
    }

    return false
  }

  get status() {
    // incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid

    if (BillingService.isActive()) return 'active'
    if (BillingService.isInactive()) return 'inactive'
    if (BillingService.isCanceled()) return 'canceled'
    if (BillingService.isPastDue()) return 'past due'

    return null
  }

  _renderUpdateDowngradeBtn = () => {
    if (
      this.state.subSource &&
      this.state.subSource?.merchant !== 'stripe' &&
      !this.state.subSource?.canceled_at
    )
      return (
        <MDBBtn
          disabled={this.state.modifying}
          className="btn-block text-center"
          onClick={() => this._confirmDowngradeAccount()}
        >
          {this.state.modifying ? (
            <>
              <span>
                <i className="fa fa-spin fa-spinner" />
              </span>
              &nbsp;<span>Canceling...</span>
            </>
          ) : (
            <>
              <span>Cancel Signature Agent Subscription</span>
            </>
          )}
        </MDBBtn>
      )

    // UPGRADE ------------------------------------------------
    // Affiliates & Associates ONLY.
    if (
      UserProfileService.isA([
        'affiliate-agent',
        'affiliate-leader',
        'associate-agent',
        'associate-leader',
      ])
    ) {
      if (
        this.state.subSource &&
        this.state.subSource?.merchant !== 'stripe' &&
        !this.state.subSource?.canceled_at
      )
        return (
          <MDBBtn
            disabled={this.state.modifying}
            className="btn-block text-center"
            onClick={() => this._confirmDowngradeAccount()}
          >
            {this.state.modifying ? (
              <>
                <span>
                  <i className="fa fa-spin fa-spinner" />
                </span>
                &nbsp;<span>Canceling...</span>
              </>
            ) : (
              <>
                <span>Cancel Signature Agent Subscription</span>
              </>
            )}
          </MDBBtn>
        )

      if (this.activeSubscriptionId === false) {
        // 1. there is no active subscription.
        // 2. there is a valid payment method.
        // 3. if payment methods are valid, enable upgrade option.
        if (this.state.paymentMethodValid)
          return (
            <MDBBtn
              disabled={this.state.modifying}
              className="btn-block text-center"
              color="indigo"
              onClick={() => this._subscribeToSigAgent()}
            >
              {this.state.modifying ? (
                <>
                  <span>
                    <i className="fa fa-spin fa-spinner" />
                  </span>
                  &nbsp;<span>Upgrading...</span>
                </>
              ) : (
                <>
                  <span>
                    {(this.state.confirmUpgradeAccount
                      ? 'Accept & Confirm '
                      : '') + 'Upgrade to Signature Agent'}
                  </span>
                </>
              )}
            </MDBBtn>
          )
      } else {
        return (
          <MDBBtn
            disabled={this.state.modifying}
            className="btn-block text-center"
            onClick={() => this._confirmDowngradeAccount()}
          >
            {this.state.modifying ? (
              <>
                <span>
                  <i className="fa fa-spin fa-spinner" />
                </span>
                &nbsp;<span>Canceling...</span>
              </>
            ) : (
              <>
                <span>Cancel Signature Agent Subscription</span>
              </>
            )}
          </MDBBtn>
        )
      }
    }

    // DOWNGRADE ----------------------------------------------
    // Sig Agent Variants ONLY.
    else if (
      UserProfileService.isA([
        'limited-to-contracting-signature-agent',
        'limited-to-training-signature-agent',
        'signature-agent-in-need-of-pic',
        'signature-agent-complete',
        'career-agent',
        'premier-agent',
      ])
    ) {
      // Permit the downgrade button for all Sig Agents, regardless of whether or not
      // we show an active subscription on file.  The downgrade notification will
      // go to BST where they can confirm/reconcile.

      // if (this.state.subscriptionId || (this.state.subSource && this.state.subSource?.merchant !== 'stripe' && !this.state.subSource?.canceled_at)) {
      // The Downgrade block below was listed inside this if block.
      // }

      // Deny Upline managers the ability to downgrade their agents.
      // Agents must downgrade themselves.
      if (UserProfileService.isAssumed()) {
        if (
          !UserProfileService.isA(
            ['agency-owner', 'system-admin', 'internal-admin'],
            true
          )
        )
          return <></>
      }

      // Downgrade button.
      // Downgrade to Affiliate Agent
      return (
        <MDBBtn
          disabled={this.state.modifying}
          className="btn-block text-center"
          onClick={() => this._confirmDowngradeAccount()}
        >
          {this.state.modifying ? (
            <>
              <span>
                <i className="fa fa-spin fa-spinner" />
              </span>
              &nbsp;<span>Canceling...</span>
            </>
          ) : (
            <>
              <span>Cancel Signature Agent Subscription</span>
            </>
          )}
        </MDBBtn>
      )
    } else if (UserProfileService.isA(['agent'])) {
      // contact BST to downgrade your subscriptions.
      return (
        <MDBAlert className="text-center" color="secondary">
          You have a <strong>leadership</strong> usertype.
          <br />
          Please contact Broker Support for further assistance.
        </MDBAlert>
      )
    } else {
      // contact BST to downgrade your subscriptions.
      return (
        <MDBAlert className="text-center" color="secondary">
          You have an <strong>advanced</strong> usertype.
          <br />
          Please contact Broker Support for further assistance.
        </MDBAlert>
      )
    }
  }

  _subscribeToSigAgent = async () => {
    if (!this.state.confirmUpgradeAccount)
      return this.setState({ confirmUpgradeAccount: true })

    this.setState({ modifying: true })
    // 1. creates subscription.
    // 2. updates usertype.
    // 3. notifies upline.
    let prices = this.isOnGrace
      ? { items: [stripePrices.recurring] }
      : { items: [stripePrices.recurring], add_items: [stripePrices.one_time] }
    try {
      await BillingService.createSubscription(this._customerId, prices)
    } catch (ex) {
      this.setState({ modifying: false })
      return toast.error(`${ex}`)
    }

    acceptTerms()

    if (!UserProfileService.isAssumed())
      await UserProfileService.fetchAndStoreUserDetails()

    // Generate E&O policy upon upgrade.
    // (Policy wont be generate if already exists).
    setGroupEandO()
    this._fetchLastUserTypeHistory()

    toast.success('Great!  Your account has been upgraded.')

    this.setState({ modifying: false, confirmUpgradeAccount: false })
  }

  _confirmDowngradeAccount = () =>
    this.setState({ showDowngradeModal: !this.state.showDowngradeModal })

  _downgradeAccount = async (params) => {
    if (!params?.why_downgrade)
      return toast.error('Please complete: "Why are you downgrading today?"')
    if (!params?.what_better)
      return toast.error('Please complete: "What could USABG do better?"')

    let msg
    this.setState({
      // modifying: true,
      showDowngradeModal: false,
    })
    // 1. cancels subscription.
    // 2. updates usertype.
    // 3. notifies upline.

    if (
      !this.state.subscriptionId ||
      (this.state.subSource &&
        this.state.subSource?.merchant !== 'stripe' &&
        !this.state.subSource?.canceled_at)
    ) {
      // console.log("subSource: ",this.state.subSource)
      // Temp work-around while moving customers to Stripe from cart32/iBoom merchants.
      /*TODO:REMOVE*/
      if (UserProfileService.get('merchant_history', true))
        UserProfileService.set('merchant_history', '', true)

      msg = 'Broker Support has been notified to downgrade your account.'
      await BillingService.notifyBrokerSupport({
        userId: UserProfileService.getUserId(),
        ...params,
      })
    } else {
      msg = 'Your subscription has been canceled'
      if (
        UserProfileService.isA(['agent']) &&
        !UserProfileService.isA([
          'affiliate-agent',
          'affiliate-leader',
          'associate-agent',
          'associate-leader',
        ])
      )
        msg += ' and your account has been downgraded'

      await BillingService.cancelSubscription(this.state.subscriptionId)
      await BillingService.notifyBrokerSupport({
        userId: UserProfileService.getUserId(),
        ...params,
      })
    }

    if (!UserProfileService.isAssumed()) {
      await UserProfileService.fetchAndStoreUserDetails()
      let subSource = this.state.subSource ? this.state.subSource : {}
      subSource.canceled_at = new Date()
      this.setState({ subSource })
    }
    this._fetchLastUserTypeHistory()

    toast.success(`${msg}.`)
    this.setState({ modifying: false })
  }

  _renderSubscriptionAlert = () => {
    let status = this.status

    if (UserProfileService.isA('non-agent'))
      return (
        <MDBAlert className="text-center" color="warning">
          You do not have an <strong>Agent</strong> account.
          <br />
          You are inelligle for account subscriptions.
        </MDBAlert>
      )

    // handles off-stripe subscriptions (cart-32, cocard, iboomerang)
    if (
      this.state.subSource &&
      this.state.subSource?.merchant !== 'stripe' &&
      !this.state.subSource?.canceled_at
    )
      return (
        <MDBAlert className="text-center" color="success">
          Your agent subscription is <strong>active</strong>.
        </MDBAlert>
      )

    if (!status) {
      let customerExists = !!this._customer,
        paymentValid = !!this.state.paymentMethodValid

      if (!customerExists)
        return (
          <MDBAlert className="text-center" color="secondary">
            Preparing Account...
          </MDBAlert>
        )

      return (
        <>
          <MDBAlert className="text-center" color="warning">
            You do not have an active subscription.
          </MDBAlert>
          {paymentValid ? (
            <></>
          ) : (
            <MDBAlert className="text-center" color="danger">
              You must add a valid payment method before you can upgrade your
              subscription.
            </MDBAlert>
          )}
        </>
      )
    }

    if (status === 'canceled')
      return (
        <MDBAlert className="text-center" color="warning">
          Your subscription was <strong>canceled</strong> on{' '}
          <strong>
            {DateService.dateOnlyToString(
              this.state.subscription.canceled_at,
              'MM/DD/YYYY'
            )}
          </strong>
          .
        </MDBAlert>
      )

    if (status === 'active') {
      if (
        UserProfileService.isAssumed() &&
        (UserProfileService.isA('system-admin', true) ||
          UserProfileService.isA('internal-admin', true))
      )
        return (
          <MDBAlert className="text-center" color="success">
            Your Signature Agent subscription is <strong>{status}</strong> since{' '}
            <strong>
              {DateService.dateOnlyToString(
                this.state.subscription.started_at,
                'MM/DD/YYYY'
              )}
            </strong>
            .
          </MDBAlert>
        )
      return (
        <MDBAlert className="text-center" color="success">
          Your Signature Agent subscription is <strong>{status}</strong>.
        </MDBAlert>
      )
    }

    return (
      <MDBAlert
        className="text-center"
        color={
          status === 'active'
            ? 'success'
            : status === 'inactive'
            ? 'warning'
            : 'danger'
        }
      >
        Your Signature Agent subscription is <strong>{status}</strong>.
      </MDBAlert>
    )
  }

  _updateSubscriptionStatus = async () => {
    if (this._customerId) {
      let subscriptionId = await BillingService.fetchSubscriptionId(
        this._customerId
      )

      if (!subscriptionId) {
        if (!subscriptionId && this.state.subscriptionId !== false)
          this.setState({ subscriptionId: false })
        return
      }

      if (subscriptionId !== this.state.subscriptionId)
        BillingService.fetchSubscription(subscriptionId)
    }
  }

  _setCustomer = async (customer) => {
    let idChanged =
      (customer && this._customerId !== customer.id) ||
      (!this._customerId && customer)
    this._customer = customer

    if (
      this.state.paymentMethodValid !==
      !!(
        this._customer &&
        this._customer.expands &&
        this._customer.expands.paymentMethod.length
      )
    )
      this.setState({ paymentMethodValid: !this.state.paymentMethodValid })

    if (idChanged) {
      this._customerId = customer && customer.id ? customer.id : null
      if (this._customerId) this._updateSubscriptionStatus()
    }
  }

  _setSubscription = async (subscription) =>
    this.setState({
      subscriptionId: subscription && subscription.id,
      subscription,
    })

  _fetchDowngradeContent = async () => {
    let contentId = null
    if (this.state.downgradeTo === 'associate-agent') contentId = 66
    if (this.state.downgradeTo === 'affiliate-agent') contentId = 67

    if (contentId > 0)
      ContentService.getPageById(contentId).then((res) =>
        this.setState({ downgradeContent: res })
      )
  }

  _determineDowngradeUsertype = async () => {
    let carriers = []
    try {
      carriers = await UserCarrierService.search({
        pagination: false,
        search: { user_id: UserProfileService.getUserId() },
      })
    } catch (ex) {}

    carriers =
      carriers && carriers?.models
        ? carriers.models.map((c) => c.carrier_id)
        : []

    if (carriers.length)
      carriers = await CarrierService.search({
        pagination: false,
        search: { id: carriers },
      })

    carriers = carriers && carriers?.models ? carriers.models : []

    this.setState(
      {
        downgradeTo:
          carriers.filter(
            (c) => !!(parseInt(c.c_required) + parseInt(c.c_preferred))
          ).length >= 5
            ? 'associate-agent'
            : 'affiliate-agent',
      },
      this._fetchDowngradeContent
    )
  }

  _fetchSubscriptionMerchant = async () => {
    let metas = await UserProfileService.getUserMetas(
      'billing---subscription-source'
    )

    if ((metas = metas && metas.shift()))
      this.setState({ subSource: JSON.parse(metas.meta_value) })
  }

  _fetchIsAdminFeeWaived = async () => {
    let today = new Date().toISOString().slice(0, 10),
      res = await UserMetaService.search({
        search: {
          user_id: UserProfileService.getUserId(),
          meta_key: `subscription---waive-admin-fee`,
        },
        orderBy: { created_at: 'DESC' },
      })
    res =
      res && Array.isArray(res?.models)
        ? res.models.filter(
            (r) => r.meta_key && r.meta_value.indexOf(today) > -1
          )
        : false

    if (!this.state.adminFeeWaived && res && res.length)
      this.setState({ adminFeeWaived: true })
    else if (this.state.adminFeeWaived && (!res || !res.length))
      this.setState({ adminFeeWaived: false })
  }

  async onSubmitPromoteCode(event) {
    event.preventDefault()
    const result = await PromoteCodeService.search({
      search: { code: this.state.promotecode },
    })

    if (result?.models?.length > 0) {
      const currentTime = new Date()
      // Get the timezone offset in minutes
      const timezoneOffset = currentTime.getTimezoneOffset()

      // Parse the start and end time from the provided string
      const startTime = new Date(result.models[0]?.start_at)
      const endTime = new Date(result.models[0]?.end_at)
      endTime.setDate(endTime.getDate() + 1)

      const convertedStartTime = new Date(
        startTime.getTime() + timezoneOffset * 60000
      )
      const convertedEndTime = new Date(
        endTime.getTime() + timezoneOffset * 60000
      )

      if (convertedEndTime > currentTime && convertedStartTime <= currentTime) {
        this.setState({ adminFeeWaived: true })
        toast.success('Promotion Code is valid.', {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Promotion Code is not valid.', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    } else {
      toast.error('Promotion Code is not valid.', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  _renderPromoteCodeElement = () => {
    let customerExists = !!this._customer

    return (
      <form
        noValidate
        onSubmit={(evt) => this.onSubmitPromoteCode(evt)}
        className="mt-4"
      >
        <div className="d-flex align-items-center justify-content-between">
          <UIInput
            label="Promotion Code"
            name="promote_code"
            className="promote-code"
            value={this.state.promotecode || ''}
            onChange={(evt) => this.setState({ promotecode: evt.target.value })}
            required={true}
            rules={{ required: true }}
            type="text"
          />

          <MDBBtn
            disabled={
              !this.state.promotecode ||
              this.state.isSendingPromoteCode ||
              !customerExists
            }
            color="primary"
            type="submit"
          >
            {this.state.isSendingPromoteCode ? (
              <i className="fa fa-spin fa-spinner" />
            ) : (
              <>Check Code</>
            )}
          </MDBBtn>
        </div>
      </form>
    )
  }

  _fetchLastUserTypeHistory = async () => {
    const result = (await UserUserTypesService.search({
      search: { user_id: UserProfileService.getUserId() },
      pagination: false,
      orderBy: { changed_at: 'DESC' },
    }))?.models.shift()

    this.setState({lastUserTypeHistory: result})
  }

  componentDidMount() {
    this._fetchSubscriptionMerchant()
    this._fetchIsAdminFeeWaived()

    // this._fetchUsertype();
    this.__subscriptions$.add(
      BillingService.getCustomer().subscribe((customer) =>
        this._setCustomer(customer)
      )
    )
    this.__subscriptions$.add(
      BillingService.getSubscription().subscribe((subscription) =>
        this._setSubscription(subscription)
      )
    )

    // get the number of contracts this user has
    // so we can appropriately downgrade their account.
    this._determineDowngradeUsertype()
    this._fetchLastUserTypeHistory()
  }

  componentWillUnmount() {
    this.__subscriptions$.unsubscribe()
  }

  render() {
    toast.configure()

    return (
      <React.Fragment>
        <MDBCard id="SubscriptionPackageComponent">
          <MDBCardHeader>Account Subscription</MDBCardHeader>
          <MDBCardBody>
            <h6>Subscription:</h6>
            {this._renderSubscriptionAlert()}
            <MDBCollapse isOpen={this.state.confirmUpgradeAccount}>
              <div className={'invoice ' + (this.isOnGrace ? ' on-grace' : '')}>
                <AgentTermAcceptance canAccept={false} />
                <table>
                  <tbody>
                    <tr>
                      <td />
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td>Signature Agent Fee</td>
                      <td />
                      <td>${SIG_AGENT_RECURRING.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Onboarding & Account Setup</td>
                      <td>WAIVED</td>
                      <td>${SIG_AGENT_ONE_TIME.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td />
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td>
                        <span
                          onClick={() =>
                            this.setState({ confirmUpgradeAccount: false })
                          }
                        >
                          Cancel Upgrade
                        </span>
                      </td>
                      <td>Total:</td>
                      <td>
                        $
                        {(
                          parseFloat(SIG_AGENT_RECURRING) +
                          (this.isOnGrace ? 0 : parseFloat(SIG_AGENT_ONE_TIME))
                        ).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </MDBCollapse>
            {this._renderUpdateDowngradeBtn()}
            {this._renderPromoteCodeElement()}
          </MDBCardBody>
          <MDBCardFooter>
            <DowngradeModal
              downgrade={this._downgradeAccount}
              toggle={this._confirmDowngradeAccount}
              pageName={this.state.downgradeContent?.page_name}
              content={this.state.downgradeContent?.content}
              showDowngradeModal={this.state.showDowngradeModal}
            />
          </MDBCardFooter>
        </MDBCard>
        {this.state.subSource &&
        [35, 36, 219, 220].indexOf(
          parseInt(UserProfileService.getCurrentUserTypeId(true))
        ) > -1 ? (
          <>
            <MDBRow>
              <MDBCol size="12" md="6" className="mt-1">
                <strong>Merchant:</strong> {this.state.subSource.merchant}
              </MDBCol>
              <MDBCol size="12" md="6" className="mt-1">
                <strong>Amount:</strong> {this.state.subSource.amount}
              </MDBCol>
            </MDBRow>
            {this._showStripeIds && (
              <MDBRow>
                <MDBCol size="12" md="6" className="mt-1">
                  <strong>Customer ID:</strong> {this._customerId || 'N/A'}
                </MDBCol>
                <MDBCol size="12" md="6" className="mt-1">
                  <strong>Subscription ID:</strong>{' '}
                  {this.state.subscriptionId || 'N/A'}
                </MDBCol>
              </MDBRow>
            )}
          </>
        ) : (
          <></>
        )}
        {this.state.subscriptionId &&
        parseInt(UserProfileService.getCurrentUserTypeId(true)) === 219 ? (
          <>
            <MDBRow>
              <MDBCol size="12" md="6" className="mt-1">
                <SyncSubscription
                  onSync={(res) => {
                    if (res?.status && res?.msg)
                      toast.success(res.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                      })
                    else if (res?.msg)
                      toast.error(res.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                      })
                  }}
                  subscriptionId={this.state.subscriptionId}
                />
              </MDBCol>
            </MDBRow>
          </>
        ) : (
          <></>
        )}
      </React.Fragment>
    )
  }
}

export default SubscriptionPackage
