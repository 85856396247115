import { CRMPage } from './index'

import { RouteFactory } from './../../shared/router/Route.factory'
import {
  RequiresAuthGuard,
  RequiresUsertypesGuard,
} from './../../shared/router/guards'

export default [
  RouteFactory.create(
    CRMPage,
    '/crm',
    [
      new RequiresAuthGuard(true),
      new RequiresUsertypesGuard([
        // 'agency-owner',
        // 'divisional-leader',
        // 'regional-leader',
        // 'district-leader',
        // 'internal-admin',
        'system-admin',
        // 'division-admin',
        // 'signature-higher-group',
        // 'signature-group',
      ]),
    ],
    {
      name: 'CRM',
    }
  ),
]
